import React, { useState, useEffect } from "react";
import { Loader,Table,Icon } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import { map } from "lodash";
import { useTranslation } from "react-i18next";
import ReactPlayer from "react-player";
import firebase from "../../../utils/Firebase";
import "firebase/firestore";
import "firebase/storage";

import './audiodetails.scss';

const db = firebase.firestore(firebase);

function Audiodetails(props) {

const {match }=props;
const [album, setAlbum] = useState(null);
const [albumImg, setAlbumImg] = useState(null);
const [record, setRecord] = useState([]);
const { t } = useTranslation();
 //console.log(album);
 //console.log(albumImg);
 //console.log(record);

    useEffect(() => {
        db.collection("albums")
        .doc(match.params.id)
        .get()
        .then(response => {           
        // console.log(response);            
          const data = response.data();
          data.id = response.id;
           //console.log(data);
         // setCourselist(data.id)
          setAlbum(data);
        });
    }, [match])

    useEffect(() => {
        if (album) {
            firebase
              .storage()
              .ref(`album/${album?.banner}`)
              .getDownloadURL()
              .then(url => {
                  //console.log(url);
                setAlbumImg(url);
              });
          }
    }, [album])

    useEffect(() => {
        if (album){
          db.collection("records")
          .where("album", "==", album.id)
          .get()
          .then(response =>{
           const arrayRecords=[];
           map(response?.docs, record=>{
             const data = record.data();
             data.id = record.id;
             arrayRecords.push(data);
           });
           setRecord(arrayRecords);
          });
        }
     }, [album])    


    if(!album ){
        return <Loader active>{t("Loading")}...</Loader>;
    }

    return (
        <>
        <div className="audio-album">

            <div className="audio-album__header">
                <HeaderAlbum album={album} albumImg={albumImg}  />
            </div>
        </div>
        <div>
              <ListRecords record={record} albumImg={albumImg} t={t}/>
        </div>
        </>
    )
}

function HeaderAlbum(props) {

    const { album, albumImg } = props;
   
    return (
      <>
        <div
          className="audio-image"
          style={{ backgroundImage: `url('${albumImg}')` }}
        />
        <div className="audio-info">
          <h1>{album.name}</h1>
          <p>
          </p>
        </div>
      </>
    );
}

function ListRecords(props) {
  const {record,albumImg,playerRecording,t }= props;
  // console.log(props);
  return (
      <Table inverted className="list-records">
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell />
          <Table.HeaderCell><p className="title-audio-list">{t("Audioresources")}:</p></Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
      {map(record, record => (
        <Record
          key={record.id}
          record={record}
          albumImg={albumImg}

        />
      ))}
      </Table.Body>
    </Table>
  );
}

function Record(props){
    const { record }= props;

    const [playing, setPlaying] = useState(false);
    const [recordData, setrecordData] = useState(null);
   // console.log(recordData);

    useEffect(() => {
      firebase
      .storage()
      .ref(`recordings/${record.fileName}`)
      .getDownloadURL()
      .then(url =>{
        //console.log(url);
        setrecordData({url, name: record.name });
      }) 
    }, [recordData])

    const onStart =()=>{
      setPlaying(true);
   }  
  
   ////pause player
  const onPause =()=>{
     setPlaying(false);
  }
  


      return (
        <>
        <Table.Row >
          <Table.Cell collapsing>
          {playing ? (
              <Icon  onClick={onPause} name="pause circle outline"  size='large'/>
            ) : (
              <Icon  onClick={onStart} name="play circle outline"  size='large'/>
            )}
          </Table.Cell>
          <Table.Cell><p className="record-name-list">{record.name}</p></Table.Cell>
        </Table.Row>
        <ReactPlayer
        className="react-player"
        url={recordData?.url}
        playing={playing}
        height="0"
  
        />
            </>
      );
    }



export default withRouter(Audiodetails);