import React, { useState, useEffect} from "react";
import { Form, Button, Dropdown,Icon,Input } from "semantic-ui-react";
import { map } from "lodash";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import firebase from "../../../utils/Firebase";
import "firebase/firestore";
import "firebase/storage";

import "./BlogMeta.scss";

const db = firebase.firestore(firebase);

 function BlogMeta(props) {
    const {setShowModal}= props;

    const [formData, setFormData] = useState(initialValueForm());
   
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation();
    const [blogs, setBlogs] = useState([]);
   
    useEffect(() => {
     db.collection("blogcovers")
     .get()
     .then(response => {
      //  console.log(response);
         
           const arrayBlogs = [];
           map(response?.docs, blogs => {
             const data = blogs.data();
           // console.log(data);
           // data.id=users.id;
             arrayBlogs.push({
               key: data.id,
               value: data.id,
               text: data.title,
             //  uid: users.id
             });
           });
         // console.log(arrayUsers);
         setBlogs(arrayBlogs);
     });
   
   }, [])
   
     const onSubmit=()=>{  
        // console.log(formData);
       if(!formData.id){
         toast.warning("Please provide the id");
     }else if (!formData.keywords){
       toast.warning("Please provide a keyword");
     }else if (!formData.metatitle){
        toast.warning("Please provide a Meta title");
     }else if (!formData.description){
        toast.warning("Please provide a description");
     }
     else{ 
    //    console.log(formData);
       setShowModal(false);
       db.collection("blogmeta")
                   .add({
                     id:formData.id,
                     description:formData.description,
                     keywords:formData.keywords,
                     metatitle:formData.metatitle
                     })
                     .then(() => {
                       toast.success("The blog CEO info is uploaded.");
                       // resetForm();
                       setIsLoading(false);
                       setShowModal(false);
                       resetForm();
                     })
                     .catch(() => {
                       toast.warning("Error creating blog CEO record.");
                       setIsLoading(false);
                     });
     }
     }
    const resetForm = () => {
        setFormData(initialValueForm());
      };
  return (
    <Form className="add-blog-meta-form" onSubmit={onSubmit}> 
        <Dropdown 
        placeholder="Blog id"
        fluid
        search
        selection
        options={blogs}
        lazyLoad
        onChange={(e, data) =>
        setFormData({ ...formData, id: data.value})}
        className="add-blog-meta"  />
        <Form.Field>
            <Input
                placeholder="Keywords"
                onChange={(e, data) =>
                setFormData({ ...formData,  keywords: data.value})}
            />
        </Form.Field>
        <Form.Field>
            <Input
                placeholder="Meta title"
                onChange={(e, data) =>
                setFormData({ ...formData,  metatitle: data.value})}
            />
        </Form.Field>
        <Form.Field>
            <Input
                placeholder="Description"
                onChange={(e, data) =>
                setFormData({ ...formData,  description: data.value})}
            />
        </Form.Field>
    <br>
    </br>         
    <br></br>
   <Button type="submit" isLoading={isLoading} className="add-blog">Add CEO</Button>
</Form>
  )
}

function initialValueForm (){
    return{
        id: "",
        keywords:"",
        metatitle:"",
        description:""
    }
  }

export default BlogMeta;