import React,{useState} from 'react'
import { Button} from "semantic-ui-react";
import {Radio,Grid} from 'react-bootstrap';
// import axios from 'axios';
import Qs from "qs";

import  './Trial.scss';

export default function Trial(props) {
    const {user}=props;
    const [formData, setformData] = useState("");
  
    const onChange = e =>{
      // console.log("Key:" +e.target.name);
      // console.log("Value:" +e.target.value);
  
      setformData({
        ...formData,
        [e.target.name]: e.target.value
      });
    }
    
    const onSubmit =(e)=> {
     // console.log("Formulario enviado");
    //  console.log(formData);

  var str = Qs.stringify(formData)
  
  ////https://docs.google.com/forms/d/e/1FAIpQLSfH2-p_9RFtmIPiI0r3wW2N8FdfyRRn-ZubNSw6vN50QAbrog/formResponse
  ////https://e0f64a9b-35a4-42a9-ab13-ed1305b4d82a.mock.pstmn.io
   e.preventDefault();
    fetch('https://docs.google.com/forms/d/e/1FAIpQLScz52T_7lV2MkixLrXa3i12nLqVcSAHh4rWv1CUypJhdeAl3w/formResponse', {
     method: 'post',
     headers: {'Content-Type':'application/x-www-form-urlencoded'},
     body: str,
     redirect: 'follow'
    });
    e.target.reset();
  
  
  }  



 //   console.log(user.uid);
    return (
      <>
        <div className="App">
        <header className="App-header-trial">
        <div className="container-trial">
        <Grid className="trial-card">
        <form onSubmit={onSubmit} onChange={onChange}>
    <fieldset>
        <h2 className="trial-schedule">Schedule a free trial class<br/><small></small></h2>
        <br></br>
        <br></br>
    </fieldset>
    <fieldset>
        <legend for="" className="trial-email">Email</legend>
        <div class="form-group">
            <input id="emailAddress" type="email" name="emailAddress" class="form-control" required/>
        </div>
    </fieldset>
   <br></br>
    <fieldset>
        <legend for="13006916" className="trial-name">Name</legend>
        <div class="form-group">
            <input id="1294804243" type="text" name="entry.1294804243" class="form-control" />
        </div>
    </fieldset>
    <br></br>
    <fieldset>
        <legend for="849996491"className="trial-country">Country</legend>
        <div class="form-group">
            <input id="1399679755" type="text" name="entry.1399679755" class="form-control" />
        </div>
    </fieldset>
    <br></br>   
    <fieldset>
        <legend for="1527668531" className="trial-age">Age</legend>
        <br></br>
        <div class="form-group">
        <Radio className="ra-uno" name="groupOptions" name="entry.1892081148" value="4-6 years old" required> &nbsp;&nbsp;4-6 years old</Radio>
        <Radio className="ra-dos" name="groupOptions" name="entry.1892081148" value="7-9 years old" required> &nbsp;&nbsp;7-9 years old</Radio>
        <Radio className="ra-tres" name="groupOptions" name="entry.1892081148" value="10-12 years old" required> &nbsp;&nbsp;10-12 years old</Radio>
        <Radio className="ra-cuatro" name="groupOptions" name="entry.1892081148" value="13-17 years old" required> &nbsp;&nbsp;13-17 years old</Radio>
        <Radio className="ra-cinco" name="groupOptions" name="entry.1892081148" value="Adult" required> &nbsp;&nbsp;Adult</Radio>
        </div>
    </fieldset>
    <br></br>
    <fieldset>
        <legend for="1546469253" className="trial-day">Prefered day</legend>
        <div class="form-group">
            <select id="1546469253" name="entry.391189045" class="form-control">
                <option value=""></option>
                <option value="Monday">Monday</option>
                <option value="Tuesday">Tuesday</option>
                <option value="Wednesday">Wednesday</option>
                <option value="Thursday">Thursday</option>
                <option value="Friday">Friday</option>
                <option value="Saturday">Saturday</option>
                <option value="Sunday">Sunday</option>
            </select>
        </div>
    </fieldset>
    <br></br>
    <fieldset>
        <legend for="180390977" className="trial-time">Prefered time</legend>
        <div class="form-group">
            <select id="180390977" name="entry.433472354" class="form-control">
                <option value=""></option>
                <option value="Morning">Morning</option>
                <option value="Afternoon">Afternoon</option>
                <option value="Evening">Evening</option>
            </select>
        </div>
    </fieldset>
    <br></br>
    <fieldset>
        <legend for="549544122" className="trial-years">Number of years studying Spanish</legend>
        <div class="form-group">
            <input id="713250847" type="text" name="entry.713250847" class="form-control" />
        </div>
    </fieldset>

    <input type="hidden" name="fvv" value="1"/>
    <input type="hidden" name="fbzx" value="22626098501025249"/>
   
    <input type="hidden" name="pageHistory" value="0"/>

    <Button className="btn-primary" type="submit">Request</Button>
</form>
       </Grid>
      </div>
    </header>
</div>
</>
    )
}
