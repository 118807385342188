import React,{useState,useEffect} from 'react';
import {Table,Icon,Container} from "semantic-ui-react";
import { map } from "lodash";
import {Link} from "react-router-dom";
import { useTranslation } from "react-i18next";
import firebase from "../../utils/Firebase";
import 'firebase/firestore';
import './Myclasses.scss';


const db = firebase.firestore(firebase);

function Myclasses(props) {

  const {user}=props;
  const [clase, setClase] = useState(null);
  const { t } = useTranslation();
 // const [idclase, setidclase] = useState(null);
//console.log(clase);
//console.log(user.uid);


///////class list
    useEffect(() => {
        // const {user}=props;

        db.collection("classroom")
        .get()
        .then(response => {
            
        const arrayClases = [];
        // const arrayEvaluations = [];
          map(response?.docs, classes => {
            const data = classes.data();
            data.id=classes.id;
           // console.log(data.teacher);
            if(user.uid === data.teacher){
              //  setClase(data.class);
           //  console.log(data);
             arrayClases.push(data);
            }else{
                // console.log("fail");
            }
          });
         // console.log(list);
        //console.log(arrayClases);
         setClase(arrayClases);
    });
}, [])
//////

    return (
      <Container>
        <Table inverted className="list-classes">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell />
            <Table.HeaderCell className="title-my-classes">{t("Myclasses")}:</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
  
        <Table.Body>
       {/* <Table.Row>
           <Table.Cell collapsing>
           <Icon name="book"/>
           </Table.Cell>
           <Table.Cell>
               {clase}
           </Table.Cell>
       </Table.Row> */}
       {/* <Record  clase={clase}/> */}
       {map(clase, clase => (
          <Record
            key={clase.id}
            clase={clase}            
          />
        ))}
        </Table.Body>
      </Table>
      </Container>
    
    )
}

function Record(props){
const{clase}=props;
//console.log(clase.id);
//console.log(key);

    return (
      <>
      <Table.Row >
      <Link to={`/courseinfo/${clase.id}`}  style={{ color: 'inherit', textDecoration: 'inherit'}}>  
        <Table.Cell collapsing>
          <Icon name="book" />
        </Table.Cell>
        {/* <Table.Cell>{clase.class}</Table.Cell>      */}
        <Table.Cell>{clase.classname}</Table.Cell> 
        </Link>
      </Table.Row>
      </>
    );
  }

export default Myclasses;