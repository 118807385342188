import React from 'react';
import { Tab  } from 'semantic-ui-react';
import { useTranslation } from "react-i18next";
import Onboardingform from '../../components/onboarding/onboardingForm/onboardingForm';
import Studentinfoform from '../../components/onboarding/studentinfoForm/studentinfoForm';
import Teacherinfoform from '../../components/onboarding/teacherinfoForm/teacherinfoForm';
import BringusersForm from '../../components/onboarding/bringusersForm/bringusersForm';
import ChatForm from '../../components/onboarding/chatForm/chatForm';


export default function Onboarding() {
    
    const { t } = useTranslation();

    const panes = [
        { menuItem: t("Createaccounts"), render: () => <Tab.Pane as='div'><Onboardingform/></Tab.Pane> },
        { menuItem: t("Studentinfo"), render: () => <Tab.Pane as='div'><Studentinfoform/></Tab.Pane> },
        { menuItem: t("Teacherinfo"), render: () => <Tab.Pane as='div'><Teacherinfoform/></Tab.Pane> },
        { menuItem: t("Userlist"), render: () => <Tab.Pane as='div'><BringusersForm/></Tab.Pane> },
        { menuItem: t("Chatroom"), render: () => <Tab.Pane as='div'><ChatForm/></Tab.Pane> },
      ]
    // const {cvsjson} =props;
    // console.log(cvsjson);

    const color = 'gray';

    return (
        <div>
            <h1 style={{marginLeft : 70}}>{t("Accountsonboarding")}</h1>
            <br>
            </br>
            <br>
            </br>
            <Tab menu={{color, inverted: true,}} panes={panes} />
        </div>
    )
}
