import React,{useState} from 'react'
import {Form,Input,Button} from "semantic-ui-react";
import {toast} from "react-toastify";
import { useTranslation } from "react-i18next";
import firebase from "../../utils/Firebase";
import "firebase/auth";

export default function UserName(props) {
    const {
        user,
        setShowModal,
        setTitleModal,
        setContentModal,
        setReloadApp
      } = props;
      const { t } = useTranslation();
   // console.log(props);
 const onEdit =() =>{
    setTitleModal(t("Updatename"));
    setContentModal(<ChangeDisplayNameForm  
        displayName={user.displayName}
        setShowModal={setShowModal}
        setReloadApp={setReloadApp}
        t={t}
        />);
    setShowModal(true);
 }

    return (
        <div className="user-name">
            <h2>{user.displayName}</h2>
            <Button circular  onClick={onEdit}>
            {t("Update")}
      </Button>
        </div>
    )
}

function ChangeDisplayNameForm(props) {
    const { displayName, setShowModal, setReloadApp,t } = props;
    const [formData, setFormData] = useState({ displayName: displayName });
    const [isLoading, setIsLoading] = useState(false);
  
    const onSubmit = () => {
        if (!formData.displayName || formData.displayName === displayName) {
            setShowModal(false);
          }else{
            setShowModal(true);
            firebase
            .auth()
             .currentUser
               .updateProfile({displayName: formData.displayName})
                .then(()=>{
                  setReloadApp(prevState => !prevState);
                  toast.success("Updated Name")
                  setIsLoading(false);
                  setShowModal(false);
                 
                }).catch(()=>{
                    toast.error("Error Name")
                    setIsLoading(false);
                })
          }
    };

    return (
        <Form onSubmit={onSubmit}>
          <Form.Field>
            <Input
              defaultValue={displayName}
              onChange={e => setFormData({ displayName: e.target.value })}
            />
          </Form.Field>
          <Button type="submit" loading={isLoading}>
          {t("Updatename")}
          </Button>
        </Form>
      );
   
    }