import React, { useState,useCallback } from 'react';
import { Form, Table,Icon,Container } from "semantic-ui-react";
import { map } from "lodash";
import { toast } from "react-toastify";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";

import "./onboardingForm.scss";
//import csv from 'csv';
import csv from 'csvtojson';

//const csv = require('csvtojson');

 function OnboardingForm () {

    const [cvsjson, setCvsjson] = useState(null);
    const { t } = useTranslation();

    const onDrop = useCallback(acceptedFiles => {
        const file =acceptedFiles[0];
       // setFiles(file);

        const reader = new FileReader();
    
        reader.onabort = () => console.log("file reading was aborted");
        reader.onerror = () => console.log("file reading failed");
        reader.onload = () => {
   
        const fileAsBinaryString = reader.result;

        csv({
          noheader: true,
          output: "json"
        })
          .fromString(fileAsBinaryString)
          .then((csvRows) => {
            const toJson = []
            csvRows.forEach((aCsvRow, i) => {


              if (i !== 0) {
                const builtObject = {}

                Object.keys(aCsvRow).forEach((aKey) => {
                  const valueToAddInBuiltObject = aCsvRow[aKey];
                  const keyToAddInBuiltObject = csvRows[0][aKey];
                  builtObject[keyToAddInBuiltObject] = valueToAddInBuiltObject;
                })

                toJson.push(builtObject)
              }


            })
            setCvsjson (toJson)
          // console.log(toJson);
          })
        };
    
        // read file contents
        acceptedFiles.forEach(file => reader.readAsBinaryString(file));
      }, []);


    const {getRootProps
           ,getInputProps,
           isDragActive, 
           isDragAccept, 
           isDragReject
    } 
    = useDropzone({
    	accept: 'text/csv, application/vnd.ms-excel',
        noKeyboard:true,
        onDrop
  });



    
    return (
  <>   
  <Form className="add-onboarding-fom">
       <Form.Field className="onboarding-banner">
       <div
            {...getRootProps()}
            className="banner"
          />    
       <input {...getInputProps()} />
     	{isDragAccept  &&  <p className="droptext">{t("Drop2")}</p>}
			{isDragReject  &&  <p className="droptext">{t("Drop3")}</p>}
			{!isDragActive &&  <p className="droptext">{t("Drop")}...      </p>}
        </Form.Field>
  </Form>
  <br>
  </br>
  <Usertable  cvsjson={cvsjson} t={t}/>
  </>
    )
}

function Usertable (props){

  const{cvsjson,t}=props;

//console.log(cvsjson);

  return (
 <Container>
   {cvsjson &&
      <Table inverted >
      <Table.Header>
          <Table.Row>
            <Table.HeaderCell >{t("Createusers")} ({t("Email2")}) </Table.HeaderCell>
            <Table.HeaderCell > {t("Phonenumber")}</Table.HeaderCell>
            <Table.HeaderCell > {t("Displayname")}</Table.HeaderCell>
            <Table.HeaderCell > {t("Password2")}</Table.HeaderCell>
            <Table.HeaderCell >{t("Agregar")}</Table.HeaderCell>
          </Table.Row>
      </Table.Header>
      <Table.Body>
          {map(cvsjson, cvsjson => (
            <Userows
            key={cvsjson.id}
            cvsjson={cvsjson}   
            />
        ))}
      </Table.Body>
    </Table>
    }
  </Container>  
    );
}


function Userows (props) {

  const{cvsjson}=props;
  console.log(cvsjson);

  const {email,phoneNumber,displayName, password} = cvsjson;

  //console.log(cvsjson);
  //console.log(email);
  // const HOST = process.env.HOST || "http://localhost:8080"
  const HOST = process.env.HOST || "https://restserver-323001.uw.r.appspot.com"

  const onSubmit = async () =>{
 // console.log('click');

  try {
    const response = await fetch(HOST + "/api/users", {
      headers: {
        'Content-Type': 'application/json',
        'Accept':       'application/json',
      },
      method: "POST",
      body:  JSON.stringify(cvsjson)
    })
  
    
    if(response.status === 404) 
      throw {message: "Bad request"}
      
      
    if(response.status !== 200) 
      throw new Error()


    // Si la tarea se ha cumplido correctamente 
    // podemos borrar al usuario de la tabla



    toast.success("User uploaded");
  }
  catch(e) {
    toast.warning("ERROR: something went wrong...");
  }



  }

  return (
    <>
    <Table.Row > 
      <Table.Cell collapsing>
     {email}
     </Table.Cell>
     <Table.Cell>
    { phoneNumber}
     </Table.Cell>
     <Table.Cell>
     {displayName}
     </Table.Cell>  
     <Table.Cell>
    {password}
     </Table.Cell>  
     <Table.Cell>
     {/* <Icon name="plus"  onclick="onSubmit"/> */}
     <Icon className="add-s"  name='plus' link onClick={ onSubmit }/>
     </Table.Cell>     
    </Table.Row>
    </>
  );

}


export default OnboardingForm;