import React, { useState,useEffect} from 'react';
import { map } from "lodash";
import { toast } from "react-toastify";
import {  Table,Container,Button,Icon } from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import firebase from "../../../utils/Firebase";
import "firebase/firestore";
import "firebase/storage";

import './chatForm.scss';

const db = firebase.firestore(firebase);

function ChatForm() {

    const [listofclassroom, setlistofclassroom] = useState([]);
    const { t } = useTranslation();
   // console.log(listofclassroom);
       /////classroom distribution
   useEffect(() => {
    db.collection("classroom")
    .get()
    .then(response => {
   
      const arrayListofclassrooms = [];
      map(response?.docs, salon => {
        const data = salon.data();
          arrayListofclassrooms.push(data)        
      });
      setlistofclassroom(arrayListofclassrooms)
     // console.log(arrayListofclassrooms);
    });
  
  }, [ ])
    return (
        <Container>
        {listofclassroom &&
           <Table inverted >
           <Table.Header>
               <Table.Row>
                 <Table.HeaderCell >{t("Listofclass")}</Table.HeaderCell>
                 <Table.HeaderCell >{t("Agregar")}</Table.HeaderCell>
               </Table.Row>
           </Table.Header>
           <Table.Body>
               {map(listofclassroom, listofclassroom => (
                 <Roomsrows
                 key={listofclassroom.id}
                 listofclassroom={listofclassroom}  
             
                 />
             ))}
           </Table.Body>
         </Table>
         }
       </Container>  
    )
}


function Roomsrows (props) {

    const{ listofclassroom }=props;
    //console.log(userslists);  
    const { classname } = listofclassroom;

 //const HOST = process.env.HOST || "http://localhost:8080"
 const HOST = process.env.HOST || "https://restserver-323001.uw.r.appspot.com/"
  
const onSubmit = async () =>{
// console.log('click');

var raw = JSON.stringify({
    "classname": classname
  });

try {
  const response = await fetch(HOST + "/api/courses/chat", {
    headers: {
      'Content-Type': 'application/json',
      'Accept':       'application/json',
    },
    method: "POST",
    body:  raw
  })

  
  if(response.status === 404) 
    throw {message: "Bad request"}
    
    
  if(response.status !== 200) 
    throw new Error()


  // Si la tarea se ha cumplido correctamente 
  // podemos borrar al usuario de la tabla
  toast.success("Chatroom have been added");
}
catch(e) {
  toast.warning("ERROR: something went wrong...");
}
}
   
    return (
      <>
      <Table.Row > 
        <Table.Cell collapsing>
       {classname}
       </Table.Cell> 
       <Table.Cell>
       {/* <Icon name="plus"  onclick="onSubmit"/> */}
       <Icon className="add-chat"  name='plus' link onClick={ onSubmit }/>
       </Table.Cell>     
      </Table.Row>
      </>
    );
  
  }

export default ChatForm;