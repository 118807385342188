import React,{useState,useEffect} from 'react';
import {Table,Icon,Container} from "semantic-ui-react";
import { map } from "lodash";
import { useTranslation } from "react-i18next";
import {Link} from "react-router-dom";
import firebase from "../../utils/Firebase";

import "./Mycourses.scss"

const db = firebase.firestore(firebase);

function Mycourses(props) {

    const {user}=props;
    const [miclase, setMiclase] = useState(null);
    const { t } = useTranslation();
    console.log(miclase);
   // console.log(user.uid);

//     var strWindowFeatures = "location=yes,height=1000,width=1500,scrollbars=yes,status=yes";
//     var URL = "https://comligopapp.firebaseapp.com/";  
   
// const doCourse = ()=>{
// console.log("click");
// window.open(URL, "_blank", strWindowFeatures);
// }
//////////////////list student class
useEffect(() => {
    db.collection("studentclass")
    .get()
    .then(response => {

        const arraymiClase=[]

        map(response?.docs, classes => {
            const data = classes.data();
            data.id=classes.id;
           // console.log(data.teacher);
            if(user.uid === data.studentid){
              //  setClase(data.class);
           // console.log(data);
             arraymiClase.push(data);
            }else{
                // console.log("fail");
            }
          });   
          setMiclase(arraymiClase);
});
}, [])
//////////////////////
    return (
//         <div className="my-courses">
//             <h1 className="mycourses-l">My courses</h1>

//  <List>
//  <List.Item>
//  <List.Icon name='folder' />
//  <List.Content>      
//  <List.Header><h3 className="li-header">General spanish</h3></List.Header>
//  <List.Description><h3 className="li-title">B1.1</h3></List.Description> 
//  <Button className="mycourses-btn" onClick={doCourse}> Start</Button>
//  </List.Content>      
//  </List.Item>
//   </List>
  
//         </div>
<Container textAlign='justify'>
  <Table inverted className="list-classes">
  <Table.Header>
    <Table.Row>
      <Table.HeaderCell />
      <Table.HeaderCell className="title-courses">{t("Mycourses")}:</Table.HeaderCell>
    </Table.Row>
  </Table.Header>

  <Table.Body>

  {map(miclase, miclase => (
    <Records
      key={miclase.id}
      miclase={miclase}            
    />
  ))}
  </Table.Body>
  </Table>
</Container>
    )
}



function Records(props){
    const{miclase}=props;
        return (
          <>
          <Table.Row >
          <Link to={`/Mycourseinfo/${miclase.id}`}  style={{ color: 'inherit', textDecoration: 'inherit'}}>  
            <Table.Cell collapsing>
              <Icon name="bookmark" />
            </Table.Cell>
            {/* <Table.Cell>{miclase.class}</Table.Cell>      */}
            <Table.Cell>{miclase.classname}</Table.Cell>  
            </Link>
          </Table.Row>
          </>
        );
      }

export default  Mycourses;