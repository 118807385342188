import React from 'react';

import './FaqForm.scss'

 function FaqForm() {
  return (
    <div className="faq-form">
      <p>Your Account</p>
      <p><br/></p>
      <p><strong>How do I create an account for the Comligo platform?</strong></p>
      <p>To create an account, simply click on Register, and we will contact you shortly for your registration.</p>
      <p><br/></p>
      <p><strong>How do I log in to the Comligo platform?</strong></p>
      <p>To log in, go to comligospanish.com and simply input the email and password provided.</p>
      <p><br/></p>
      <p><strong>How do I change the email on my Profile?</strong></p>
      <p>You can change the email by following these instructions:</p>
      <ul>
          <li>Click on your Profile and select Update next to the email you want to change.</li>
          <li>A pop-up screen will appear where you can update your new email.</li>
          <li>Once you have selected your new email, enter your password and click Update Email to apply the changes.</li>
      </ul>
      <p><br/></p>
      <p><strong>How do I change my current username?</strong></p>
      <p>You can change your username by following these instructions:&nbsp;</p>
      <ul>
          <li>Click on your Profile and select Update next to the username you would like to change.</li>
          <li>A pop-up screen will appear where you can change to your new username.</li>
          <li>Once you have selected your new username, click Update Name to apply the changes.</li>
      </ul>
      <p>.&nbsp;</p>
      <p><br/></p>
      <p><strong>How do I change my current password?</strong></p>
      <p>You can change your password by following these instructions:</p>
      <ul>
          <li>Click on your Profile and select Update next to the password you want to change.</li>
          <li>A pop-up screen will appear where you can change your password.</li>
          <li>Next, enter your current password.</li>
          <li>Enter your new password.</li>
          <li>Then re-type the new password.</li>
          <li>Once done, click Update Password to apply the changes.</li>
      </ul>
      <p><br/></p>
      <p><br/></p>
      <p><strong>How do I change my current avatar?</strong></p>
      <p>You can change your avatar by following these instructions:</p>
      <ul>
          <li>Click on your Profile.</li>
          <li>Next, click on your current avatar. This will allow you to choose a picture from your photos on your computer.</li>
          <li>Pick your new image, and your Profile will update automatically.&nbsp;</li>
      </ul>
      <p><br/></p>
      <p><strong>How do I change the language of the platform?</strong></p>
      <p>You can select the platform language by clicking the four options available at the bottom left corner. The platform is available in English, Spanish, French, and Portuguese.&nbsp;</p>
      <p><br/></p>
      <p><br/></p>
      <p>Technology Requirements</p>
      <p><br/></p>
      <p><strong>Do I need special equipment to start learning?</strong></p>
      <p>All you will need is a stable internet connection and a computer.&nbsp;</p>
      <p><br/></p>
      <p><strong>What video conferencing platform do you use?</strong></p>
      <p>We use Zoom as our main free video conferencing platform for our online classes.</p>
      <p><br/></p>
      <p><br/></p>
      <p><br/></p>
      <p><br/></p>
      <p>Learning Experience</p>
      <p><br/></p>
      <p><strong>What languages are taught?</strong></p>
      <p>We teach Spanish.</p>
      <p><br/></p>
      <p><strong>Do I need to know Spanish to start learning with Comligo?</strong></p>
      <p>No. We cater to all language levels, from total beginners to advanced learners.</p>
      <p><br/></p>
      <p><strong>Are classes online?</strong></p>
      <p>Yes, they are. We offer synchronous learning, which means that our students engage in the learning process with a teacher who is teaching the class &ldquo;live&rdquo; online, so there can be an instant exchange of knowledge and participation.</p>
      <p><br/></p>
      <p><strong>When can I take classes?</strong></p>
      <p>Our classes can be taken anytime, anywhere. We ensure our students have a wide range of options and choices that best match their schedules&rsquo;&nbsp;&nbsp;needs.</p>
      <p><br/></p>
      <p><strong>How do I check my courses?</strong></p>
      <p>To check your courses, click on Courses on the side menu and then click on your course to display its information.&nbsp;</p>
      <p><br/></p>
      <p><br/></p>
      <p><strong>Will I get learning materials?</strong></p>
      <p>Each course includes a pack of additional and complementary learning materials that enhance the student&rsquo;s learning experience, allowing them to review, practice, or expand the contents at their own pace. This pack of materials consists of:</p>
      <ul>
          <li>Reference sheets with vocabulary, grammar, etc.</li>
          <li>Worksheets and quizzes.</li>
          <li>Authentic materials (videos, articles, audio, etc.).</li>
      </ul>
      <p><br/></p>
      <p><strong>How can I view the learning materials?</strong></p>
      <p>Go to Courses on the side menu, select your course, and then click on &ldquo;Course Material&rdquo; to access them.</p>
      <p><br/></p>
      <p><strong>Who are the teachers?</strong></p>
      <p>Our experienced, qualified native Spanish teachers come from different countries from around the Hispanic world. This enables us to bring an international and multicultural perspective to our students.</p>
      <p><br/></p>
      <p><br/></p>
      <p><strong>How do I view the teacher&rsquo;s feedback for my classes?</strong></p>
      <p>You can see the teacher&rsquo;s feedback by clicking Teacher Feedback on the side menu. Once there, you will be able to view the feedback.&nbsp;</p>
      <p><br/></p>
      <p><br/></p>
      <p><strong>Will I receive a certificate to show my progress?</strong></p>
      <p>Yes, A certificate will be awarded to showcase your progress in Spanish.</p>
      <p><br/></p>
      <p><strong>How can I contact your support team?</strong></p>
      <p>If you have any questions or concerns about the platform, you can contact us at support@comligo.com.</p>
    </div>
  )
}
export default FaqForm;