import React, { useState,useCallback } from 'react';
import { Form, Table,Icon,Container } from "semantic-ui-react";
import { map } from "lodash";
import { toast } from "react-toastify";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";

import "./classroombooking.scss";
//import csv from 'csv';
import csv from 'csvtojson';

function Classroombooking() {

    const [cvsjsonbook, setCvsjsonbook] = useState(null);
    const { t } = useTranslation();

    const onDrop = useCallback(acceptedFiles => {
        const file =acceptedFiles[0];
       // setFiles(file);

        const reader = new FileReader();
    
        reader.onabort = () => console.log("file reading was aborted");
        reader.onerror = () => console.log("file reading failed");
        reader.onload = () => {
   
        const fileAsBinaryString = reader.result;

        csv({
          noheader: true,
          output: "json"
        })
          .fromString(fileAsBinaryString)
          .then((csvRows) => {
            const toJson = []
            csvRows.forEach((aCsvRow, i) => {


              if (i !== 0) {
                const builtObject = {}

                Object.keys(aCsvRow).forEach((aKey) => {
                  const valueToAddInBuiltObject = aCsvRow[aKey];
                  const keyToAddInBuiltObject = csvRows[0][aKey];
                  builtObject[keyToAddInBuiltObject] = valueToAddInBuiltObject;
                })

                toJson.push(builtObject)
              }


            })
            setCvsjsonbook (toJson)
          // console.log(toJson);
          })
        };
    
        // read file contents
        acceptedFiles.forEach(file => reader.readAsBinaryString(file));
      }, []);


    const {getRootProps
           ,getInputProps,
           isDragActive, 
           isDragAccept, 
           isDragReject
    } 
    = useDropzone({
    	accept: 'text/csv, application/vnd.ms-excel',
        noKeyboard:true,
        onDrop
  });




    return (
        <>   
        <Form className="add-onboardingbc-fom">
             <Form.Field className="onboardingbc-banner">
             <div
                  {...getRootProps()}
                  className="bannerbc"
                />    
             <input {...getInputProps()} />
               {isDragAccept  &&  <p className="droptextbc">{t("Drop2")} </p>}
                  {isDragReject  &&  <p className="droptextbc">{t("Drop3")}</p>}
                  {!isDragActive &&  <p className="droptextbc">{t("Drop")}...      </p>}
              </Form.Field>
        </Form>
        <br>
        </br>
        <Bookingclasstable  cvsjsonbook={cvsjsonbook} t={t}/>
        </>
    )
}


function Bookingclasstable (props){

    const{cvsjsonbook,t}=props;
  
  //console.log(cvsjson);
  
    return (
   <Container className="overflow-classroom">
     {cvsjsonbook &&
        <Table inverted >
        <Table.Header>
            <Table.Row>
              <Table.HeaderCell >{t("Clase")}</Table.HeaderCell>
              <Table.HeaderCell >{t("Classday")}</Table.HeaderCell>
              <Table.HeaderCell >{t("Classtime")}</Table.HeaderCell>
              <Table.HeaderCell >{t("Course")}</Table.HeaderCell>
              <Table.HeaderCell >{t("Courselink")}</Table.HeaderCell>
              <Table.HeaderCell >{t("Level")}</Table.HeaderCell>
              <Table.HeaderCell >{t("Link")}</Table.HeaderCell>
              <Table.HeaderCell >{t("Sublevel")}</Table.HeaderCell>
              <Table.HeaderCell >{t("Teacheruid")}</Table.HeaderCell>
              <Table.HeaderCell >{t("Teachername")}</Table.HeaderCell>
              <Table.HeaderCell >{t("Studentuid")}</Table.HeaderCell>
              <Table.HeaderCell >{t("Studentname")}</Table.HeaderCell>
              <Table.HeaderCell >{t("Completion")}</Table.HeaderCell>
              <Table.HeaderCell >{t("Agregar")}</Table.HeaderCell>
            </Table.Row>
        </Table.Header>
        <Table.Body>
            {map(cvsjsonbook, cvsjsonbook => (
              <Bookingclassrows
              key={cvsjsonbook.id}
              cvsjsonbook={cvsjsonbook}   
              />
          ))}
        </Table.Body>
      </Table>
      }
    </Container>  
      );
  }
  
  
  function Bookingclassrows (props) {
  
    const{cvsjsonbook}=props;
    console.log(cvsjsonbook);
  
    const {
        classname,
        classday,
        classtime,
        course,
        courselink,
        level,
        link,
        sublevel,
        teacher,
        teachername,
        studentid,
        studentname,
        completion
    } = cvsjsonbook;
  
    //console.log(cvsjson);
    //console.log(email);
   // const HOST = process.env.HOST || "http://localhost:8080"
    const HOST = process.env.HOST || "https://restserver-323001.uw.r.appspot.com"
  
    const onSubmit = async () =>{
   // console.log('click');
  
    try {
      const response = await fetch(HOST + "/api/courses/classroom", {
        headers: {
          'Content-Type': 'application/json',
          'Accept':       'application/json',
        },
        method: "POST",
        body:  JSON.stringify(cvsjsonbook)
      })
    
      
      if(response.status === 404) 
        throw {message: "Bad request"}
        
        
      if(response.status !== 200) 
        throw new Error()
  
  
      // Si la tarea se ha cumplido correctamente 
      // podemos borrar al usuario de la tabla
  
  
  
      toast.success("classroom created");
    }
    catch(e) {
      toast.warning("ERROR: something went wrong...");
    }
  
  
  
    }
  
    return (
      <>
      <Table.Row > 
      <Table.Cell collapsing>
       { classname}
       </Table.Cell>
       <Table.Cell>
       { classday }
       </Table.Cell>
       <Table.Cell>
       { classtime }
       </Table.Cell>  
       <Table.Cell>
       { course }
       </Table.Cell> 
       <Table.Cell>
       { courselink }
       </Table.Cell> 
       <Table.Cell>
       { level }
       </Table.Cell> 
       <Table.Cell>
       { link }
       </Table.Cell>  
       <Table.Cell>
       { sublevel }
       </Table.Cell>
       <Table.Cell>
       { teacher }
       </Table.Cell> 
       <Table.Cell>
       { teachername }
       </Table.Cell>  
       <Table.Cell>
       { studentid }
       </Table.Cell>  
       <Table.Cell>
       { studentname }
       </Table.Cell>    
       <Table.Cell>
       { completion }
       </Table.Cell>  
       <Table.Cell>
       {/* <Icon name="plus"  onclick="onSubmit"/> */}
       <Icon className="add-sbc"  name='plus' link onClick={ onSubmit }/>
       </Table.Cell>     
      </Table.Row>
      </>
    );
  
  }
  


export default Classroombooking;