import React, { useState} from "react";
import { Form, Input, Button} from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import firebase from "../../../utils/Firebase";
import "firebase/firestore";

import './AddGradeForm.scss';


const db = firebase.firestore(firebase);

export default function AddGradeForm(props) {

    let history = useHistory();

    const {studenanswers,user,setShowgrade,idcomentario}=props;

    const [formData, setFormData] = useState(initialValueForm());

    const [isloading, setIsloading] = useState(false);
   // console.log(user);
  //console.log(studenanswers);
   //console.log(idcomentario);

   ///////delete answer
   const borrar =()=>{
    //console.log(cartid);
    db.collection('answers')
    .doc(idcomentario)
    .delete()
    .then(() => {
     // console.log("unit exercises completed!");
     // toast.success("You are ready complete the unit exercises.");

     history.push('/evaluatetest')
      //window.location.reload();
    }).catch((error) => {
     // toast.error("Error completing the unit exercises.");

    });
}
//////////submit function
   const onSubmit=()=>{
       console.log(formData);
       setShowgrade(false);

       db.collection("grades")
                   .add({
                     studentgrade: formData.studengrade,
                     studentid: studenanswers.user,
                     studentclassid: studenanswers.userclassid,
                     studentclassname: studenanswers.userclassname,
                     studentcourse: studenanswers.usercourse,
                     studentlevel: studenanswers.userlevel,
                     studentwork: studenanswers.userwork,
                     studentemail: studenanswers.useremail,
                     studentname:studenanswers.username,
                     studentskill:studenanswers.studentskill,
                     teachername: studenanswers.Teacher,
                     teacherid: user.uid
                    })
                     .then(() => {
                       toast.success("The test is already evaluated.");
                       // resetForm();
                       setIsloading(false);
                       setShowgrade(false);
                       borrar();
                     })
                     .catch(() => {
                       toast.warning("Error evaluating the test.");
                       setIsloading(false);
                     });
   }
    return (
        <Form className="add-testgrade-form" onSubmit={onSubmit}>
          <Form.Group>
              <Form.Field className="testgrade-inputs" >
                  <b className="grade-description">Please enter the grade of the test:</b>
                  <Input placeholder="Assign grade... %"
                  onChange={e => setFormData({...formData, studengrade: e.target.value})}
                  className="entrada-testgrade"
                  />
                 
              </Form.Field>
          </Form.Group>
          <br></br>
          <Button type="submit" isloading={isloading} className="btn-grade-testing">Send record</Button>
       </Form>
    )
}

function initialValueForm (){
    return{
        studengrade:""
    
    }
}