import { toast } from "react-toastify";

export default function alertErrors(type) {
  switch (type) {
    case "auth/wrong-password":
      toast.warning("Invalid password verify.");
      break;
    case "auth/email-already-in-use":
      toast.warning("The email is already in use.");
      break;
    default:
      toast.warning("Server Error , try later.");
      break;
  }
}
