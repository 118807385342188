import React ,{useEffect,useState}from 'react';
import { Button} from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import firebase  from '../../../utils/Firebase';
import "firebase/storage";
import './Bannerresources.scss';

export default function Bannerresources(props) {

    const {resources}= props;
    const [bannerurlstwo, setBannerurlstwo] = useState(null);
    const { t } = useTranslation();

    useEffect(() => {
        firebase
        .storage()
        .ref(`resources/${resources?.banner}`)
        .getDownloadURL()
        .then (url =>{
            setBannerurlstwo(url);
        })
        
    }, [resources]);

    const openWindow = ()=>{
        window.open(resources.url);
    }

    return (
        <div className="banner-resources"
        style={{backgroundImage: `url('${bannerurlstwo}')`}}
        >
            {/* <h1>llll</h1> */}
            <Button  className="Resources-details" onClick={openWindow}>
            {t("Moredetails")}
            </Button>
        </div>
    )
}
