import React, {useState,useCallback} from 'react'
/////avatar image
import {Image} from "semantic-ui-react"
import NoAvatar from "../../assets/png/user.png"
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
///////db
import firebase from "../../utils/Firebase";
import "firebase/storage";
import "firebase/auth";

export default function UploadAvatar(props) {
    const {user,setReloadApp} = props;
    const [avatarUrl, setAvatarUrl] = useState(user.photoURL);
//////upload image and update
    const onDrop = useCallback(acceptedFiles => {
        const file = acceptedFiles[0];
        setAvatarUrl(URL.createObjectURL(file));
        uploadImage(file).then(() => {
          updateUserAvatar();
       });
      });
///////format accepted to upload
      const { getRootProps, getInputProps, isDragActive } = useDropzone({
        accept: "image/jpeg, image/png",
        noKeyboard: true,
        onDrop
      });

      const uploadImage = file => {
         // console.log(file);
   ////////////////storage image in database 
        const ref = firebase
          .storage()
          .ref()
          .child(`avatar/${user.uid}`);
        return ref.put(file);
      };
      //////////////update image 
      const updateUserAvatar = () => {
        firebase
          .storage()
          .ref(`avatar/${user.uid}`)
          .getDownloadURL()
          .then(async response => {
            await firebase.auth().currentUser.updateProfile({ photoURL: response });
            setReloadApp(prevState => !prevState);
          // console.log(response);
          })
          .catch(() => {
            toast.error("Error updating the avatar.");
          });
      };
   // console.log(props);
    return (
        <div className="user-avatar" {...getRootProps()}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <Image src={NoAvatar} />
        ) : (
          <Image src={avatarUrl ? avatarUrl : NoAvatar} />
        )}
      </div>
    )
}
