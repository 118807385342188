import React,{useState} from 'react'
import {Button,Form,Input,Icon} from "semantic-ui-react";
import {toast} from "react-toastify";
import { useTranslation } from "react-i18next";
/////reautentication
import {reauthenticate} from "../../utils/Api";
////errors.
import alertErrors from "../../utils/AlertErrors";
////database fb
import firebase from "../../utils/Firebase";
import "firebase/auth";

export default function UserPassword(props) {
   
    const { setShowModal, setTitleModal, setContentModal } = props;
    const { t } = useTranslation();

    const onEdit =()=>{
        setTitleModal(t("Updatepassword"));
        setContentModal(<ChangePasswordForm setShowModal={setShowModal} t={t}/>);
        setShowModal(true);
    }
    return (
        <div className="user-password">
           <h3>{t("Password")} *** *** *** ***</h3> 
           <Button circular onClick={onEdit}>
           {t("Update")}
           </Button>
        </div>
    )
}

function ChangePasswordForm (props){
const {setShowModal,t}=props;
const [formData, setFormData] = useState({
    currentPassword:"",
    newPassword:"",
    repeatNewPassword:""
});
const [showPassword, setShowPassword] = useState(false);
const [isLoading, setIsLoading] = useState(false);

   const onSubmit = () =>{
     //  console.log(formData);
       if (
        !formData.currentPassword ||
        !formData.newPassword ||
        !formData.repeatNewPassword
      ) {
        toast.warning("The passwords can´t be left empty.");
   }else if (formData.currentPassword === formData.newPassword) {
    toast.warning("The new password can´t be the current one.");
  }else if (formData.newPassword !== formData.repeatNewPassword) {
    toast.warning("The new password don´t match verify.");
  }else if (formData.newPassword.length < 6) {
    toast.warning("The password must have more than 6 characters.");
  }else{
      //console.log("changing password...");
      setIsLoading(true);
      reauthenticate(formData.currentPassword).then(()=>{
        // console.log("Send it"); 
        const currentUser = firebase.auth().currentUser;
        currentUser
          .updatePassword(formData.newPassword)
          .then(() => {
            toast.success("Password Updated.");
            setIsLoading(false);
            setShowModal(false);
            firebase.auth().signOut();
          })
          .catch(err => {
            alertErrors(err?.code);
            setIsLoading(false);
          });
      })
      .catch(err => {
        alertErrors(err?.code);
        setIsLoading(false);
      });
  }
};
    return(
   <Form onSubmit={onSubmit}>
    <Form.Field>
        <Input
        placeholder={t("Currentpassword")}
        type={showPassword ? "text" : "password"}
        onChange={e => setFormData({...formData, currentPassword:e.target.value})}
        icon={
        <Icon
         name={showPassword ? "eye slash outline" : "eye"}
        link
        onClick={()=> setShowPassword(!showPassword)}
        />}
        />
    </Form.Field>
    <Form.Field>
        <Input
        placeholder={t("Newpassword")}
        type={showPassword ? "text" : "password"}
        onChange={e => setFormData({...formData, newPassword:e.target.value})}
        icon={
            <Icon
             name={showPassword ? "eye slash outline" : "eye"}
            link
            onClick={()=> setShowPassword(!showPassword)}
            />}
            />
    </Form.Field>
    <Form.Field>
        <Input
        placeholder={t("Retypepass")}
        type={showPassword ? "text" : "password"}
        onChange={e => setFormData({...formData, repeatNewPassword:e.target.value})}
        icon={
            <Icon
             name={showPassword ? "eye slash outline" : "eye"}
            link
            onClick={()=> setShowPassword(!showPassword)}
            />}
            />
    </Form.Field>
    <Button type="submit" loading={isLoading}>
    {t("Updatepassword")}
    </Button>
   </Form>
    )
}