import React,{useState} from 'react'
import { Button} from "semantic-ui-react";
import {Radio,Grid} from 'react-bootstrap';
import { useTranslation } from "react-i18next";
// import axios from 'axios';
import Qs from "qs";

import './LevelTest.scss';

export default function LevelTest() {
    //const{user}=props;
    const [formData, setformData] = useState("");
    const { t } = useTranslation();
  
  const onChange = e =>{
    // console.log("Key:" +e.target.name);
    // console.log("Value:" +e.target.value);

    setformData({
      ...formData,
      [e.target.name]: e.target.value
    });
  }
  
  const onSubmit =(e)=> {
  console.log("Formulario enviado");
  //  console.log(formData);
///envio local
//     // toast.success("The information was send succesfully");
//     axios.post(`http://localhost:8080/`,  formData)
//   //   axios.post(`https://nodejs-notifications-293520.ue.r.appspot.com`,  formData)
//     // axios.post(`https://docs.google.com/forms/d/e/1FAIpQLScFu_1DcBBZYVrPrFZgZRil3iDig7dUGC_C567Rb-IpDjY9yw/formResponse`, { formData })
//     .then(res => {
//       console.log(res);
//       console.log(res.data);
//     })
var str = Qs.stringify(formData)

////https://docs.google.com/forms/d/e/1FAIpQLSfH2-p_9RFtmIPiI0r3wW2N8FdfyRRn-ZubNSw6vN50QAbrog/formResponse
////https://e0f64a9b-35a4-42a9-ab13-ed1305b4d82a.mock.pstmn.io
 e.preventDefault();
 
  const url = "https://docs.google.com/forms/d/e/1FAIpQLSfH2-p_9RFtmIPiI0r3wW2N8FdfyRRn-ZubNSw6vN50QAbrog/formResponse";
  fetch(url,{  
   method: 'post',
   headers: {'Content-Type':'application/x-www-form-urlencoded'},
   mode: 'no-cors',
   body: str,
   redirect: 'follow'
  });
  e.target.reset();


}  
    return (
        <>
        <div className="App">
        <header className="App-header">
        <div className="container">
        <Grid className="test-card">
      <form onSubmit={onSubmit} onChange={onChange}>
    <fieldset>
        <h2 className='level-test'>{t("Leveltest")}<br/><small></small></h2>
    </fieldset>


    <fieldset>
        <legend for="416464545" className="name">{t("Writename")}:</legend>
        <div class="form-group">
            <input id="1337354118" type="text" name="entry.1337354118" class="form-control" required/>
        </div>
    </fieldset>
    <br/>
    <br/>
    <fieldset>
        <legend for="695519194" className="email">{t("Writemail")}:</legend>
        <div class="form-group">
            <input id="260198976" type="text" name="entry.260198976" class="form-control" />
        </div>
    </fieldset>
    <br/>
    <br/>
    <fieldset>
        <legend for="389530239" className="t-uno">1.- ¿Dónde _____ la estación, por favor?</legend>
        <Radio className="r-uno" name="groupOptions" name="entry.1891367556" value="hay" required> &nbsp;&nbsp;hay</Radio>
        <Radio className="r-uno" name="groupOptions" name="entry.1891367556" value="está" required> &nbsp;&nbsp;está</Radio>
        <Radio className="r-uno" name="groupOptions" name="entry.1891367556" value="están" required> &nbsp;&nbsp;están</Radio>
        {/* <div class="form-group">
            <div class="radio">
                <label>
                    <input type="radio" name="entry.1891367556" value="hay" required/>
                    hay
                </label>
            </div>
            <div class="radio">
                <label>
                    <input type="radio" name="entry.1891367556" value="está" required/>
                    está
                </label>
            </div>
            <div class="radio">
                <label>
                    <input type="radio" name="entry.1891367556" value="están" required/>
                    están
                </label>
            </div>
        </div> */}
    </fieldset>

    <fieldset>
        <legend for="2070777105" className="t-dos">2.- Javier ______ preocupado por el examen.</legend>
        <Radio className="r-dos" name="groupOptions" name="entry.1158892464" value="es" required> &nbsp;&nbsp;es</Radio>
        <Radio className="r-dos" name="groupOptions" name="entry.1158892464" value="está" required> &nbsp;&nbsp;está</Radio>
        <Radio className="r-dos" name="groupOptions" name="entry.1158892464" value="estoy" required> &nbsp;&nbsp;estoy</Radio>
        {/* <div class="form-group">
            <div class="radio">
                <label>
                    <input type="radio" name="entry.1158892464" value="es" required/>
                    es
                </label>
            </div>
            <div class="radio">
                <label>
                    <input type="radio" name="entry.1158892464" value="está" required/>
                    está
                </label>
            </div>
            <div class="radio">
                <label>
                    <input type="radio" name="entry.1158892464" value="estoy" required/>
                    estoy
                </label>
            </div>
        </div> */}
    </fieldset>

    <fieldset>
        <legend for="1389110991" className="t-tres">3.- María _______ en una cafetería.</legend>
        <Radio className="r-tres" name="groupOptions" name="entry.135737607" value="trabaja" required> &nbsp;&nbsp;trabaja</Radio>
        <Radio className="r-tres" name="groupOptions" name="entry.135737607" value="trabajo" required> &nbsp;&nbsp;trabajo</Radio>
        <Radio className="r-tres" name="groupOptions" name="entry.135737607" value="trabajas" required> &nbsp;&nbsp;trabajas</Radio>
        {/* <div class="form-group">
            <div class="radio">
                <label>
                    <input type="radio" name="entry.135737607" value="trabaja" required/>
                    trabaja
                </label>
            </div>
            <div class="radio">
                <label>
                    <input type="radio" name="entry.135737607" value="trabajo" required/>
                    trabajo
                </label>
            </div>
            <div class="radio">
                <label>
                    <input type="radio" name="entry.135737607" value="trabajas" required/>
                    trabajas
                </label>
            </div>
        </div> */}
    </fieldset>

    <fieldset>
        <legend for="633129789" className="t-cuatro">4.- Yo no ______ a tu novio. ¿Cuándo me lo presentas?</legend>
        <Radio className="r-cuatro" name="groupOptions" name="entry.1947499824" value="conozco" required> &nbsp;&nbsp;conozco</Radio>
        <Radio className="r-cuatro" name="groupOptions" name="entry.1947499824" value="conoce" required> &nbsp;&nbsp;conoce</Radio>
        <Radio className="r-cuatro" name="groupOptions" name="entry.1947499824" value="sé" required> &nbsp;&nbsp;sé</Radio>
        {/* <div class="form-group">
            <div class="radio">
                <label>
                    <input type="radio" name="entry.1947499824" value="conozco" required/>
                    conozco
                </label>
            </div>
            <div class="radio">
                <label>
                    <input type="radio" name="entry.1947499824" value="conoce" required/>
                    conoce
                </label>
            </div>
            <div class="radio">
                <label>
                    <input type="radio" name="entry.1947499824" value="sé" required/>
                    sé
                </label>
            </div>
        </div> */}
    </fieldset>


    <fieldset>
        <legend for="485719743" className="t-cinco">5.- ¿A ustedes ________ el cine español?</legend>
        <Radio className="r-cinco" name="groupOptions" name="entry.1964057963" value=" les gusta" required> &nbsp;&nbsp; les gusta</Radio>
        <Radio className="r-cinco" name="groupOptions" name="entry.1964057963" value=" les gustan" required> &nbsp;&nbsp; les gustan</Radio>
        <Radio className="r-cinco" name="groupOptions" name="entry.1964057963" value=" les gusta" required> &nbsp;&nbsp; le gustan</Radio>
        {/* <div class="form-group">
            <div class="radio">
                <label>
                    <input type="radio" name="entry.1964057963" value="les gusta" required/>
                    les gusta
                </label>
            </div>
            <div class="radio">
                <label>
                    <input type="radio" name="entry.1964057963" value="les gustan" required/>
                    les gustan
                </label>
            </div>
            <div class="radio">
                <label>
                    <input type="radio" name="entry.1964057963" value="le gustan" required/>
                    le gustan
                </label>
            </div>
        </div> */}
    </fieldset>

    <fieldset>
        <legend for="1213461059" className="t-seis">6.- No me encuentro bien. Me duele _____ cabeza.</legend>
        <Radio className="r-seis" name="groupOptions" name="entry.890194221" value="una" required> &nbsp;&nbsp;una</Radio>
        <Radio className="r-seis" name="groupOptions" name="entry.890194221" value="mi" required> &nbsp;&nbsp;mi</Radio>
        <Radio className="r-seis" name="groupOptions" name="entry.890194221" value="la" required> &nbsp;&nbsp;la</Radio>
        {/* <div class="form-group">
            <div class="radio">
                <label>
                    <input type="radio" name="entry.890194221" value="una" required/>
                    una
                </label>
            </div>
            <div class="radio">
                <label>
                    <input type="radio" name="entry.890194221" value="mi" required/>
                    mi
                </label>
            </div>
            <div class="radio">
                <label>
                    <input type="radio" name="entry.890194221" value="la" required/>
                    la
                </label>
            </div>
        </div> */}
    </fieldset>

    <fieldset>
        <legend for="74495738" className="t-siete">7.- ¿Dónde está Miguel?</legend>
        <Radio className="r-siete" name="groupOptions" name="entry.2074902217" value="Está duchándome" required> &nbsp;&nbsp;Está duchándome</Radio>
        <Radio className="r-siete" name="groupOptions" name="entry.2074902217" value="Está duchándote" required> &nbsp;&nbsp;Está duchándote</Radio>
        <Radio className="r-siete" name="groupOptions" name="entry.2074902217" value="Está duchándose" required> &nbsp;&nbsp;Está duchándose</Radio>
        {/* <div class="form-group">
            <div class="radio">
                <label>
                    <input type="radio" name="entry.2074902217" value="Está duchándome" required/>
                    Está duchándome
                </label>
            </div>
            <div class="radio">
                <label>
                    <input type="radio" name="entry.2074902217" value="Está duchándote" required/>
                    Está duchándote
                </label>
            </div>
            <div class="radio">
                <label>
                    <input type="radio" name="entry.2074902217" value="Está duchándose" required/>
                    Está duchándose
                </label>
            </div>
        </div> */}
    </fieldset>


    <fieldset>
        <legend for="758049292" className="t-ocho">8.- Señor, necesito saber la verdad. ________ todo, por favor.</legend>
        <Radio className="r-ocho" name="groupOptions" name="entry.1840829153" value="Cuéntamelo" required> &nbsp;&nbsp;Cuéntamelo</Radio>
        <Radio className="r-ocho" name="groupOptions" name="entry.1840829153" value="Cuéntamelo" required> &nbsp;&nbsp;Cuéntamelo</Radio>
        <Radio className="r-ocho" name="groupOptions" name="entry.1840829153" value="Me lo cuente" required> &nbsp;&nbsp;Me lo cuente</Radio>
        {/* <div class="form-group">
            <div class="radio">
                <label>
                    <input type="radio" name="entry.1840829153" value="Cuéntamelo" required/>
                    Cuéntamelo
                </label>
            </div>
            <div class="radio">
                <label>
                    <input type="radio" name="entry.1840829153" value="Cuéntemelo" required/>
                    Cuéntemelo
                </label>
            </div>
            <div class="radio">
                <label>
                    <input type="radio" name="entry.1840829153" value="Me lo cuente" required/>
                    Me lo cuente
                </label>
            </div>
        </div> */}
    </fieldset>


    <fieldset>
        <legend for="307399396" className="t-nueve">9.- Ayer ______ un día increíble. </legend>
        <Radio className="r-nueve" name="groupOptions" name="entry.1943180450" value="fue" required> &nbsp;&nbsp;fue</Radio>
        <Radio className="r-nueve" name="groupOptions" name="entry.1943180450" value="ha sido" required> &nbsp;&nbsp;ha sido</Radio>
        <Radio className="r-nueve" name="groupOptions" name="entry.1943180450" value="fui" required> &nbsp;&nbsp;fui</Radio>
        {/* <div class="form-group">
            <div class="radio">
                <label>
                    <input type="radio" name="entry.1943180450" value="fue" required/>
                    fue
                </label>
            </div>
            <div class="radio">
                <label>
                    <input type="radio" name="entry.1943180450" value="ha sido" required/>
                    ha sido
                </label>
            </div>
            <div class="radio">
                <label>
                    <input type="radio" name="entry.1943180450" value="fui" required/>
                    fui
                </label>
            </div>
        </div> */}
    </fieldset>

    <fieldset>
        <legend for="1116991084" className="t-diez">10.- Este hombre está muy __________.</legend>
        <img src="https://firebasestorage.googleapis.com/v0/b/logincomligo.appspot.com/o/other%2Fhombre.jpg?alt=media&token=35af5e0b-b9d1-45b1-b030-847ebb681573" className="hombre"/>
        <Radio className="r-diez" name="groupOptions" name="entry.412468466" value="preocupado" required> &nbsp;&nbsp;preocupado</Radio>
        <Radio className="r-diez" name="groupOptions" name="entry.412468466" value="contento" required> &nbsp;&nbsp;contento</Radio>
        <Radio className="r-diez" name="groupOptions" name="entry.412468466" value="satisfecho" required> &nbsp;&nbsp;satisfecho</Radio>
        {/* <div class="form-group">
            <div class="radio">
                <label>
                    <input type="radio" name="entry.412468466" value="preocupado" required/>
                    preocupado
                </label>
            </div>
            <div class="radio">
                <label>
                    <input type="radio" name="entry.412468466" value="contento" required/>
                    contento
                </label>
            </div>
            <div class="radio">
                <label>
                    <input type="radio" name="entry.412468466" value="satisfecho" required/>
                    satisfecho
                </label>
            </div>
        </div> */}
    </fieldset>

    <fieldset>
        <legend for="1682270516" className="t-once">11.- No pidas otra botella. Hoy no quiero beber _____</legend>
        <Radio className="r-once" name="groupOptions" name="entry.2021689485" value="mucho" required> &nbsp;&nbsp;mucho</Radio>
        <Radio className="r-once" name="groupOptions" name="entry.2021689485" value="algo" required> &nbsp;&nbsp;algo</Radio>
        <Radio className="r-once" name="groupOptions" name="entry.2021689485" value="muy" required> &nbsp;&nbsp;muy</Radio>
        {/* <div class="form-group">
            <div class="radio">
                <label>
                    <input type="radio" name="entry.2021689485" value="mucho" required/>
                    mucho
                </label>
            </div>
            <div class="radio">
                <label>
                    <input type="radio" name="entry.2021689485" value="algo" required/>
                    algo
                </label>
            </div>
            <div class="radio">
                <label>
                    <input type="radio" name="entry.2021689485" value="muy" required/>
                    muy
                </label>
            </div>
        </div> */}
    </fieldset>

    <fieldset>
        <legend for="731648012" className="t-doce">12.- ¡Tu teléfono es increíble! ________ ya tiene algunos años y no funciona muy bien.</legend>
        <Radio className="r-doce" name="groupOptions" name="entry.877181019" value="Mi" required> &nbsp;&nbsp;Mi</Radio>
        <Radio className="r-doce" name="groupOptions" name="entry.877181019" value="Mío" required> &nbsp;&nbsp;Mío</Radio>
        <Radio className="r-doce" name="groupOptions" name="entry.877181019" value="El mío" required> &nbsp;&nbsp;El mío</Radio>
        {/* <div class="form-group">
            <div class="radio">
                <label>
                    <input type="radio" name="entry.877181019" value="Mi" required/>
                    Mi
                </label>
            </div>
            <div class="radio">
                <label>
                    <input type="radio" name="entry.877181019" value="Mío" required/>
                    Mío
                </label>
            </div>
            <div class="radio">
                <label>
                    <input type="radio" name="entry.877181019" value="El mío" required/>
                    El mío
                </label>
            </div>
        </div> */}
    </fieldset>

    <fieldset>
        <legend for="938243444" className="t-trece">13.- Ernesto es un estudiante excepcional. Es _______ disciplinado ____ su clase.</legend>
        <Radio className="r-doce" name="groupOptions" name="entry.231257521" value="más / de" required> &nbsp;&nbsp;más / de</Radio>
        <Radio className="r-doce" name="groupOptions" name="entry.231257521" value="el más / que" required> &nbsp;&nbsp;el más / que</Radio>
        <Radio className="r-doce" name="groupOptions" name="entry.231257521" value="el más / de" required> &nbsp;&nbsp;el más / de</Radio>
        {/* <div class="form-group">
            <div class="radio">
                <label>
                    <input type="radio" name="entry.231257521" value="más / de" required/>
                    más / de
                </label>
            </div>
            <div class="radio">
                <label>
                    <input type="radio" name="entry.231257521" value="el más / que" required/>
                    el más / que
                </label>
            </div>
            <div class="radio">
                <label>
                    <input type="radio" name="entry.231257521" value="el más / de" required/>
                    el más / de
                </label>
            </div>
        </div> */}
    </fieldset>

    <fieldset>
        <legend for="1506897480" className="t-catorce">14.- Ayer te llamé pero no respondiste. ¿Qué ___________?</legend>
        <Radio className="r-diezcuatro" name="groupOptions" name="entry.252630313" value="has hecho" required> &nbsp;&nbsp;has hecho</Radio>
        <Radio className="r-diezcuatro" name="groupOptions" name="entry.252630313" value="hiciste" required> &nbsp;&nbsp;hiciste</Radio>
        <Radio className="r-diezcuatro" name="groupOptions" name="entry.252630313" value="estabas haciendo" required> &nbsp;&nbsp;estabas haciendo</Radio>
        {/* <div class="form-group">
            <div class="radio">
                <label>
                    <input type="radio" name="entry.252630313" value="has hecho" required/>
                    has hecho
                </label>
            </div>
            <div class="radio">
                <label>
                    <input type="radio" name="entry.252630313" value="hiciste" required/>
                    hiciste
                </label>
            </div>
            <div class="radio">
                <label>
                    <input type="radio" name="entry.252630313" value="estabas haciendo" required/>
                    estabas haciendo
                </label>
            </div>
        </div> */}
    </fieldset>

    <fieldset>
        <legend for="1309866975" className="t-quince">15.- Cuando era pequeña ______________ ir al río y dar de comer a los peces.</legend>
        <Radio className="r-diezcinco" name="groupOptions" name="entry.138872936" value="me gustaba" required> &nbsp;&nbsp;me gustaba</Radio>
        <Radio className="r-diezcinco" name="groupOptions" name="entry.138872936" value="me ha gustado" required> &nbsp;&nbsp;me ha gustado</Radio>
        <Radio className="r-diezcinco" name="groupOptions" name="entry.138872936" value="me había gustado" required> &nbsp;&nbsp;me había gustado</Radio>
        {/* <div class="form-group">
            <div class="radio">
                <label>
                    <input type="radio" name="entry.138872936" value="me gustaba" required/>
                    me gustaba
                </label>
            </div>
            <div class="radio">
                <label>
                    <input type="radio" name="entry.138872936" value="me ha gustado" required/>
                    me ha gustado
                </label>
            </div>
            <div class="radio">
                <label>
                    <input type="radio" name="entry.138872936" value="me había gustado" required/>
                    me había gustado
                </label>
            </div>
        </div> */}
    </fieldset>

    <fieldset>
        <legend for="754332551" className="t-diezseis">16.- Cuando me dijo la verdad _________ muy sorprendido.</legend>
        <Radio className="r-diezseis" name="groupOptions" name="entry.1900313651" value="me quedé" required> &nbsp;&nbsp;me quedé</Radio>
        <Radio className="r-diezseis" name="groupOptions" name="entry.1900313651" value="me puse" required> &nbsp;&nbsp;me puse</Radio>
        <Radio className="r-diezseis" name="groupOptions" name="entry.1900313651" value="me volví" required> &nbsp;&nbsp;me volví</Radio>
        {/* <div class="form-group">
            <div class="radio">
                <label>
                    <input type="radio" name="entry.1900313651" value="me quedé" required/>
                    me quedé
                </label>
            </div>
            <div class="radio">
                <label>
                    <input type="radio" name="entry.1900313651" value="me puse" required/>
                    me puse
                </label>
            </div>
            <div class="radio">
                <label>
                    <input type="radio" name="entry.1900313651" value="me volví" required/>
                    me volví
                </label>
            </div>
        </div> */}
    </fieldset>

    <fieldset>
        <legend for="1112803096" className="t-diezsiete">17.- ¿Recuerdas aquella serie de policías de la que te hablé? Al principio no me gustaba, pero ahora ______ muy interesante.Tengo ganas de ver los últimos capítulos.</legend>
        <Radio className="r-diezsiete" name="groupOptions" name="entry.239882205" value="es" required> &nbsp;&nbsp;es</Radio>
        <Radio className="r-diezsiete" name="groupOptions" name="entry.239882205" value="está" required> &nbsp;&nbsp;está</Radio>
        <Radio className="r-diezsiete" name="groupOptions" name="entry.239882205" value="me quedé" required> &nbsp;&nbsp;ha estado</Radio>

        {/* <div class="form-group">
            <div class="radio">
                <label>
                    <input type="radio" name="entry.239882205" value="es" required/>
                    es
                </label>
            </div>
            <div class="radio">
                <label>
                    <input type="radio" name="entry.239882205" value="está" required/>
                    está
                </label>
            </div>
            <div class="radio">
                <label>
                    <input type="radio" name="entry.239882205" value="ha estado" required/>
                    ha estado
                </label>
            </div>
        </div> */}
    </fieldset>

    <fieldset>
        <legend for="201286316" className="t-diezocho">18.- Ojalá _______ tener más tiempo libre este año. El año pasado fue muy estresante.</legend>
        <Radio className="r-diezocho" name="groupOptions" name="entry.1613299571" value="pueda" required> &nbsp;&nbsp;pueda</Radio>
        <Radio className="r-diezocho" name="groupOptions" name="entry.1613299571" value="puede" required> &nbsp;&nbsp;puede</Radio>
        <Radio className="r-diezocho" name="groupOptions" name="entry.1613299571" value="podría" required> &nbsp;&nbsp;podría</Radio>

        {/* <div class="form-group">
            <div class="radio">
                <label>
                    <input type="radio" name="entry.1613299571" value="pueda" required/>
                    pueda
                </label>
            </div>
            <div class="radio">
                <label>
                    <input type="radio" name="entry.1613299571" value="puede" required/>
                    puede
                </label>
            </div>
            <div class="radio">
                <label>
                    <input type="radio" name="entry.1613299571" value="podría" required/>
                    podría
                </label>
            </div>
        </div> */}
    </fieldset>

    <fieldset>
        <legend for="845585707" className="t-dieznueve">19.- ¿Su encargo? No se preocupe. En cuanto lo ________ listo, le llamaré.</legend>
        <Radio className="r-dieznueve" name="groupOptions" name="entry.667077401" value="tenga" required> &nbsp;&nbsp;tenga</Radio>
        <Radio className="r-dieznueve" name="groupOptions" name="entry.667077401" value="tengo" required> &nbsp;&nbsp;tengo</Radio>
        <Radio className="r-dieznueve" name="groupOptions" name="entry.667077401" value="tendría" required> &nbsp;&nbsp;tendría</Radio>
        {/* <div class="form-group">
            <div class="radio">
                <label>
                    <input type="radio" name="entry.667077401" value="tenga" required/>
                    tenga
                </label>
            </div>
            <div class="radio">
                <label>
                    <input type="radio" name="entry.667077401" value="tengo" required/>
                    tengo
                </label>
            </div>
            <div class="radio">
                <label>
                    <input type="radio" name="entry.667077401" value="tendría" required/>
                    tendría
                </label>
            </div>
        </div> */}
    </fieldset>

    <fieldset>
        <legend for="1726897083" className="t-veinte">20.- No puedo creer que tu novio todavía no _________ lo de tu divorcio.</legend>
        <legend className="t-veinte-dos">Creo que deberías decírselo cuanto antes.</legend>
        <Radio className="r-veinte" name="groupOptions" name="entry.1053287919" value="sabría" required> &nbsp;&nbsp;sabría</Radio>
        <Radio className="r-veinte" name="groupOptions" name="entry.1053287919" value="sabe" required> &nbsp;&nbsp;sabe</Radio>
        <Radio className="r-veinte" name="groupOptions" name="entry.1053287919" value="sepa" required> &nbsp;&nbsp;sepa</Radio>
        {/* <div class="form-group">
            <div class="radio">
                <label>
                    <input type="radio" name="entry.1053287919" value="sabría" required/>
                    sabría
                </label>
            </div>
            <div class="radio">
                <label>
                    <input type="radio" name="entry.1053287919" value="sabe" required/>
                    sabe
                </label>
            </div>
            <div class="radio">
                <label>
                    <input type="radio" name="entry.1053287919" value="sepa" required/>
                    sepa
                </label>
            </div>
        </div> */}
    </fieldset>

    <fieldset>
        <legend for="709026287" className="t-veinteuno">21.- No puedo perdonar que ____________.</legend>
        <Radio className="r-veinteuno" name="groupOptions" name="entry.846959831" value="me mentiera" required> &nbsp;&nbsp;me mentiera</Radio>
        <Radio className="r-veinteuno" name="groupOptions" name="entry.846959831" value="me mentiría" required> &nbsp;&nbsp;me mentiría</Radio>
        <Radio className="r-veinteuno" name="groupOptions" name="entry.846959831" value="me mintiera" required> &nbsp;&nbsp;me mintiera</Radio>
        {/* <div class="form-group">
            <div class="radio">
                <label>
                    <input type="radio" name="entry.846959831" value="me mentiera" required/>
                    me mentiera
                </label>
            </div>
            <div class="radio">
                <label>
                    <input type="radio" name="entry.846959831" value="me mentiría" required/>
                    me mentiría
                </label>
            </div>
            <div class="radio">
                <label>
                    <input type="radio" name="entry.846959831" value="me mintiera" required/>
                    me mintiera
                </label>
            </div>
        </div> */}
    </fieldset>

    <fieldset>
        <legend for="1906375757" className="t-veintedos">22.- Si ________, cambiaría muchas cosas que hice en el pasado.</legend>
        <Radio className="r-veintedos" name="groupOptions" name="entry.879066160" value="pueda" required> &nbsp;&nbsp;pueda</Radio>
        <Radio className="r-veintedos" name="groupOptions" name="entry.879066160" value="podría" required> &nbsp;&nbsp;podría</Radio>
        <Radio className="r-veintedos" name="groupOptions" name="entry.879066160" value="pudiera" required> &nbsp;&nbsp;pudiera</Radio>
        {/* <div class="form-group">
            <div class="radio">
                <label>
                    <input type="radio" name="entry.879066160" value="pueda" required/>
                    pueda
                </label>
            </div>
            <div class="radio">
                <label>
                    <input type="radio" name="entry.879066160" value="podría" required/>
                    podría
                </label>
            </div>
            <div class="radio">
                <label>
                    <input type="radio" name="entry.879066160" value="pudiera" required/>
                    pudiera
                </label>
            </div>
        </div> */}
    </fieldset>

    <fieldset>
        <legend for="1493327194" className="t-veintetres">23.- No lo hice porque no me dijiste que lo ________.</legend>
        <Radio className="r-veintetres" name="groupOptions" name="entry.668064054" value="lo haya hecho" required> &nbsp;&nbsp;lo haya hecho</Radio>
        <Radio className="r-veintetres" name="groupOptions" name="entry.668064054" value="hiciera" required> &nbsp;&nbsp;hiciera</Radio>
        <Radio className="r-veintetres" name="groupOptions" name="entry.668064054" value="hago" required> &nbsp;&nbsp;hago</Radio>

        {/* <div class="form-group">
            <div class="radio">
                <label>
                    <input type="radio" name="entry.668064054" value="lo haya hecho" required/>
                    lo haya hecho
                </label>
            </div>
            <div class="radio">
                <label>
                    <input type="radio" name="entry.668064054" value="hiciera" required/>
                    hiciera
                </label>
            </div>
            <div class="radio">
                <label>
                    <input type="radio" name="entry.668064054" value="hago" required/>
                    hago
                </label>
            </div>
        </div> */}
    </fieldset>

    <fieldset>
        <legend for="1432099901" className="t-veintecuatro">24.- Pensé en regalarte este libro pero me pareció que quizás ya lo __________________.</legend>
        <Radio className="r-veintecuatro" name="groupOptions" name="entry.1596369480" value="habrás leído" required> &nbsp;&nbsp;habrás leído</Radio>
        <Radio className="r-veintecuatro" name="groupOptions" name="entry.1596369480" value="habrías leído" required> &nbsp;&nbsp;habrías leído</Radio>
        <Radio className="r-veintecuatro" name="groupOptions" name="entry.1596369480" value="hayas leído" required> &nbsp;&nbsp;hayas leído</Radio>
        {/* <div class="form-group">
            <div class="radio">
                <label>
                    <input type="radio" name="entry.1596369480" value="habrás leído" required/>
                    habrás leído
                </label>
            </div>
            <div class="radio">
                <label>
                    <input type="radio" name="entry.1596369480" value="habrías leído" required/>
                    habrías leído
                </label>
            </div>
            <div class="radio">
                <label>
                    <input type="radio" name="entry.1596369480" value="hayas leído" required/>
                    hayas leído
                </label>
            </div>
        </div> */}
    </fieldset>

    <fieldset>
        <legend for="808046487" className="t-veintecinco">25.- Ahora ya no es posible, pero si ____________ a verla, le pediría perdón.</legend>
        <Radio className="r-veintecinco" name="groupOptions" name="entry.2077079766" value="volvería" required> &nbsp;&nbsp;volvería</Radio>
        <Radio className="r-veintecinco" name="groupOptions" name="entry.2077079766" value="volviera" required> &nbsp;&nbsp;volviera</Radio>
        <Radio className="r-veintecinco" name="groupOptions" name="entry.2077079766" value="vuelva" required> &nbsp;&nbsp;vuelva</Radio>
        {/* <div class="form-group">
            <div class="radio">
                <label>
                    <input type="radio" name="entry.2077079766" value="volvería" required/>
                    volvería
                </label>
            </div>
            <div class="radio">
                <label>
                    <input type="radio" name="entry.2077079766" value="volviera" required/>
                    volviera
                </label>
            </div>
            <div class="radio">
                <label>
                    <input type="radio" name="entry.2077079766" value="vuelva" required/>
                    vuelva
                </label>
            </div>
        </div> */}
    </fieldset>

    <fieldset>
        <legend for="1193818928" className="t-veinteseis">26.- Van a seguir adelante con el plan aunque yo ya __________ que no estoy de acuerdo.</legend>
        <Radio className="r-veinteseis" name="groupOptions" name="entry.331829460" value="dije" required> &nbsp;&nbsp;dije</Radio>
        <Radio className="r-veinteseis" name="groupOptions" name="entry.331829460" value="diga" required> &nbsp;&nbsp;diga</Radio>
        <Radio className="r-veinteseis" name="groupOptions" name="entry.331829460" value="diría" required> &nbsp;&nbsp;diría</Radio>
        {/* <div class="form-group">
            <div class="radio">
                <label>
                    <input type="radio" name="entry.331829460" value="dije" required/>
                    dije
                </label>
            </div>
            <div class="radio">
                <label>
                    <input type="radio" name="entry.331829460" value="diga" required/>
                    diga
                </label>
            </div>
            <div class="radio">
                <label>
                    <input type="radio" name="entry.331829460" value="diría" required/>
                    diría
                </label>
            </div>
        </div> */}
    </fieldset>

    <fieldset>
        <legend for="1865452143" className="t-veintesiete">27.- El proyecto sigue adelante _________ los problemas.</legend>
        <Radio className="r-veintesiete" name="groupOptions" name="entry.763540468" value="a pesar de" required> &nbsp;&nbsp;a pesar de</Radio>
        <Radio className="r-veintesiete" name="groupOptions" name="entry.763540468" value="aunque" required> &nbsp;&nbsp;aunque</Radio>
        <Radio className="r-veintesiete" name="groupOptions" name="entry.763540468" value="a pesar de que" required> &nbsp;&nbsp;a pesar de que</Radio>

        {/* <div class="form-group">
            <div class="radio">
                <label>
                    <input type="radio" name="entry.763540468" value="a pesar de" required/>
                    a pesar de
                </label>
            </div>
            <div class="radio">
                <label>
                    <input type="radio" name="entry.763540468" value="aunque" required/>
                    aunque
                </label>
            </div>
            <div class="radio">
                <label>
                    <input type="radio" name="entry.763540468" value="a pesar de que" required/>
                    a pesar de que
                </label>
            </div>
        </div> */}
    </fieldset>


   
    <fieldset>
        <legend for="1419931548" className="t-veinteocho">28.- Si no tuviera tanto trabajo, me ___________ a un curso de yoga.</legend>
        <Radio className="r-veinteocho" name="groupOptions" name="entry.380208032" value="apuntara" required> &nbsp;&nbsp;apuntara</Radio>
        <Radio className="r-veinteocho" name="groupOptions" name="entry.380208032" value="apuntaría" required> &nbsp;&nbsp;apuntaría</Radio>
        <Radio className="r-veinteocho" name="groupOptions" name="entry.380208032" value="apuntase" required> &nbsp;&nbsp;apuntase</Radio>
        {/* <div class="form-group">
            <div class="radio">
                <label>
                    <input type="radio" name="entry.380208032" value="apuntara" required/>
                    apuntara
                </label>
            </div>
            <div class="radio">
                <label>
                    <input type="radio" name="entry.380208032" value="apuntaría" required/>
                    apuntaría
                </label>
            </div>
            <div class="radio">
                <label>
                    <input type="radio" name="entry.380208032" value="apuntase" required/>
                    apuntase
                </label>
            </div>
        </div> */}
    </fieldset>



    <fieldset>
        <legend for="1478860812" className="t-veintenueve">29.- Mereces está __________.</legend>
        <img src="https://firebasestorage.googleapis.com/v0/b/logincomligo.appspot.com/o/other%2Fmujer.jpg?alt=media&token=3e062038-b7c4-4459-997e-477e3f376f40" className="mujer"/>
        <Radio className="r-veintenueve" name="groupOptions" name="entry.1226727431" value="indignada" required> &nbsp;&nbsp;indignada</Radio>
        <Radio className="r-veintenueve" name="groupOptions" name="entry.1226727431" value="indecisa" required> &nbsp;&nbsp;indecisa</Radio>
        <Radio className="r-veintenueve" name="groupOptions" name="entry.1226727431" value="inmisericorde" required> &nbsp;&nbsp;inmisericorde</Radio>

        {/* <div class="form-group">
            <div class="radio">
                <label>
                    <input type="radio" name="entry.1226727431" value="indignada" required/>
                    indignada
                </label>
            </div>
            <div class="radio">
                <label>
                    <input type="radio" name="entry.1226727431" value="indecisa" required/>
                    indecisa
                </label>
            </div>
            <div class="radio">
                <label>
                    <input type="radio" name="entry.1226727431" value="inmisericorde" required/>
                    inmisericorde
                </label>
            </div>
        </div> */}
    </fieldset>



    <fieldset>
        <legend for="642667660" className="t-treinta">30.- ¡El concierto acaba de terminar! ¡Qué rabia que no ________________ a tiempo!</legend>
        <Radio className="r-treinta" name="groupOptions" name="entry.236285896" value="hubierais llegado" required> &nbsp;&nbsp;hubierais llegado</Radio>
        <Radio className="r-treinta" name="groupOptions" name="entry.236285896" value="hayáis llegado" required> &nbsp;&nbsp;hayáis llegado</Radio>
        <Radio className="r-treinta" name="groupOptions" name="entry.236285896" value="lleguéis" required> &nbsp;&nbsp;lleguéis</Radio>
        {/* <div class="form-group">
            <div class="radio">
                <label>
                    <input type="radio" name="entry.236285896" value="hubierais llegado" required/>
                    hubierais llegado
                </label>
            </div>
            <div class="radio">
                <label>
                    <input type="radio" name="entry.236285896" value="hayáis llegado" required/>
                    hayáis llegado
                </label>
            </div>
            <div class="radio">
                <label>
                    <input type="radio" name="entry.236285896" value="lleguéis" required/>
                    lleguéis
                </label>
            </div>
        </div> */}
    </fieldset>

    <input type="hidden" name="fvv" value="1"/>
    <input type="hidden" name="fbzx" value="5788313012252782964"/>
 
    <input type="hidden" name="pageHistory" value="0"/>
   <br></br>
    <Button className="btn-primary" type="submit">{t("Send")}</Button>
</form>
       </Grid>
      </div>
    </header>
</div>
</>
    )
}
