import React, { useState, useEffect } from "react";
import { Loader,Table,Icon,Grid } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import { map } from "lodash";
import { useTranslation } from "react-i18next";
import ReactPlayer from "react-player";
import firebase from "../../../utils/Firebase";
import "firebase/firestore";
import "firebase/storage";

import './videodetails.scss';

const db = firebase.firestore(firebase);

function Videodetails(props) {

    const {match}=props;
   // console.log(match);
   const [videoalbum, setvideoAlbum] = useState(null);
   const [videoalbumImg, setvideoAlbumImg] = useState(null);
   const [video, setVideo] = useState([]);
   const { t } = useTranslation();
   //console.log(video);

        useEffect(() => {
            db.collection("videoalbums")
            .doc(match.params.id)
            .get()
            .then(response => {           
            // console.log(response);            
            const data = response.data();
            data.id = response.id;
            //console.log(data);
            // setCourselist(data.id)
            setvideoAlbum(data);
            });
        }, [match])

        useEffect(() => {
            if (videoalbum) {
                firebase
                .storage()
                .ref(`videoalbum/${videoalbum?.banner}`)
                .getDownloadURL()
                .then(url => {
                    //console.log(url);
                    setvideoAlbumImg(url);
                });
            }
        }, [videoalbum])


        useEffect(() => {
          if (videoalbum){
            db.collection("videos")
            .where("videoalbum", "==", videoalbum.id)
            .get()
            .then(response =>{
             const arrayRecords=[];
             map(response?.docs, record=>{
               const data = record.data();
               data.id = record.id;
               arrayRecords.push(data);
             });
             setVideo(arrayRecords);
            });
          }
       }, [videoalbum])  

        if(!videoalbum ){
            return <Loader active>{t("Loading")}..</Loader>;
        }

    return (
     <>
        <div className="video-album">
          <div className="video-album__header">
              <HeaderAlbum videoalbum={videoalbum} videoalbumImg={videoalbumImg} t={t}/>
          </div>
        </div>
        <div>
          <ListVideos video={video} videoalbumImg={videoalbumImg} t={t}/>
        </div>
      </>
    )
}


function HeaderAlbum(props) {
    const { videoalbum, videoalbumImg,t} = props;
   
    return (
      <>
        <div
          className="video-image"
          style={{ backgroundImage: `url('${videoalbumImg}')` }}
        />
        <div className="video-info">
          <h1>{videoalbum.name}</h1>
          <h2 className="video-subtitle">{t("Videoresources")}</h2>
          <div className="sub-video">
          </div>
        </div>
      </>
    );
  }

  function ListVideos(props) {

    const { video,videoalbumImg,t }= props;
    // console.log(props);
    return (
      //   <Table inverted className="list-videos">
      //   <Table.Header>
      //     <Table.Row>
      //       <Table.HeaderCell />
      //       <Table.HeaderCell><p className="title-video-list">{t("Videoresources")}:</p></Table.HeaderCell>
      //     </Table.Row>
      //   </Table.Header>
  
      //   <Table.Body>
      //   {map(video, video => (
      //     <Videorecord
      //       key={video.id}
      //       video={video}
      //       videoalbumImg={videoalbumImg}
  
      //     />
      //   ))}
      //   </Table.Body>
      // </Table>
      <Grid columns={3} divided mobile={16} tablet={8} computer={4} style={{position: 'relative',left: 20}}>
            <Grid.Row>          
              {map(video, video => (
                <Videorecord
                  key={video.id}
                  video={video}
                  videoalbumImg={videoalbumImg}
                />
              ))}
            </Grid.Row>  
      </Grid>
    );
  }


function Videorecord(props){

  const { video }= props;

  const [playing, setPlaying] = useState(false);
  //const [recordData, setrecordData] = useState(null);
  //console.log(video);
 // console.log(recordData);

  // useEffect(() => {
  //   firebase
  //   .storage()
  //   .ref(`recordings/${record.fileName}`)
  //   .getDownloadURL()
  //   .then(url =>{
  //     //console.log(url);
  //     setrecordData({url, name: record.name });
  //   }) 
  // }, [recordData])

  const onStart =()=>{
    setPlaying(true);
 }  

 ////pause player
const onPause =()=>{
   setPlaying(false);
}



    return (
      <>
          {/* <Table.Row  >
            <Table.Cell collapsing>
            {playing ? (
                <Icon  onClick={onPause} name="pause circle outline"  size='large'/>
              ) : (
                <Icon  onClick={onStart} name="play circle outline"  size='large'/>
              )}
            </Table.Cell>
            <Table.Cell><p className="record-video-list">{video.name}</p></Table.Cell>
          </Table.Row>
            <ReactPlayer
          url={video.link}
          playing={playing}
          controls
          width = "400px"
          height = "504px"
          /> */}

        <Grid.Column>
          <br/>
          <br/>
          <br/>
          <Table inverted className="list-videos">  
            <Table.Body> 
            <Table.Row  >
              <Table.Cell collapsing>
              {playing ? (
                  <Icon  onClick={onPause} name="pause circle outline"  size='large'/>
                ) : (
                  <Icon  onClick={onStart} name="play circle outline"  size='large'/>
                )}
              </Table.Cell>
              <Table.Cell><p className="record-video-list">{video.name}</p></Table.Cell>
            </Table.Row>
            </Table.Body>
          </Table> 
          <ReactPlayer
          url={video.link}
          playing={playing}
          controls
          width = "80%"
          height = "80%"
          />
        </Grid.Column>
      </>
    );
  }

export default withRouter(Videodetails);