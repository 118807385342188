import React,{useState,useEffect} from 'react';
import {Button,Label,Segment} from 'semantic-ui-react';
import {Link} from "react-router-dom";
import {Grid} from 'react-bootstrap';
import { toast } from "react-toastify";
import { map } from "lodash";
import { useTranslation, Trans } from "react-i18next";

import BannerHome from "../../components/BannerHome";
import BasicSliderItems from "../../components/Sliders/BasicSliderItems";
/////message
// import Messages from "../../components/Message/Messages";
////
import firebase from "../../utils/Firebase";
import 'firebase/firestore';
import './Home.scss'


const db = firebase.firestore(firebase);
var data;


function Home(props) {
    const {user}=props;
    const [hidetext, setHidetext] = useState(true);
    const [show, setShow] = useState(0);    
    const [promotions, setPromotions] = useState([]);
    const [resources, setResources] = useState([]);
    const { t } = useTranslation();
    // console.log(users);
    // console.log(teachers);
    //console.log(resources);
// change language
// const changeLanguage = lng => {
//   i18n.changeLanguage(lng);
// };
//////////register
    const register=()=>{
        if(user.uid===data.uid){
            setHidetext(prevState => !prevState)
            setShow(!show)
        }
        if(user.uid!==data.uid){
                db.collection("users").add(
                    {
                      uid:user.uid,
                      username: user.displayName, 
                      email: user.email 
                  })
                  .then(()=>{
              // console.log('succes');
               toast.success("The terms are already accepted");
               db.collection("regularuser").doc(user.uid).set({
                username: user.displayName, 
                email: user.email 
            })
            .then(function() {
              //  console.log("Document successfully written!");
            })
            .catch(function(error) {
               // console.error("Error writing document: ", error);
            });
                  }).catch(() => {
                 //  console.log('error');
                   }) 
    
                   setHidetext(prevState => !prevState)
                   setShow(!show)           
        }
    
    }
     
    useEffect(() => {
        db.collection("users")
        .get()
        .then(response => {
         //  console.log(response);            
       
          map(response?.docs, users => {
       data = users.data();
        // console.log(user.uid);
        // console.log(data.uid);
       
  if(user.uid===data.uid){
    // console.log("son igual");  
    setHidetext(false)
    setShow(!show)
  }
          });
       
        //  setUsers(arrayUsers);
        });
    }, [])
/////////////promo slider

useEffect(() => {
  db.collection("promotions")
  .get().then(response=>{
    //  console.log(response);
    const arrayPromotions = [];
   map(response?.docs,promotions =>{
      // console.log(teachers.data());
       const data=promotions.data();
       data.id = promotions.id;
       arrayPromotions.push(data);
   });
   setPromotions(arrayPromotions);
    /*
      response.docs.map(teacher => {
          console.log(teacher.data());
      })*/
  });
 }, []);

////////////////
useEffect(() => {
  db.collection("resources")
  .get().then(response=>{
    //  console.log(response);
    const arrayResources = [];
   map(response?.docs,resources =>{
      // console.log(teachers.data());
       const data=resources.data();
       data.id = resources.id;
       arrayResources.push(data);
   });
   setResources(arrayResources);
    /*
      response.docs.map(teacher => {
          console.log(teacher.data());
      })*/
  });
 }, []);


    
    return (
        <>
        <BannerHome/>
        <br></br>
        <br></br>
       <BasicSliderItems 
        title={t("News")}
        data={promotions} 
        folderImage="promotions" 
        urlName="promotions"
        />
       
       <BasicSliderItems  
       title={t("Resources")}
       data={resources} 
       folderImage="resources" 
       urlName="resources"
       />
       <br></br>
       <br></br>
        {/*  */}
        <div>
        {/* <Messages/> */}
        </div>
       
        </>
    )
}


export default Home;