import React, { useState, useEffect} from "react";
import { Form, Button, Dropdown,Icon,Input } from "semantic-ui-react";
import { map } from "lodash";
import { toast } from "react-toastify";
import firebase from "../../../utils/Firebase";
import "firebase/firestore";
import "firebase/storage";
// import NoImage from "../../../assets/png/no-image.png";

import "./AddStudentForm.scss";

const db = firebase.firestore(firebase);

const Courses= [
  { key: '1', value: 'general',text: 'General Spanish' },
  { key: '2', value: 'diversity' ,text: 'Diversity'},
  { key: '3', value: 'business' ,text: 'For Business'},
  { key: '4', value: 'tourism' ,text: 'For Tourism'},
  { key: '5', value: 'culture' ,text: 'Culture and Civilization'}, 
  { key: '6', value: 'ccse' ,text: 'Preparation for the CCSE'}, 
  { key: '7', value: 'dele' ,text: 'Preparation for the DELE'}, 
  { key: '8', value: 'siele' ,text: 'Preparation for the SIELE'}, 
  { key: '9', value: 'health' ,text: 'For Health Care'}, 
  { key: '10', value: 'law' ,text: 'For Law'}, 
  { key: '11', value: 'lgtbi' ,text: 'For LGTBI'}, 
  { key: '12', value: 'religious' ,text: 'For Religious'}, 
  { key: '13', value: 'security' ,text: 'For Security'}, 
  { key: '14', value: 'sephardis' ,text: 'For Sephardis'}, 
  { key: '15', value: 'training' ,text: 'Teacher Training'}, 
]

const Level= [
  { key: '1', value: 'advanced',text: 'Advanced' },
  { key: '2', value: 'beginner' ,text: 'Beginner'},
  { key: '3', value: 'intermediate' ,text: 'Intermediate'},
  { key: '4', value: 'a1' ,text: 'A1'},
  { key: '5', value: 'a2' ,text: 'A2'},
  { key: '6', value: 'b1' ,text: 'B1'},
  { key: '7', value: 'b2' ,text: 'B2'},
  { key: '8', value: 'c1' ,text: 'C1'},
  { key: '9', value: 'c2' ,text: 'C2'},
  { key: '10', value: 'm' ,text: 'M'},
  { key: '11', value: '1' ,text: 'N/A'},
]  

const Sublevel= [
  { key: '1', value: '1',text: '1' },
  { key: '2', value: '2' ,text: '2'},
  { key: '3', value: '3' ,text: '3'},
  { key: '4', value: '4' ,text: '4'},
  { key: '5', value: '5' ,text: '5'},
  { key: '6', value: '6' ,text: '6'},
  { key: '7', value: '1' ,text: 'N/A'},
]  


export default function AddStudentForm(props) {
   
    const {setShowModal}= props;
     const [teaching, setTeaching] = useState([]);
    const [classroom, setClassroom] = useState([]);
    const [formData, setFormData] = useState(initialValueForm());
    const [listcourse, setListcourse] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const [user, setUser] = useState([]);

    const [username, setUsername] = useState([])
//console.log(teachers);
 //console.log(user);
 //console.log(classroom.id);
 ///////////////list course
 useEffect(() => {
  db.collection("available")
  .get()
  .then(response => {
   //  console.log(response);
      
    const arrayListcourses = [];
    map(response?.docs, listcourses => {
      const data = listcourses.data();
   //  console.log(data);
      arrayListcourses.push({
        key:  listcourses.id,
        value: data.courselink,
        text: data.coursename,
      //   uid: data.uid
      });
    });
  // console.log(arrayUsers);
  setListcourse(arrayListcourses);
  });
 }, [])
////////Userslist id
    useEffect(() => {
        db.collection("users")
        .get()
        .then(response => {
         //  console.log(response);
            
          const arrayUsers = [];
          map(response?.docs, users => {
            const data = users.data();
         //  console.log(data);
        // data.id=users.id;
           arrayUsers.push({
              key: users.id,
              value: data.uid,
              text: data.email,
            //  uid: users.id
            });
          });
        // console.log(arrayUsers);
         setUser(arrayUsers);
        });

    }, [])
/////////////////////username
useEffect(() => {
  db.collection("users")
  .get()
  .then(response => {
   //  console.log(response);
      
    const arrayUsuarios = [];
    map(response?.docs, usuariosname => {
      const data = usuariosname.data();
   //  console.log(data);
  // data.id=users.id;
      arrayUsuarios.push({
        key: usuariosname.id,
        value: data.username,
        text: data.email,
      //  uid: users.id
      });
    });
  // console.log(arrayUsers);
  setUsername(arrayUsuarios);
  });

}, [])

//////////Classroomlist
    useEffect(() => {
        db.collection("classroom")
        .get()
        .then(response => {
           // console.log(response);
            
          const arrayClassroom = [];
          map(response?.docs, classrooms => {
            const data = classrooms.data();
           // console.log(data);
          // console.log(classrooms.id);
            arrayClassroom.push({
              key: classrooms.id,
             // value: classrooms.id,
              value: data.classname,
              text: data.classname
            });
          });
        //  console.log(arrayTeachers);
          setClassroom(arrayClassroom);
        });

    }, [])
////////Teacherslist
useEffect(() => {
  db.collection("teacherlist")
        .get()
        .then(response => {
         //  console.log(response);
            
          const arrayTeachers = [];
          map(response?.docs, teachers => {
            const data = teachers.data();
         //  console.log(data);
        // data.id=users.id;
        arrayTeachers.push({
              key: teachers.id,
              value: data.username,
              text: data.email,
            //  uid: users.id
            });
          });
      //  console.log( arrayTeachers);
        setTeaching(arrayTeachers);
        });
}, [])

//////////Submit data

    const onSubmit=()=>{    
   //  console.log(formData);  
        setShowModal(false);
        db.collection("studentclass")
                    .add({
                      classday:formData.classday,
                      classname: formData.classroom,
                      classtime:formData.classtime,
                      course:formData.course,
                      courselink:formData.courselink,
                      level:formData.level,
                      link:formData.link,
                      studentid: formData.student,
                      studentname: formData.studentname,
                      sublevel: formData.sublevel,
                      teacher: formData.teacher,
                      completion:formData.completion
                      })
                      .then(() => {
                        toast.success("The student information is already added.");
                        // resetForm();
                        setIsLoading(false);
                        setShowModal(false);
                      })
                      .catch(() => {
                        toast.warning("Error adding student.");
                        setIsLoading(false);
                      });

                db.collection("waiting")
                .doc(formData.student).set({
                      classday:formData.classday,
                      classname: formData.classroom,
                      classtime:formData.classtime,
                      course:formData.course,
                      courselink:formData.courselink,
                      level:formData.level,
                      link:formData.link,
                      studentid: formData.student,
                      studentname: formData.studentname,
                      sublevel: formData.sublevel,
                      teacher: formData.teacher,
                      completion:formData.completion
                      })
                      .then(() => {
               
                      })
                      .catch(() => {
                    
                      });
    }
/////////    

    return (

       <Form className="add-album-form" onSubmit={onSubmit}>
          <Form.Group>
              <Form.Field className="album-inputs" >
                 <br>
                 </br>
                 <Dropdown 
                  placeholder="Classroom Name"
                  fluid
                  search
                  selection
                  options={classroom}
                  lazyLoad
                  onChange={(e, data) =>
                    setFormData({ ...formData, classroom: data.value})}
                 className="students-form"  />
                 <br>
                 </br>
                  <Dropdown 
                  placeholder="Students "
                  fluid
                  search
                  selection
                  options={user}
                  lazyLoad
                    onChange={(e, data) =>
                      setFormData({ ...formData, student: data.value })}
                className="students-form"   />
                <br>
                </br>
                <Dropdown 
                  placeholder="Verify Student"
                  fluid
                  search
                  selection
                  options={username}
                  lazyLoad
                    onChange={(e, data) =>
                      setFormData({ ...formData, studentname: data.value })}
                className="students-form"   />
                <Input placeholder="Class day"
                  onChange={e => setFormData({...formData, classday: e.target.value})}
                  className="entrada-name"
                  icon={<Icon name="calendar outline"/>}
                  />
                 <br>
                 </br>
                   <Input placeholder="Class time"
                  onChange={e => setFormData({...formData, classtime: e.target.value})}
                  className="entrada-name"
                  icon={<Icon name="clock outline"/>}
                  />
                  <br>
                 </br>
                 <Input placeholder="Session link"
                  onChange={e => setFormData({...formData, link: e.target.value})}
                  className="entrada-name"
                  icon={<Icon name="linkify"/>}
                  />
                 <br>
                 </br> 
                 <Input placeholder="Number of completion classes"
                  onChange={e => setFormData({...formData, completion: e.target.value})}
                  className="entrada-name"
                  icon={<Icon name="briefcase"/>}
                  />
                 <br>
                 </br> 
                 {/* <Input placeholder="Course link"
                  onChange={e => setFormData({...formData, courselink: e.target.value})}
                  className="entrada-name"
                  icon={<Icon name="staylinked"/>}
                  /> */}
                   <Dropdown 
                  placeholder="Course link"
                  fluid
                  search
                  selection
                  options={listcourse}
                  lazyLoad
                  onChange={(e, data) =>
                    setFormData({ ...formData, courselink: data.value})}
                 className="add-class"  
                 />
                 <br>
                 </br>             
                 <Dropdown 
                  placeholder="Course"
                  fluid
                  search
                  selection
                  options={Courses}
                  lazyLoad
                  onChange={(e, data) =>
                    setFormData({ ...formData, course: data.value})}
                 className="add-class"  />
                 <br>
                 </br>         
                 <Dropdown 
                  placeholder="Level"
                  fluid
                  search
                  selection
                  options={Level}
                  lazyLoad
                  onChange={(e, data) =>
                    setFormData({ ...formData, level: data.value})}
                 className="add-class"  />
                 <br>
                 </br>
                 <Dropdown 
                  placeholder="Sub level"
                  fluid
                  search
                  selection
                  options={Sublevel}
                  lazyLoad
                  onChange={(e, data) =>
                    setFormData({ ...formData, sublevel: data.value})}
                 className="add-class"  />
                 <br>
                 </br> 
                 <Dropdown 
                  placeholder="Teacher"
                  fluid
                  search
                  selection
                  options={teaching}
                  lazyLoad
                  onChange={(e, data) =>
                    setFormData({ ...formData, teacher: data.value})}
                 className="add-class"  />
                 <br>
                 </br>                    
                
              </Form.Field>
          </Form.Group>
          <br></br>
          <Button type="submit" isLoading={isLoading} className="add-student">Add student</Button>
       </Form>
    )
}

function initialValueForm (){
    return{
        classroom: "",
        studentid:"",
        studentname:"",
        classday:"",
        classtime:"",
        course:"",
        level:"",
        link:"",
        courselink:"",
        sublevel:"",
        teacher:"",    
        completion:"",
    }
}