import React, { useState, useEffect } from "react";
import { Grid } from "semantic-ui-react";
import { map } from "lodash";
import { Link } from "react-router-dom";
import firebase from "../../../utils/Firebase";

import "firebase/firestore";
import "firebase/storage";

import './filesresources.scss';

const db = firebase.firestore(firebase);


 function Filesresources(props) {
  const { user }=props;
  //console.log(user.uid);

    const [albums, setAlbums] = useState([]);
   // const [classroomfiles, setClassroomfiles] = useState([]);
   // const [studentclassname, setStudentclassname] = useState(null);
    const [showResources, setshowResources] = useState(false);
   // console.log(studentclassname);
    console.log(showResources);
    //console.log(classroomfiles);
 
  //
    //console.log(albums);

    //console.log(albums);
   /////student class 
 
   useEffect(() => {
    db.collection("studentclass")
    .get()
    .then(response => {
   
      map(response?.docs, classmates => {
        const data = classmates.data();
       //console.log(data.studentid);
      // console.log(data);
       if(user.uid === data.studentid){
       // setStudentclassname(data.classname);
        setshowResources(true)
       }
      });
    });
  }, [user])
   /////classroom distribution
   useEffect(() => {
    db.collection("classroom")
    .get()
    .then(response => {
   
    //  const arrayStudentclass = [];
      map(response?.docs, salon => {
        const data = salon.data();
        if(user.uid === data.teacher){
          setshowResources(true)
        }
          //console.log(data.classfiles);
          //setClassroomfiles(data.classfiles)       
      });

    });
  
  }, [ ])
   /////files albums 
     useEffect(() => {
         db.collection("filesalbums")
           .get()
           .then(response => {
             const arrayAlbums = [];
            
             map(response?.docs, album => {
               const data = album.data();
              
              console.log(data.name);
         
              // setClassroomfiles(data.classroom)
                  data.id = album.id;
                  arrayAlbums.push(data);
              
              }); 
               setAlbums(arrayAlbums);
             
           });
       }, []);
 
       //console.log("igual");
      return (
        <>
        {showResources && (
        <div className="files-albums">
        {/* <h1 className="title-co">Courses</h1> */}
        <Grid>
          {map(albums, album => (
            <Grid.Column key={album.id} mobile={16} tablet={8} computer={4}>
              <Videoalbum album={album} />
            </Grid.Column>
          ))}
        </Grid>
      </div>
      )}
      </>
    )
 }
 
 
 function Videoalbum(props) {
     const { album } = props;
     const [imageUrl, setImageUrl] = useState(null);
   
     useEffect(() => {
       firebase
         .storage()
         .ref(`filesalbum/${album.banner}`)
         .getDownloadURL()
         .then(url => {
           setImageUrl(url);
         });
     }, [album]);
   
     return (
       <Link to={`/filesdetails/${album.id}`}>
         <div className="files-albums__item">
           <div
             className="files-avatar"
             style={{ backgroundImage: `url('${imageUrl}')` }}
           />
           <h3 className="title-files">{album.name}</h3>
         </div>
       </Link>
     );
   }
export default Filesresources;