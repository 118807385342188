import React,{useState} from 'react';
import { useTranslation } from "react-i18next";
import BasicModal from "../../components/Modal/BasicModal";
////upload avatar
import UploadAvatar from "../../components/Settings/UploadAvatar";
////update user name
import UserName from "../../components/Settings/UserName";
//////update email
import UserEmail from "../../components/Settings/UserEmail";
////update password
import UserPassword from "../../components/Settings/UserPassword";

import "./Settings.scss";

export default function Settings(props) {
    const {user,setReloadApp} = props;
    //console.log(props);
///update name
    const [showModal, setShowModal] = useState(false);
    const [titleModal, setTitleModal] = useState("");
    const [contentModal, setContentModal] = useState(null);
    const { t } = useTranslation();

    return (
        <div className="settings">
           <h1>{t("Settings")}</h1> 
           <div className="avatar-name">
            <UploadAvatar user={user} setReloadApp={setReloadApp} />
            <UserName user={user}
             setShowModal={setShowModal}
             setTitleModal={setTitleModal}
             setContentModal={setContentModal}
             setReloadApp={setReloadApp}
             />
           </div>
           <UserEmail user={user}   
           setShowModal={setShowModal}
           setTitleModal={setTitleModal}
           setContentModal={setContentModal}
           />
           <UserPassword 
           setShowModal={setShowModal}
           setTitleModal={setTitleModal}
           setContentModal={setContentModal}
           />
          <BasicModal show={showModal} setShow={setShowModal} title= {titleModal}>
              {contentModal}
              </BasicModal>
        </div>
    );
}
