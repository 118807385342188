import React,{useState} from 'react';
import {Button,Container} from "semantic-ui-react";
import BasicModal from '../../Modal/BasicModal';
import AddGradeForm from '../AddGradeForm';
import './GradeTestForm.scss';

//const aswerlink="https://docs.google.com/spreadsheets/d/1bn92_gZVYyDvP4hiktuRNMSq1Ft9T0rhTt8b0VMw5Kc/edit#gid=882382997";

export default function GeadeTestForm(props) {


    const {studenanswers,user,idcomentario}=props;
    const [showgade, setShowgrade] = useState(false);
   // console.log(studenanswers.aswerlink);
 //  console.log(studenanswers.userlevel);

 const grading= ()=>{
    // console.log("cerrar sesion");
   // firebase.auth().signOut();
   setShowgrade(true);
 }

    return (
        <Container>
        <div>
            <h1 className="title-student-grade">Student Information:</h1>
            <h2 className="student-name-grade">Name: {studenanswers.username}</h2>
            <h2 className="student-email-grade">E-mail: {studenanswers.useremail}</h2>
            <h3 className="student-course-grade">Course: {studenanswers.usercourse}</h3>
            <h3 className="student-course-level">Level: {studenanswers.userlevel}</h3>
            <h3 className="student-test-grade">Test: {studenanswers.userwork}</h3>
            <div class="center"> 
            <Button href={studenanswers.answerlink} target="_blank" className="btn-grade-test">Review answers</Button>
            </div>
            <div class="center"> 
          
            <Button onClick={grading} className="btn-grade-answers">Grade Test</Button>  
                <BasicModal 
                show={showgade}
                setShow={setShowgrade}
                title={"Test grade"}
                >
                <AddGradeForm  setShowgrade={setShowgrade} user={user} studenanswers={studenanswers} idcomentario={idcomentario}/>
                </BasicModal>
            </div>
        </div>
        </Container>
    )
}
