import React, { useState } from 'react';
import {Form,Input,Button }from "semantic-ui-react";
// import {useDropzone} from "react-dropzone";
///toasts
import {toast} from "react-toastify";
import { useTranslation } from "react-i18next";
////no image
// import NoImage from "../../../assets/png/no-image.png";
// /////import uiid
// import { v4 as uuidv4 } from "uuid";
/////database
import firebase from "../../../utils/Firebase";
import "firebase/storage";
import "firebase/firestore";

import './BlogCover.scss';
//firestore
const db = firebase.firestore(firebase);

 function BlogCover(props) {
    const {setShowModal}=props;

    const [formData, setFormData] = useState(initialValueForm);
    // const [banner, setBanner] = useState(null);
    // const [file, setFile] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation();


//    const onDrop=useCallback(acceptedFile =>{
//     //  console.log(acceptedFile);
//     const file =acceptedFile[0];
//           setFile(file);
//           setBanner(URL.createObjectURL(file));
//      });
  
//      const {getRootProps,getInputProps} =useDropzone({
//            accept: "image/jpeg, image/png",
//            noKeyboard:true,
//            onDrop
//      });
  
//      const uploadImage = fileName => {
//       const ref = firebase
//         .storage()
//         .ref()
//         .child(`resources/${fileName}`);
//       return ref.put(file);
//     };
      
  
      const onSubmit= ()=>{
        // console.log("Creating Teachers"); 
        //console.log(formData);
        // setShowModal(false);
        if(!formData.title){
            toast.warning("Please provide the resources title");
        }else if (!formData.date){
          toast.warning("Please provide the resources date");
        }else if (!formData.image){
          toast.warning("Please provide the resources Url");
        }
        else{ 
          // console.log(formData);
          setShowModal(false);
          db.collection("blogcovers")
                      .add({
                        title:formData.title,
                        date:formData.date,
                        image:formData.image,
                        id:formData.id
                        })
                        .then(() => {
                          toast.success("The blog cover is uploaded.");
                          // resetForm();
                          setIsLoading(false);
                          setShowModal(false);
                          resetForm();
                        })
                        .catch(() => {
                          toast.warning("Error creating blog cover record.");
                          setIsLoading(false);
                        });
        }
      };
  
    const resetForm = () => {
      setFormData(initialValueForm());
    };


  return (
    <Form className="add-blog-form" onSubmit={onSubmit}>
    <Form.Field>
      <Input
        placeholder={t("Title")}
        onChange={(e, data) =>
          setFormData({ ...formData,  title: data.value})}
      />
    </Form.Field>
    <Form.Field>
      <Input
        placeholder={t("Numberid")}
        onChange={(e, data) =>
          setFormData({ ...formData,  id: data.value})}
      />
    </Form.Field>
    <Form.Field>
      <Input
        placeholder={t("Date")}
        onChange={(e, data) =>
          setFormData({ ...formData,  date: data.value})}
      />
    </Form.Field>
    <Form.Field>
      <Input
        placeholder={`${t("Typeurldetails")}${'..'}`}
        onChange={(e, data) =>
          setFormData({ ...formData,  image: data.value})}
      />
    </Form.Field>
    <Button type="submit" loading={isLoading}>
    {t("Addresource")}
    </Button>
  </Form>
  )
}

function initialValueForm(){

    return{ 
        title:"",
        date:"",
        image:"",
        id:""
    };    
}

export default BlogCover;