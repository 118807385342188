import React,{useState,useEffect} from 'react';
import {withRouter} from "react-router-dom";
import Listinputs from "../../components/Inputs/Listinputs";
import firebase from "../../utils/Firebase";
import "firebase/firestore";

import "./Myinputdetails.scss";


const db = firebase.firestore(firebase);

function Myinputdetails(props) {
    const {match}=props;
    //console.log(props);
   // console.log(match);
    const [studentinfo, setStudentinfo] = useState(null);
   // console.log(maestroinfo);
   // console.log(props);

   useEffect(() => {
    db.collection("studentclass")
      .doc(match?.params?.id)
      .get()
      .then(response => {
        //  console.log(response.data());
        setStudentinfo(response.data())
      });
  }, [match]);

    return (
        <div className="Course">
          {studentinfo && 
         <Listinputs studentinfo={studentinfo}/>
          }
         {/* <h2>More Information</h2> */}
        </div>
    )
}

export default withRouter(Myinputdetails);
