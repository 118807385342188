import React, { useState, useEffect } from "react";
import { Grid } from "semantic-ui-react";
import { map } from "lodash";
import { Link } from "react-router-dom";
import firebase from "../../../utils/Firebase";
import "firebase/firestore";
import "firebase/storage";

import './videoresources.scss';

const db = firebase.firestore(firebase);

function Videoresources() {

    const [albums, setAlbums] = useState([]);

   //console.log(albums);

    useEffect(() => {
        db.collection("videoalbums")
          .get()
          .then(response => {
            const arrayAlbums = [];
            map(response?.docs, album => {
              const data = album.data();
              data.id = album.id;
              arrayAlbums.push(data);
            });
            setAlbums(arrayAlbums);
          });
      }, []);


    return (
        <div className="video-albums">
        {/* <h1 className="title-co">Courses</h1> */}
        <Grid>
          {map(albums, album => (
            <Grid.Column key={album.id} mobile={16} tablet={8} computer={4}>
              <Videoalbum album={album} />
            </Grid.Column>
          ))}
        </Grid>
      </div>
    )
}


function Videoalbum(props) {
    const { album } = props;
    const [imageUrl, setImageUrl] = useState(null);
  
    useEffect(() => {
      firebase
        .storage()
        .ref(`videoalbum/${album.banner}`)
        .getDownloadURL()
        .then(url => {
          setImageUrl(url);
        });
    }, [album]);
  
    return (
      <Link to={`/videodetails/${album.id}`}>
        <div className="video-albums__item">
          <div
            className="video-avatar"
            style={{ backgroundImage: `url('${imageUrl}')` }}
          />
          <h3 className="title-video">{album.name}</h3>
        </div>
      </Link>
    );
  }

export default Videoresources;