import React,{useState,useEffect,useCallback} from 'react';
import {Form,Input,Button,Icon,Dropdown} from "semantic-ui-react";
import { useDropzone } from "react-dropzone";
// import {map} from "lodash";
import {toast} from "react-toastify";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import firebase from "../../../utils/Firebase";
import "firebase/firestore";
import "firebase/storage";

import "./AddCollaterals.scss";

const db = firebase.firestore(firebase);

const documentType = 
 [
    { key: '1', value: 'ebook',text: 'E-book' },
    { key: '2', value: 'whitepaper' ,text: 'Whitepaper'},
    { key: '1', value: 'brochure' ,text: 'Brochure'},
    { key: '4', value: 'infographic' ,text: 'Infographic'}  
  ]

 function AddCollaterals(props) {

    const {setShowModal}= props;
    const [formData, setformData] = useState(initialValueForm());
    //const [albums, setAlbums] = useState([]);
    const [file, setFile] = useState(null);
    const [isLoading, setisLoading] = useState(false);
    const [linked, setisLinked] = useState(null);
    const { t } = useTranslation();
    //console.log(albums);
    //console.log(formData);
    useEffect(() => {
    
    }, [])
    
    const onDrop = useCallback(acceptedFiles =>{
       const file=acceptedFiles[0];
       setFile(file);
     //  console.log(file);
    })
    /////dropzoner
    const {getRootProps,getInputProps}= useDropzone({
        accept:".pdf",
        noKeyboard: true,
        onDrop
    });
    ///////upload song
    const uploadRecord =(fileName)=>{
      const ref= firebase
      .storage()
      .ref()
      .child(`collaterals/${fileName}`);
    
      return ref.put(file); 
    }
    
    //////getting albums collections
    // useEffect(() => {
    //   db.collection("filesalbums")
    //     .get()
    //     .then(response =>{
    //       //  console.log(response);
    //       const albumsArray = []
    //         map(response?.docs, album =>{
    //             const data= album.data();
    //             albumsArray.push({
    //               key:album.id,
    //               value:album.id,
    //               text:data.name
    //             });
    //            // console.log(album.data());          
    //         });
    //         setAlbums(albumsArray);
    //     });
    // }, []);
    
    const onSubmit =()=>{
       // console.log("enviando.....");
      if(!formData.name){
          toast.warn("The name of the file must be placed")
      }else if(!formData.id){
        toast.warn("The id missing!")
      }
      else if(!formData.filetype){
        toast.warn("The filetype missing!")
      }
      else if(!formData.image){
        toast.warn("The image is missing!")
      }
      else if(!file){
          toast.warn("The file is missing!")
      }else{
          setisLoading(true);
          const fileName = uuidv4();
         //console.log("okk"); 
       //  console.log(fileName);
       uploadRecord(fileName).then(() => {
           //console.log("ok");
           firebase
           .storage()
           .ref(`collaterals/${fileName}`)
           .getDownloadURL()
           .then(url => {
              //  console.log(url);
              //  setisLinked(url);
              //  console.log(linked);
                db.collection("collaterals")
                .add({
                 name: formData.name,
                 id: formData.id,
                 fileName: fileName,
                 fileType: formData.filetype,
                 image: formData.image,
                 link:url
               }).then(() =>{
                   toast.success("Succesfull Uploaded collateral!");
                   resetForm();
                   setisLoading(false);
                   setShowModal(false);
                           
               }).catch(()=>{
                 toast.error("Error uploading the file try again!");
                 setisLoading(false);
               })
           });
       }).catch(()=>{
         toast.error("Error uploading the file try again!");
         setisLoading(false);
       })
      }
    }
    
    const resetForm =()=>{
      setformData(initialValueForm());
      setFile(null);
      //setAlbums([])
    }

  return (
       <Form className="add-files-from" onSubmit={onSubmit}>
             <Form.Field>
                 <Input
                 placeholder={t("Name file")}
                 onChange={e => setformData({...formData, name: e.target.value})}
                 />
             </Form.Field>
             <Form.Field>
                 <Input
                 placeholder="Id"
                 onChange={e => setformData({...formData, id: e.target.value})}
                 />
             </Form.Field>
             <Form.Field>
              <Dropdown
                placeholder={t("File Type")}
                search
                selection
                lazyLoad
                options={documentType}
                onChange={(e,data) => setformData({...formData, filetype: data.value})}
                className="add-collateral" 
              />
             </Form.Field>
             <Form.Field>
                <Input
                  placeholder={`${t("Typeurldetails")}${'..'}`}
                  onChange={(e, data) =>
                    setformData({ ...formData,  image: data.value})}
                />
             </Form.Field>
             <Form.Field>
             <div className="files-upload"{...getRootProps()}>
                 <input {...getInputProps()}/>
                 <Icon name="cloud upload" className={file && "load"}/>
                 <div>
                     <p> {t("Dropfile")} <span>&nbsp; {t("Here")}</span>.</p>
                     {file &&(
                         <p>File Upload: <span>{file.name}</span></p>
                     )}
                 </div>
                </div>
             </Form.Field>
             <Button type="submit" loading={isLoading}>
             {t("Addfile")}
             </Button>
           </Form>
  )
}

function initialValueForm (){
    return{
        name:"",
        id: "",
        filetype:"",
        image:""
    }
}    
export default  AddCollaterals;