import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    // we init with resources
    resources: {
      en: {
        translations: {
          "News": "News",
          "Resources":"Resources",
          "Home":"Home",
          "Download":"Download",
          "Email":"Email:",
          "Update":"Update",
          "Updatename":"Update name",
          "Password":"Password:",
          "Updateemail":"Update email",
          "Updatepassword":"Update password",
          "Currentpassword":"Current password",
          "Newpassword":"New password",
          "Retypepass":"Retype the new password",
          "Settings":"Settings",
          "Progress":"Progress",
          "Leveltest":"Level test",
          "Booking":"Booking",
          "Teacherguide":"Teacher guide",
          "Onboarding":"Onboarding",
          "Videos":"Videos",
          "Myclasses":"My classes",
          "Incomingtest":"Incoming test",
          "Evaluatestudents":"Evaluate students",
          "Evaluate":"Evaluate",
          "Assingstudentclass":"Assing student class",
          "Newclassroom":"New classroom",
          "Newclassmaterials": "New class materials",
          "Deletenews":"Delete news",
          "Deleteresources":"Delete resources",
          "Moredetails":"More details",
          "Downloadclick":"Click to download",
          "Course":"Course",
          "Writename":"Please write your name",
          "Writemail":"Please write your email",
          "Send":"Send",
          "Singlebooking":"Single booking",
          "Onboardingbooking":"Onboarding booking",
          "Classroombooking":"Classroom booking",
          "Booksession":"Book session",
          "Classid":"Type the Class Id",
          "Sessionduration":"Type the session duration",
          "Classname":"Select class name",
          "Startdate":"Type the start date",
          "SelectTeacher":"Select teacher email",
          "TypendDate":"Type the end date",
          "Typemeet":"Type the meet session Url",
          "Selectstudentmail":"Select student email",
          "Typetime":"Type the time zone",
          "Id":"Class Id",
          "Classroom":"Classroom",
          "Teacher":"Teacher",
          "SessionUrl":"Session URL",
          "Student":"Student",
          "Sessionduration2":"Session duration",
          "Startdate2":"Start date",
          "Enddate":"End Date",
          "Timezone":"Time zone",
          "Book":"Book",
          "Classid2":"Class ID",
          "Classname2":"Class name",
          "Studentemail":"Student email",
          "Teacheremail":"Teacher email",
          "Agregar":"Add",
          "Clase":"Class",
          "Classday":"Class day",
          "Classtime":"Class time",
          "Courselink":"Course Link",
          "Level":"Level",
          "Link":"Link",
          "Sublevel":"Sublevel",
          "Teacheruid":"Teacher UID",
          "Teachername":"Teacher name",
          "Studentuid":"Student UID",
          "Studentname":"Student name",
          "Completion":"Completion",
          "Accept":"Accept",
          "Welcome":"Welcome",
          "Codeofconduct":"Code of conduct",
          "Createaccounts":"Create accounts",
          "Studentinfo":"Student info",
          "Teacherinfo":"Teacher info",
          "Accountsonboarding":"Accounts Onboarding",
          "Userlist":"User list",
          "Chatroom":"Chat room",
          "Drop":"Please drop csv file here",
          "Drop2":"All files will be accepted",
          "Drop3":"Some files will be rejected",
          "Createusers":"Create users",
          "Phonenumber":"Phone number",
          "Displayname":"Display name",
          "Password2":"Password",
          "Email2":"Email",
          "Users":"Users",
          "Usersname":"Users name",
          "Language":"Language",
          "Spanish":"Español",
          "DownloadFile":"Download File",
          "Listofclass":"List of classrooms",
          "Pleasematch":"Please match the names to confirm the delete",
          "Resource":"Resource",
          "Identifier":"Identifier",
          "Newresource":"New resource",
          "Resourcename":"Resource name",
          "Typeurldetails":"Type the details url",
          "Addresource":"Add resource",
          "Addnews":"Add news",
          "Newsname":"News name",
          "Newclassmaterial":"New class materials",
          "Sendrecord":"Send record",
          "Sessionlink":"Join class now",
          "Cousematerials":"Course materials",
          "Open":"Open",
          "Classdeliver":"Class deliver",
          "Deliver":"Deliver",
          "Comments":"Comments",
          "Comment":"Comment",
          "Audiolist":"Audio list",
          "Videolist":"Video list",
          "Filelist":"File list",
          "Audioresources":"Audio resources",
          "Loading":"Loading",
          "Videoresources":"Video resources",
          "Fileresources":"File resources",
          "Teacherfeedback":"Teacher Feedback",
          "Courses":"Courses",
          "Courseinformation":"Course information",
          "Coursematerials":"Course materials",
          "Mycourses":"My courses",
          "Myinputs":"My inputs",
          "Blogcover":"Blog cover",
          "Title":"Title",
          "Date":"Date",
          "Imageurl":"Type the image url",
          "Numberid":"Number id",
          "Addblogcontent":"Add blog content",
          "Blogcontent":"Blog content",
          "Addblogcover":"Add blog cover",
          "AddblogCEO":"Add blog CEO",
          "blogCEO":"Blog CEO"
        }
      },
      fr: {
        translations: {
          "News": "Des nouvelles",
          "Resources":"Ressources",
          "Home":"Domicile",
          "Download":"Télécharger",
          "Email":"E-mail:",
          "Update":"Mettre à jour",
          "Updatename":"Mettre à jour le nom",
          "Password":"Le mot de passe:",
          "Updateemail":"Mettre à jour l'e-mail",
          "Updatepassword":"Mettre à jour le mot de passe",
          "Currentpassword":"Mot de passe actuel",
          "Newpassword":"Nouveau mot de passe",
          "Retypepass":"Retaper le nouveau mot de passe",
          "Settings":"Réglages",
          "Progress":"Le progrès",
          "Leveltest":"Test de niveau",
          "Booking":"Réservation",
          "Teacherguide":"Guide de l'enseignant",
          "Onboarding":"Intégration",
          "Videos":"Vidéos",
          "Myclasses":"Mes classes",
          "Incomingtest":"Essai entrant",
          "Evaluatestudents":"Evaluer les étudiants",
          "Evaluate":"Evaluer",
          "Assingstudentclass":"Assing étudiant classe",
          "Newclassroom":"Nouvelle salle de classe",
          "Newclassmaterials": "Nouveau matériel de classe",
          "Deletenews":"Supprimer des actualités",
          "Deleteresources":"Supprimer des ressources",
          "Moredetails":"Plus de détails",
          "Downloadclick":"Cliquez pour télécharger",
          "Course":"Course",
          "Writename":"S'il vous plaît écrivez votre nom",
          "Writemail":"S'il vous plaît écrivez votre e-mail",
          "Send":"Envoyer",
          "Singlebooking":"Réservation unique",
          "Onboardingbooking":"Réservation d'intégration",
          "Classroombooking":"Réservation de classe",
          "Booksession":"Séance de livre",
          "Classid":"Tapez l'identifiant de la classe",
          "Sessionduration":"Tapez la durée de la session",
          "Classname":"Sélectionnez le nom de la classe",
          "Startdate":"Tapez la date de début",
          "SelectTeacher":"L'adresse e-mail de l'enseignant",
          "TypendDate":"Tapez la date de fin",
          "Typemeet":"l'URL de la session de rencontre",
          "Selectstudentmail":"Le courrier étudiant",
          "Typetime":"Taper le fuseau horaire",
          "Id":"Identifiant de classe",
          "Classroom":"Salle de classe",
          "Teacher":"Professeur",
          "SessionUrl":"Session URL",
          "Student":"Elève",
          "Sessionduration2":"Session duration",
          "Startdate2":"Date de début",
          "Enddate":"Date de fin",
          "Timezone":"Fuseau horaire",
          "Book":"Livre",
          "Classid2":"Identifiant de classe",
          "Classname2":"Nom du cours",
          "Studentemail":"E-mail étudiant",
          "Teacheremail":"Teacher email",
          "Agregar":"Ajouter",
          "Clase":"Classer",
          "Classday":"Jour de classe",
          "Classtime":"Le moment d'aller en classe",
          "Courselink":"Lien de cours",
          "Level":"Niveau",
          "Link":"Lien",
          "Sublevel":"Sous-niveau",
          "Teacheruid":"UID de l'enseignant",
          "Teachername":"Nom de l'enseignant",
          "Studentuid":"ID étudiant",
          "Studentname":"Nom d'étudiant",
          "Completion":"Achèvement",
          "Accept":"J'accepte",
          "Welcome":"Bienvenu",
          "Codeofconduct":"Code de conduite",
          "Createaccounts":"Créer des comptes",
          "Studentinfo":"Infos étudiants",
          "Teacherinfo":"Info sur l'enseignant",
          "Accountsonboarding":"Intégration des comptes",
          "Userlist":"Liste d'utilisateur",
          "Chatroom":"Tchat",
          "Drop":"Veuillez déposer le fichier csv ici",
          "Drop2":"Tous les dossiers seront acceptés",
          "Drop3":"Certains fichiers seront rejetés",
          "Createusers":"Créer des utilisateurs",
          "Phonenumber":"Numéro de téléphone",
          "Displayname":"Afficher un nom",
          "Password2":"Le mot de passe:",
          "Email2":"E-mail",
          "Users":"Utilisateurs",
          "Usersname":"Nom d'utilisateur",
          "Language":"Langue",
          "Spanish":"Espagnol",
          "DownloadFile":"Télécharger un fichier",
          "Listofclass":"Liste des salles de classe",
          "Pleasematch":"Veuillez faire correspondre les noms pour confirmer la suppression",
          "Resource":"Ressource",
          "Identifier":"Identifiant",
          "Newresource":"Nouvelle ressource",
          "Resourcename":"Nom de la ressource",
          "Typeurldetails":"Tapez l'url des détails",
          "Addresource":"Ajouter une ressource",
          "Addnews":"Ajouter des nouvelles",
          "Newsname":"Nom de l'actualité",
          "Newclassmaterial":"Nouveaux matériaux de classe",
          "Sendrecord":"Envoyer l'enregistrement",
          "Sessionlink":"Junte-se à aula agora",
          "Cousematerials":"Matériel de cours",
          "Open":"Ouvert",
          "Classdeliver":"Classe livrer",
          "Deliver":"Livrer",
          "Comments":"Commentaires",
          "Comment":"Commenter",
          "Audiolist":"Liste audio",
          "Videolist":"Liste de vidéos",
          "Filelist":"lLste de fichiers",
          "Audioresources":"Ressources audio",
          "Loading":"Chargement en cours",
          "Videoresources":"Ressources vidéo",
          "Fileresources":"Ressources de fichiers",
          "Teacherfeedback":"Commentaires de l'enseignant",
          "Courses":"Courses",
          "Courseinformation":"Informations sur les cours",
          "Coursematerials":"Course materials",
          "Mycourses":"Mes cours",
          "Myinputs":"Mes entrées",
          "Blogcover":"Couverture du blog",
          "Title":"Titre",
          "Date":"Date",
          "Imageurl":"Tapez l'url de l'image",
          "Numberid":"numéro d'identification",
          "Addblogcontent":"Ajouter du contenu de blog",
          "Blogcontent":"Contenu du blog",
          "Addblogcover":"Ajouter une couverture de blog",
          "AddblogCEO":"Ajouter un CEO au blog",
          "blogCEO":"CEO du blog"
        }
      }
      ,
      br: {
        translations: {
          "News": "Notícias",
          "Resources":"Recursos",
          "Home":"Principal",
          "Download":"Download",
          "Email":"E-mail:",
          "Update":"Atualizar",
          "Updatename":"Atualizar nome",
          "Password":"Senha:",
          "Updateemail":"Atualizar e-mail",
          "Updatepassword":"Atualizar senha",
          "Currentpassword":"Senha atual",
          "Newpassword":"Nova Senha",
          "Retypepass":"Reescreva a nova senha",
          "Settings":"Definições",
          "Progress":"Progresso",
          "Leveltest":"Teste de nível",
          "Booking":"Reserva",
          "Teacherguide":"Guia do professor",
          "Onboarding":"Integração",
          "Videos":"Vídeos",
          "Myclasses":"Minhas aulas",
          "Incomingtest":"Teste de entrada",
          "Evaluatestudents":"Avaliar os alunos",
          "Evaluate":"Avalie",
          "Assingstudentclass":"Avaliando a classe do aluno",
          "Newclassroom":"Nova sala de aula",
          "Newclassmaterials": "Novos materiais de aula",
          "Deletenews":"Excluir notícias",
          "Deleteresources":"Excluir recursos",
          "Moredetails":"Mais detalhes",
          "Downloadclick":"Clique para fazer o download",
          "Course":"Course",
          "Writename":"Por favor escreva seu nome",
          "Writemail":"Por favor escreva seu e-mail",
          "Send":"Mandar",
          "Singlebooking":"Reserva única",
          "Onboardingbooking":"Reserva de integração",
          "Classroombooking":"Reserva de sala de aula",
          "Booksession":"Sessão de livro",
          "Classid":"Digite o ID da classe",
          "Sessionduration":"Digite a duração da sessão",
          "Classname":"Selecione o nome da turma",
          "Startdate":"Type the start date",
          "SelectTeacher":"E-mail do professor",
          "TypendDate":"Digite a data de término",
          "Typemeet":"Digite o URL da sessão de reunião",
          "Selectstudentmail":"E-mail do aluno",
          "Typetime":"Digite o fuso horário",
          "Id":"ID da turma",
          "Classroom":"Sala de aula",
          "Teacher":"Professor",
          "SessionUrl":"URL da sessão",
          "Student":"Aluno",
          "Sessionduration2":"Session duration",
          "Startdate2":"Data de início",
          "Enddate":"Data final",
          "Timezone":"Fuso horário",
          "Book":"Livro",
          "Classid2":"ID da turma",
          "Classname2":"Nome da classe",
          "Studentemail":"E-mail do aluno",
          "Teacheremail":"Teacher email",
          "Agregar":"Adicionar",
          "Clase":"Classe",
          "Classday":"Dia de aula",
          "Classtime":"Horário de aula",
          "Courselink":"Link do curso",
          "Level":"Nível",
          "Link":"Link",
          "Sublevel":"Subnível",
          "Teacheruid":"UID do professor",
          "Teachername":"Nome do professor",
          "Studentuid":"UID do aluno",
          "Studentname":"Nome do aluno",
          "Completion":"Conclusão",
          "Accept":"Aceitar",
          "Welcome":"Bem vinda",
          "Codeofconduct":"Código de Conduta",
          "Createaccounts":"Criar contas",
          "Studentinfo":"Informações do aluno",
          "Teacherinfo":"Info do professor",
          "Accountsonboarding":"Integração de contas",
          "Userlist":"Lista de usuários",
          "Chatroom":"Sala de bate-papo",
          "Drop":"Por favor, solte o arquivo csv aqui",
          "Drop2":"Todos os arquivos serão aceitos",
          "Drop3":"Alguns arquivos serão rejeitados",
          "Createusers":"Criar usuários",
          "Phonenumber":"Número de telefone",
          "Displayname":"Nome em Exibição",
          "Password2":"Senha:",
          "Email2":"E-mail",
          "Users":"Comercial",
          "Usersname":"Nome de usuário",
          "Language":"Língua",
          "Spanish":"Espanhol",
          "DownloadFile":"Fazer download do arquivo",
          "Listofclass":"Lista de salas de aula",
          "Pleasematch":"Combine os nomes para confirmar a exclusão",
          "Resource":"Recurso",
          "Identifier":"Identificador",
          "Newresource":"Novo recurso",
          "Resourcename":"Nome do recurso",
          "Typeurldetails":"Digite o URL de detalhes",
          "Addresource":"Adicionar recurso",
          "Addnews":"Adicionar notícias",
          "Newsname":"Nome da notícia",
          "Newclassmaterial":"Novos materiais de aula",
          "Sendrecord":"Enviar registro",
          "Sessionlink":"Junte-se à aula agora",
          "Cousematerials":"Materiais do curso",
          "Open":"Abrir",
          "Classdeliver":"Entrega de classe",
          "Deliver":"Entregar",
          "Comments":"Comentários",
          "Comment":"Comente",
          "Audiolist":"Lista de áudio",
          "Videolist":"Lista de vídeos",
          "Filelist":"Lista de arquivos",
          "Audioresources":"Recursos de áudio",
          "Loading":"Carregando",
          "Videoresources":"Recursos de vídeo",
          "Fileresources":"Recursos de arquivo",
          "Teacherfeedback":"Comentários do professor",
          "Courses":"Courses",
          "Courseinformation":"Informação do curso",
          "Coursematerials":"Course materials",
          "Mycourses":"Meus cursos",
          "Myinputs":"Minhas entradas",
          "Blogcover":"Capa do blog",
          "Title":"Título",
          "Date":"Encontro",
          "Imageurl":"Digite o URL da imagem",
          "Numberid":"número de identificação",
          "Addblogcontent":"Adicionar conteúdo do blog",
          "Blogcontent":"Conteúdo do blog",
          "Addblogcover":"Adicionar capa do blog",
          "AddblogCEO":"Adicionar CEO do blog",
          "blogCEO":"CEO do blog"
        }
      },
      es: {
        translations: {
          "News": "Noticias",
          "Resources":"Recursos",
          "Home":"Principal",
          "Download":"Descargar",
          "Email":"Correo:",
          "Update":"Actualizar",
          "Updatename":"Actualizar nombre",
          "Password":"Contraseña:",
          "Updateemail":"Actualizar correo",
          "Updatepassword":"Actualizar contraseña",
          "Currentpassword":"Contraseña actual",
          "Newpassword":"Nueva contraseña",
          "Retypepass":"Reescribe la nueva contraseña",
          "Settings":"Ajustes",
          "Progress":"Progreso",
          "Leveltest":"Prueba de nivel",
          "Booking":"Reservación",
          "Teacherguide":"Guia de maestro",
          "Onboarding":"Inducción",
          "Videos":"Vídeos",
          "Myclasses":"Mis clases",
          "Incomingtest":"Prueba entrante",
          "Evaluatestudents":"Evaluar estudiantes",
          "Evaluate":"Evaluar",
          "Assingstudentclass":"Asignar clase de estudiante",
          "Newclassroom":"Nuevo salón",
          "Newclassmaterials": "Nuevos materiales de clase",
          "Deletenews":"Borrar noticias",
          "Deleteresources":"Borrar recursos",
          "Moredetails":"Mas detalles",
          "Downloadclick":"Click de descarga",
          "Course":"Curso",
          "Writename":"Por favor escribe tu nombre",
          "Writemail":"Por favor escribe tu correo",
          "Send":"Enviar",
          "Singlebooking":"Reserva sencilla",
          "Onboardingbooking":"Reserva de incorporación",
          "Classroombooking":"Reserva de aula",
          "Booksession":"Agendar sesión",
          "Classid":"Ingresar identificación de clase",
          "Sessionduration":"Escriba la duración de la sesión",
          "Classname":"Selecciona el nombre de la clase",
          "Startdate":"Escriba la fecha de inicio",
          "SelectTeacher":"Correo electrónico del maestro",
          "TypendDate":"Escriba la fecha de finalización",
          "Typemeet":"URL de la sesión",
          "Selectstudentmail":"Correo del estudiante",
          "Typetime":"Zona horaria",
          "Id":"Id de clase",
          "Classroom":"Salón de clases",
          "Teacher":"Profesor",
          "SessionUrl":"URL de la sesión",
          "Student":"Estudiante",
          "Sessionduration2":"Duración de sesión",
          "Startdate2":"Fecha de inicio",
          "Enddate":"Fecha de fin",
          "Timezone":"Zona horaria",
          "Book":"Agendar",
          "Classid2":"ID de clase",
          "Classname2":"Nombre de clase",
          "Studentemail":"Email del estudiante",
          "Teacheremail":"Email del maestro",
          "Agregar":"Agregar",
          "Clase":"Clase",
          "Classday":"Día de clase",
          "Classtime":"Horario de clase",
          "Courselink":"Enlace de curso",
          "Level":"Nivel",
          "Link":"Enlace",
          "Sublevel":"Sub nivel",
          "Teacheruid":"UID maestro",
          "Teachername":"Nombre del maestro",
          "Studentuid":"UID estudiante",
          "Studentname":"Nombre del estudiante",
          "Completion":"Terminación",
          "Accept":"Aceptar",
          "Welcome":"Bienvenido",
          "Codeofconduct":"Codigo de conducta",
          "Createaccounts":"Crear cuentas",
          "Studentinfo":"Info de estudiante",
          "Teacherinfo":"Info de maestro",
          "Accountsonboarding":"Registro de cuentas",
          "Userlist":"Lista de usuarios",
          "Chatroom":"Sala de chat",
          "Drop":"Suelte el archivo csv aquí",
          "Drop2":"Todos los archivos serán aceptados.",
          "Drop3":"Algunos archivos serán rechazados",
          "Createusers":"Crear usuarios",
          "Phonenumber":"Número de teléfono",
          "Displayname":"Nombre de perfil",
          "Password2":"Contraseña",
          "Email2":"Correo",
          "Users":"Usuarios",
          "Usersname":"Nombre de usuario",
          "Language":"Idioma",
          "Spanish":"Español",
          "DownloadFile":"Descargar archivo",
          "Listofclass":"Lista de aulas",
          "Pleasematch":"Por favor, haga coincidir los nombres para eliminar.",
          "Resource":"Recurso",
          "Identifier":"Identificador",
          "Newresource":"Nuevo recurso",
          "Resourcename":"Nombre del recurso",
          "Typeurldetails":"Escriba la URL de detalles",
          "Addresource":"Agregar recurso",
          "Addnews":"Añadir noticia",
          "Newsname":"Nombre de la noticia",
          "Newclassmaterial":"Nuevos materiales de clase",
          "Sendrecord":"Enviar registro",
          "Sessionlink":"Unete a la clase ahora",
          "Cousematerials":"Materiales del curso",
          "Open":"Abrir",
          "Classdeliver":"Entrega de clase",
          "Deliver":"Entregar",
          "Comments":"Comentarios",
          "Comment":"Comentario",
          "Audiolist":"Lista de audio",
          "Videolist":"Lista de videos",
          "Filelist":"Lista de archivos",
          "Audioresources":"Recursos de audio",
          "Loading":"Cargando",
          "Videoresources":"Recursos de video",
          "Fileresources":"Recursos de archivos",
          "Teacherfeedback":"Teacher Feedback",
          "Courses":"Courses",
          "Courseinformation":"Información de curso",
          "Coursematerials":"Materiales de clase",
          "Mycourses":"Mis cursos",
          "Myinputs":"Mi retroalimentación",
          "Blogcover":"Portada del blog",
          "Title":"Titulo",
          "Date":"Fecha",
          "Imageurl":"Escriba la URL de la imagen",
          "Numberid":"Número de identificación",
          "Addblogcontent":"Añadir contenido de blog",
          "Blogcontent":"Contenido de blog",
          "Addblogcover":"Agregar portada del blog",
          "AddblogCEO":"Añadir CEO de blog",
          "blogCEO":"CEO de blog"
        }
      }
    },
    fallbackLng: "en",
    debug: true,

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations",

    keySeparator: false, // we use content as keys

    interpolation: {
      escapeValue: false
    }
  });

export default i18n;