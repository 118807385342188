import React,{useState} from 'react';
import { Form,Button } from 'semantic-ui-react'
import { toast } from "react-toastify";
import './SuppertForm.scss';



 function SupportForm(props) {
  const {user,setShowModal} =props;
  const [formData, setFormData] = useState(initialValueForm());

  // console.log(user);
  
  const HOST = process.env.HOST || "https://restserver-323001.uw.r.appspot.com"

  const onSubmit = async () =>{
  //  console.log(formData);
            
   const payload ={
   Support:formData.Support,
   Name:user.email,
   Email:user.displayName
   };
 
   try {
     const response = await fetch(HOST + "/api/webpage/support", {
       headers: {
         'Content-Type': 'application/json',
         'Accept':       'application/json',
       },
       method: "POST",
       body:  JSON.stringify(payload)
     })
   
     
     if(response.status === 404) 
       throw {message: "Bad request"}
       
       
     if(response.status !== 200) 
       throw new Error()
 
  
     toast.success("Your request have been sended");
     setShowModal(false);
   }
   catch(e) {
     toast.warning("ERROR: something went wrong...");
   }
 
 }

  return (
    <>
      <h3>How can we help ?</h3>
      <br/>
      <Form className='comments-support' onSubmit={onSubmit}>
          <Form.TextArea placeholder=' Please give us your comments and we get back to you as soon as possible' className='support-info'
           onChange={e => setFormData({...formData, Support: e.target.value})}
          />
          <Button type="submit" className='support-ticket'>Submit</Button>
      </Form>
    </>
  )
}

function initialValueForm (){
  return{
    Support:""
  }
}

export default SupportForm;