import React from 'react'
import {Button} from 'semantic-ui-react';
import {Icon} from "semantic-ui-react";
import {Grid} from 'react-bootstrap';
import { useTranslation } from "react-i18next";
// import {Link} from "react-router-dom";

import './Download.scss';

function Download() {
    const { t } = useTranslation();
    return (
        <div className="App">
        <header className="App-header">
        <div className="container">
             <Grid className="purchase-card">

       <Icon name="windows" size='huge' className="windows-download" />   
<Button href='https://firebasestorage.googleapis.com/v0/b/webpagecomligo.appspot.com/o/app%2Fcomligo%20Setup%201.0.1.exe?alt=media&token=98452961-0ab0-46c4-bad9-4f38e76dd0b1' download className="btn-download">{t("Downloadclick")}</Button>

<Icon name="apple" size='huge' className="mac-download"/> 
<Button href='https://firebasestorage.googleapis.com/v0/b/webpagecomligo.appspot.com/o/app%2Fcomligo-1.0.1.dmg?alt=media&token=774ff567-79ab-4dcb-9f43-71fa8d8fb7f8' download className="btnmac-download">{t("Downloadclick")}</Button>
             </Grid>
        </div>
    </header>
    </div>
    )
}

export default Download;