import React,{useState,useEffect} from 'react';
import { Button,Container,Table,Menu,Label,Icon} from "semantic-ui-react";
import { map } from "lodash";
import { useTranslation } from "react-i18next";
import BasicModal from '../../../components/Modal/BasicModal';
import AddCommentForm2 from '../../../components/Rating/AddCommentForm2';
import firebase from "../../../utils/Firebase";
import "firebase/firestore";
import './BannerMycourse.scss';

const db = firebase.firestore(firebase);

export default function BannerMycourse(props) {

    const {studentinfo}= props;
    const [showcomment, setShowcomment] = useState(false);
    const [classcomment, setClasscomment] = useState(null);
    const [teacherid, setTeacherid] = useState(null);
    const { t } = useTranslation();
   // console.log(classcomment);
   // console.log(studentinfo);
   // console.log(teacherid);
////////bring id of classroom
    useEffect(() => {

        db.collection("classroom")
        .get()
        .then(response => {
            
          map(response?.docs, listaid => {
            const data = listaid.data();
            data.id = listaid.id;
            // console.log(data);
            if(data.classname === studentinfo.classname){
               // console.log( data.id);
                setClasscomment(data.id)
                setTeacherid(data.teacher)
            }
           // console.log(data.teacher);
           
          });
      });
      
      
      }, [studentinfo])
///////////
    const comment= ()=>{
        // console.log("cerrar sesion");
       // firebase.auth().signOut();
       setShowcomment(true);
     }


    return (
        <>
        <Container textAlign='center'>
        <div>
        <h2 className="titulo-clase-myc">{t("Courseinformation")}:</h2> 
            {/* <h3 className="titulo-clases-myc">{studentinfo.classname}</h3>
                 <Button target="_blank" href={studentinfo.link} className="btn-session-myc">{t("Sessionlink")}</Button>
                    <span className="informacion-clase-myc">
                        <h3 className="info-clase-myc">Week day:{studentinfo.classday} </h3>
                        <h3 className="info-clase-dos-myc">Time: {studentinfo.classtime}</h3>
                        <h3 className="detalle-curso-myc">Course: {studentinfo.course}</h3>
                        <h3 className="nivel-curso-myc">Level: {studentinfo.level}</h3>
                        <h3 className="sub-nivel-myc">Sub level: {studentinfo.sublevel}</h3>
                        <h3 className="info-maestro-myc">Teacher: {studentinfo.teachername}</h3>
                    </span>
            <h2 className="titulo-materiales-myc">{t("Coursematerials")}:</h2>
            <Button target="_blank" href={studentinfo.courselink} className="btn-materiales-myc">{t("Coursematerials")}</Button>
      
            <h2 className="titulo-comentarios-myc">{t("Comments")}</h2>
            <Button
            onClick={comment}
            className="adding-comments-classroom-myc"
           >
          {t("Comment")}
           </Button>   */}
        <Table inverted className='list-course-information'>
            <Table.Body>
            <Table.Row>
                <Table.Cell><Icon name='bookmark' size='big' style={{color: "#E05025"}}/>{studentinfo.course}{" "}{studentinfo.level}{"."}{studentinfo.sublevel}{" "}{studentinfo.classname}</Table.Cell>
                <Table.Cell> <Button target="_blank" href={studentinfo.link} className="btn-session-myc"><Icon name='sign-in' size='small'/>{t("Sessionlink")}</Button></Table.Cell>
            </Table.Row>
            <Table.Row>
                <Table.Cell><Icon name='calendar alternate outline' size='big' style={{color: "#E05025"}}/>{studentinfo.classday}{" "}{studentinfo.classtime}</Table.Cell>
                <Table.Cell><Button target="_blank" href={studentinfo.courselink} className="btn-materiales-myc"><Icon name='folder' size='small'/>{t("Coursematerials")}</Button></Table.Cell>
              
            </Table.Row>
            <Table.Row>
                <Table.Cell><Icon name='user' size='big' style={{color: "#E05025"}}/>{studentinfo.teachername}</Table.Cell>
                <Table.Cell><Button onClick={comment} className="adding-comments-classroom-myc"><Icon name='comments' size='small'/>{t("Comment")}</Button></Table.Cell>
            </Table.Row>
            </Table.Body>
        </Table>
        </div>
        </Container>
        <BasicModal 
        show={showcomment}
        setShow={setShowcomment}
        title={"Comment"}
        >
        <AddCommentForm2 setShowcomment={setShowcomment} studentinfo={studentinfo} classcomment={classcomment} teacherid={teacherid}/>
        </BasicModal> 
        </>
    )
}
