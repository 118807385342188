import React,{useState,useEffect} from 'react';
import {withRouter} from "react-router-dom";
import BannerCourse from "../../components/Courseinfo/BannerCourse";
import firebase from "../../utils/Firebase";
import "firebase/firestore";

import "./Courseinfo.scss";

const db = firebase.firestore(firebase);

function Courseinfo(props) {
    const {match,user,setReloadApp}=props;
    //console.log(props);
    const [maestroinfo, setMaestroinfo] = useState(null);
   // console.log(maestroinfo);
   // console.log(props);
   //console.log(match.params.id);

//////////match id params
   useEffect(() => {
    db.collection("classroom")
      .doc(match?.params?.id)
      .get()
      .then(response => {
         // console.log(response.data());
          setMaestroinfo(response.data())
      });
  }, [match]);
/////////////  

    return (
        <div className="Course">
          {maestroinfo && 
         <BannerCourse maestroinfo={maestroinfo} user={user} setReloadApp={setReloadApp}/>
          }
         {/* <h2>More Information</h2> */}
        </div>
    )
}

export default withRouter(Courseinfo);