import React from 'react';
import {Switch,Route} from "react-router-dom";
////pages
import Home from "../pages/Home";
import Promotions from '../pages/Promotions/promotions';
import Resources from '../pages/Resources/resources';
//////account
import Settings from "../pages/Settings";
///app download
import Download from "../pages/Download";
/////grades
import Evaluations from "../pages/Evaluations";
/////subscription
import Subscription from "../pages/Subscription";
/////booking sessions
import Booking from "../pages/Booking";
///level test user
import LevelTest from "../pages/LevelTest";
////teachers guide
import Guide from "../pages/Guide";
//////teacher classes
import Myclasses from "../pages/Myclasses";
////////////evaluatetest
import Evaluatetest from "../pages/EvaluateTest";
///////////evaluate students
import EvaluateStudents from "../pages/EvaluateStudents";
///////////grade tests
import GradeTest from "../pages/GradeTest";
////////Trial
import Trial from "../pages/Trial";
///////Videos
import Videos from "../pages/Videos";
//////Course Info
import Courseinfo from "../pages/Courseinfo";
/////student info
import Studentinfo from "../pages/Studentinfo";
/////student details
import Studentdetails from "../pages/Studentdetails";
///////
import Onboarding from '../pages/onboarding/onboarding';
////// 
import Mediaresources from '../pages/mediaresources/mediaresources';
//import EvaluateTest from '../pages/EvaluateTest';
////components
import Audiodetails from '../pages/mediadetails/audiodetails';
//
import Videodetails from '../pages/mediadetails/videodetails';
////
import Filesdetails from '../pages/mediadetails/filesdetails/filesdetails';
//////
import Mycourses from '../pages/Mycourses/Mycourses';
////
import Mycourseinfo from '../pages/Mycourseinfo/Mycourseinfo';
////
import Myinputs from '../pages/Myinputs';
////
import Myinputdetails from '../pages/Myinputdetails/Myinputdetails';

export default function Routes(props) {
    const { user,setReloadApp }=props;
   // console.log(setReloadApp);
    return ( 
     <Switch>
         <Route path="/" exact>
             <Home user={user}/>
         </Route>
         <Route path="/resources/:id" exact>
            <Resources/>
         </Route>
         <Route path="/promotions/:id" exact>
            <Promotions/>
         </Route>
         <Route path="/subscriptions" >
          <Subscription user={user} />
         </Route>
         <Route path="/book" >
         <Booking user={user}/>
         </Route>
         <Route path="/settings" exact>
            <Settings user={user} setReloadApp={setReloadApp}/>
         </Route>
         <Route path="/download" exact>
            <Download/>
         </Route>
         <Route path="/evaluation" exact>
           <Evaluations user={user}/>
         </Route>
         <Route path="/guide" exact>
           <Guide user={user}/>
         </Route>
         <Route path="/test" exact>
           <LevelTest user={user}/>
         </Route>
         <Route path="/class" exact>
           <Myclasses user={user}/>
         </Route>
         <Route path="/evaluatetest" exact>
           <Evaluatetest user={user}/>
         </Route>
         <Route path="/evaluatestudents" exact>
           <EvaluateStudents user={user}/>
         </Route>
         <Route path="/gradetests/:id" exact>
           <GradeTest user={user}/>
         </Route>
         <Route path="/trial" exact>
           <Trial user={user}/>
         </Route>
         <Route path="/videos" exact>
           <Videos user={user}/>
         </Route>
         <Route path="/courseinfo/:id" exact>
           <Courseinfo user={user} setReloadApp={setReloadApp}/>
         </Route>
         <Route path="/studentinfo/:id" exact>
           <Studentinfo user={user}/>
         </Route>
         <Route path="/studentdetails/:studentid" exact>
           <Studentdetails user={user}/>
         </Route>
         <Route path="/onboarding" exact>
           <Onboarding user={user}/>
         </Route>
         <Route path="/mediaresources" exact>
           <Mediaresources user={user}/>
         </Route>
         <Route path="/audiodetails/:id" exact>
            <Audiodetails  user={user}/>
         </Route>
         <Route path="/videodetails/:id" exact>
            <Videodetails user={user}/>
         </Route>
         <Route path="/filesdetails/:id" exact>
            <Filesdetails user={user}/>
         </Route>
         <Route path="/mycourses" exact>
            <Mycourses user={user}/>
         </Route>
         <Route path="/Mycourseinfo/:id" exact>
           <Mycourseinfo user={user}/>
         </Route>
         <Route path="/myinputs" exact>
             <Myinputs user={user}/>
         </Route>
         <Route path="/myinputsdetails/:id" exact>
             <Myinputdetails user={user}/>
         </Route>
     </Switch>
     
    )
}


