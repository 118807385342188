import React,{useState,useEffect} from 'react';
import { Button,Form,Input,Icon,Dropdown,Tab  } from 'semantic-ui-react';
import { toast } from "react-toastify";
import { map } from "lodash";
import {Grid} from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import firebase from "../../../utils/Firebase";
import "firebase/firestore";
import './singlebooking.scss';

const db = firebase.firestore(firebase);



 function Singlebooking () {

// ///react hooks
const [formData, setFormData] = useState(defaultValueForm());
const [isLoading, setIsLoading] = useState(false);  
const [teacherbooking, setTeacherbooking] = useState(null);
const [estudiantebook, setEstudiantebook] = useState(null);
const [bookclassroom, setBookclassroom] = useState(null);
const { t } = useTranslation();

// //console.log(teacherbooking);
// //console.log(estudiantebook);
// //console.log(bookclassroom);
// /////open external window
//     // var strWindowFeatures = "location=yes,height=1000,width=1500,scrollbars=yes,status=yes";
//     // var URL = "https://comligoagenda.firebaseapp.com/";  
    
    // const doCourse = ()=>{
    //     //console.log("click");
    //     window.open(URL, "_blank", strWindowFeatures);
    //     }
/////////teacher list
        useEffect(() => {
          db.collection("teacherlist")
          .get()
          .then(response => {
            //  console.log(response);
              
            const arrayTeachersbooking = [];
            map(response?.docs, teacherbooking => {
              const data = teacherbooking.data();
            //  console.log(data.email);
            //  console.log(teacherlist.id);
            arrayTeachersbooking.push({
                key: teacherbooking.id,
                value: data.email,
                text: data.email
              });
            });
          //  console.log(arrayTeachers);
          setTeacherbooking(arrayTeachersbooking);
          });
  
      }, [])       
/////////student list
useEffect(() => {
  db.collection("users")
  .get()
  .then(response => {
   //  console.log(response);
      
    const arrayEstudiante = [];
    map(response?.docs, estudiante => {
      const data = estudiante.data();
   //  console.log(data);
   arrayEstudiante.push({
        key: estudiante.id,
        value: data.email,
        text: data.email,
      //   uid: data.uid
      });
    });
  // console.log(arrayUsers);
  setEstudiantebook(arrayEstudiante );
  });

}, [])

/////////classroom list
useEffect(() => {
  db.collection("classroom")
  .get()
  .then(response => {
     // console.log(response);
      
    const arrayClassroom = [];
    map(response?.docs, classrooms => {
      const data = classrooms.data();
     // console.log(data);
    // console.log(classrooms.id);
      arrayClassroom.push({
        key: classrooms.id,
       // value: classrooms.id,
        value: data.class,
        text: data.class
      });
    });
  //  console.log(arrayTeachers);
  setBookclassroom(arrayClassroom);
  });

}, [])
///////////
        const onChange =e=>{
           e.preventDefault();
            /*
             console.log("Name:"+ e.target.name);
             console.log("Value"+e.target.value);*/
             setFormData({
             ...formData,[e.target.name]: e.target.value
        
             });
            // e.target.reset();
          };
/////////submit form
          const onSubmit =(e) =>{
            //  console.log(formData);
              db.collection("bookings")
              .add({
                claseId: formData.claseId,
                claseName: formData.claseName,
                teacher: formData.teacher,
                link:formData.link,
                student:formData.student,
                hr: formData.hr,
                startDate: formData.startDate,
                endDate: formData.endDate,
                timeZone: formData.timeZone
                })
                .then(() => {
                  toast.success("The booking is already added.");
                  // resetForm();
                  setIsLoading(false);
                  e.target.reset();
                  //setShowModal(false);
                })
                .catch(() => {
                  toast.warning("Error adding the booking.");
                  setIsLoading(false);
                });
          }
          
    
        return (
            <>
            <header className="booking-header">
              <h2 className="titulo-book">{t("Booksession")}</h2>          
           <div className="booking-form">
              <Grid className="booking-card">
              <Form  onSubmit={onSubmit}  onChange={onChange}> 
              <div class="col">
              <Form.Field>
              <h3>{t("Classid")}</h3>
                   <Input
                   placeholder={t("Id")}
                   type="text"
                   name="claseId"                
                   icon={<Icon name="caret square down outline"/>}
                   />
               </Form.Field>
               <h3>{t("Classname")}</h3>
               <Dropdown 
                  placeholder={t("Classroom")}
                  fluid
                  search
                  selection
                  options={bookclassroom}
                  lazyLoad
                  onChange={(e, data) =>
                  setFormData({ ...formData,  claseName: data.value})}
                 className="book-classroom"  />
               <h3>{t("SelectTeacher")}</h3>
               <Dropdown 
                  placeholder={t("Teacher")}
                  fluid
                  search
                  selection
                  options={teacherbooking}
                  lazyLoad
                  onChange={(e, data) =>
                  setFormData({ ...formData, teacher: data.value})}
                 className="book-maestro"  />
                 <br></br>
               <Form.Field>
              <h3>{t("Typemeet")}</h3>
                   <Input
                   placeholder={t("SessionUrl")}
                   type="text"
                   name="link"
                   icon={<Icon name="linkify"/>}
                 
                   />
               </Form.Field>
               <h3>{t("Selectstudentmail")}</h3>
               <Dropdown 
                  placeholder={t("Student")}
                  fluid
                  search
                  selection
                  options={estudiantebook}
                  lazyLoad
                  onChange={(e, data) =>
                  setFormData({ ...formData, student: data.value})}
                 className="book-student"  />
                 <br></br>
               </div>
               <div class="col">
               <Form.Field>
              <h3>{t("Sessionduration")}</h3>
                   <Input
                   placeholder={t("Sessionduration2")}
                   type="text"
                   name="hr"                
                   icon={<Icon name="caret square down outline"/>}
                   />
               </Form.Field>
               <br></br>
               <Form.Field>
              <h3 className="inicio-fecha">{t("Startdate")}</h3>
                   <Input
                   placeholder={`${t("Startdate2")}${' '}${'yyyy/mm/dd'}`}
                   type="text"
                   icon={<Icon name="calendar outline"/>}
                   name="startDate"
                   className="fecha-inicio"
                   />
               </Form.Field>
               <br></br>
               <Form.Field>
              <h3 className="fin-fecha">{t("TypendDate")}</h3>
                   <Input
                   placeholder={`${t("Enddate")}${' '}${'yyyy/mm/dd'}`}
                   type="text"
                    name="endDate"
                    className="fecha-fin"
                   icon={<Icon name="calendar outline"/>}
                   />            
               </Form.Field>
               <Form.Field>
              <h3 className="fecha-zona">{t("Typetime")}</h3>
                   <Input
                   placeholder={t("Timezone")}
                   type="text"
                    name="timeZone"
                    className="fecha-zona"
                   icon={<Icon name="clock outline"/>}
                   />            
               </Form.Field>
             
               </div>
               <Button className="calendar" type="submit" loading={isLoading}>{t("Book")}</Button> 
               </Form>    
               </Grid>
      
               </div> 
            
            </header>
          </>
        )
}


function defaultValueForm() {
    return {
        claseId: "",
        claseName: "",
        teacher: "",
        link:"",
        student:"",
        hr: "",
        startDate: "",
        endDate: "",
        timeZone: ""
    };
  }
  
  export default Singlebooking;