import React,{useState,useEffect} from 'react';
import {Table,Icon,Container} from "semantic-ui-react";
import { map } from "lodash";
import {Link} from "react-router-dom";
import firebase from "../../utils/Firebase";
import 'firebase/firestore';

import './EvaluateStudents.scss';


const db = firebase.firestore(firebase);

function EvaluateStudents(props) {

    const {user}=props;
    const [studentclases, setStudentclases] = useState(null);
   console.log(studentclases);
  //console.log(studentclases);

///////class list
useEffect(() => {
    // const {user}=props;

    db.collection("classroom")
    .get()
    .then(response => {
        
    const arrayStudentClases = [];
    // const arrayEvaluations = [];
      map(response?.docs, students => {
        const data = students.data();
        data.id=students.id;
       // console.log(data.teacher);
        if(user.uid === data.teacher){
          //  setClase(data.class);
       //console.log(data);
       arrayStudentClases.push(data);
        }else{
            // console.log("fail");
        }
      });
     // console.log(list);
    //console.log(arrayClases);
    setStudentclases( arrayStudentClases);
});
}, [])
//////


    return (
        <Container>
        <Table inverted className="list-classes-evaluate">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell />
            <Table.HeaderCell className="title-evaluate">My Students:</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
  
        <Table.Body>
       {map(studentclases, studentclases => (
          <Records
            key={studentclases.id}
            studentclases={studentclases}            
          />
        ))}
        </Table.Body>
      </Table>
        </Container>
    
    )
}

function Records(props){
    const{studentclases}=props;
    //console.log(clase.id);
    //console.log(key);
    
        return (
          <>
          <Table.Row >
          <Link to={`/studentinfo/${studentclases.id}`}  style={{ color: 'inherit', textDecoration: 'inherit'}} >  
            <Table.Cell collapsing>
              <Icon name="book" />
            </Table.Cell>
            <Table.Cell >{studentclases.classname}</Table.Cell>     
            </Link>
          </Table.Row>
          </>
        );
      }


export default EvaluateStudents;