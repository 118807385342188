import React ,{useEffect,useState}from 'react';
import { Button} from "semantic-ui-react";
import { useTranslation } from "react-i18next";
import firebase  from '../../../utils/Firebase';
import "firebase/storage";
import './Bannerpromotions.scss';

export default function Bannerpromotions(props) {
    const {promotions} = props;
    const [bannerurlone, setBannerurlone] = useState(null);
    const { t } = useTranslation();
    // console.log(promotions);

    useEffect(() => {
        firebase
        .storage()
        .ref(`promotions/${promotions?.banner}`)
        .getDownloadURL()
        .then (url =>{
            setBannerurlone(url);
        })
        
    }, [promotions]);

    const openWindow = ()=>{
        window.open(promotions.url);
    }

    return (
        <div className="banner-promotions"
        style={{backgroundImage: `url('${bannerurlone}')`}}
        >
            {/* <h1>Promotions</h1> */}
            <Button  className="Promotions-details" onClick={openWindow}>
            {t("Moredetails")}
            </Button>
        </div>
    )
}
