import React,{ useState, useEffect } from 'react';
import { Embed,Form,Dropdown } from 'semantic-ui-react';
// import BasicModal from '../../components/Modal/BasicModal';
import { map } from 'lodash';

import firebase from "../../utils/Firebase";
import "firebase/storage";

import "./Videos.scss";

const videose = [
  { key: 'af', value: 'ZincdK3v8wU', text: 'Learn spanish' },
  { key: 'ax', value: '0Odnbvov9N0', text: 'Spanish beginner' },
  { key: 'al', value: 'fokoXua7OEs', text: 'Learn spanish 2' },
  { key: 'dz', value: 'lJw40ENfr2w',  text: 'Basic vocabulary' },
  { key: 'as', value: '3qKEHip_TEc',  text: 'Words every beginner must know' }
]

const db = firebase.firestore(firebase);

export default function Videos() {
  const [bannerUrl, setBannerUrl] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [titleModal, setTitleModal] = useState(null);
  const [contentModal, setContentModal] = useState(null);
  const [formData, setformData] = useState(initialValueForm());
  const [videos, setVideos] = useState(null);
/////////////////
// console.log(formData.id);
////list of videos
useEffect(() => {
  db.collection("videos")
  .get()
  .then(response => {
   //  console.log(response);
      
    const arrayVideos = [];
    map(response?.docs, videos => {
      const data = videos.data();
   //  console.log(data);
     arrayVideos.push({
        key: videos.id,
        value: data.id,
        text: data.name,
      //   uid: data.uid
      });
    });
  // console.log(arrayVideos);
  setVideos(arrayVideos);
  });

}, [])


useEffect(() => {
  firebase
    .storage()
    .ref("other/banner-video.jpg")
    .getDownloadURL()
    .then(url => {
      setBannerUrl(url);
    })
    .catch(() => {});
}, []);

if (!bannerUrl) {
  return null;
}
/////////////////   
/////////
const onSubmit =()=>{
console.log("enviado");
// console.log(formData.id);

}
///////////
  return (
    <>   
    <div
   className="banner-video"
   style={{ backgroundImage: `url('${bannerUrl}')` }}
    />
    <br></br>
     <div className="video" >
         <h1 className="titulo-videos">Video player</h1>
         <br></br>
         <Embed
 aspectRatio='16:9'    
 id={formData.id}
 placeholder="https://firebasestorage.googleapis.com/v0/b/comligoaap.appspot.com/o/other%2FBusiness%20Spanish_business%20woman.jpg?alt=media&token=d59fcced-cef6-4715-8881-451ccfbd0494"
 source='youtube'
/>
{/* <h2>Video Resources</h2> */}
<div className="videos-form">
<Form  onSubmit={onSubmit}>
        {/* <Form.Field>
            <Input
            placeholder="Insert the video id"
            onChange={(e) => setformData( e.target.value)}
            />
        </Form.Field>    */}
        {/* <Form.Field> */}
        <h3 className="seleccionar-video">Select a video of the list:</h3>
          <Dropdown
            placeholder="video list"
            search
            selection
            lazyLoad
            options={videos}
            onChange={(e,data) => setformData({...formData, id: data.value})}
            className="listado-videos" />
         {/* </Form.Field>    */}
      </Form>  
      {/* <Button type="submit" onClick={onSubmit}>
             Upload record
         </Button>  */}
     </div>     
     </div>   
     </>
  )
}

function initialValueForm(){
  return{
     id:""
}
}