import React, { useState } from 'react';
import { Form, Input, Button, Dropdown,Icon} from "semantic-ui-react";
import { map } from "lodash";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import firebase from "../../../utils/Firebase";
import "firebase/firestore";
import "firebase/storage";

import "./AddMaterialsForm.scss";

const db = firebase.firestore(firebase);


const Courses= [
    { key: '1', value: 'general',text: 'General Spanish' },
    { key: '2', value: 'diversity' ,text: 'Diversity'},
    { key: '3', value: 'business' ,text: 'For Business'},
    { key: '4', value: 'tourism' ,text: 'For Tourism'},
    { key: '5', value: 'culture' ,text: 'Culture and Civilization'}, 
    { key: '6', value: 'ccse' ,text: 'Preparation for the CCSE'}, 
    { key: '7', value: 'dele' ,text: 'Preparation for the DELE'}, 
    { key: '8', value: 'siele' ,text: 'Preparation for the SIELE'}, 
    { key: '9', value: 'health' ,text: 'For Health Care'}, 
    { key: '10', value: 'law' ,text: 'For Law'}, 
    { key: '11', value: 'lgtbi' ,text: 'For LGTBI'}, 
    { key: '12', value: 'religious' ,text: 'For Religious'}, 
    { key: '13', value: 'security' ,text: 'For Security'}, 
    { key: '14', value: 'sephardis' ,text: 'For Sephardis'}, 
    { key: '15', value: 'training' ,text: 'Teacher Training'}, 
  ]

  const Level= [
    { key: '1', value: 'advanced',text: 'Advanced' },
    { key: '2', value: 'beginner' ,text: 'Beginner'},
    { key: '3', value: 'intermediate' ,text: 'Intermediate'},
    { key: '4', value: 'a1' ,text: 'A1'},
    { key: '5', value: 'a2' ,text: 'A2'},
    { key: '6', value: 'b1' ,text: 'B1'},
    { key: '7', value: 'b2' ,text: 'B2'},
    { key: '8', value: 'c1' ,text: 'C1'},
    { key: '9', value: 'c2' ,text: 'C2'},
    { key: '10', value: 'm' ,text: 'M'},
    { key: '11', value: '1' ,text: 'N/A'},
  ]  

  const Sublevel= [
    { key: '1', value: '1',text: '1' },
    { key: '2', value: '2' ,text: '2'},
    { key: '3', value: '3' ,text: '3'},
    { key: '4', value: '4' ,text: '4'},
    { key: '5', value: '5' ,text: '5'},
    { key: '6', value: '6' ,text: '6'},
    { key: '7', value: '1' ,text: 'N/A'},
  ]  

function AddMaterialsForm(props) {

  const {setShowModal}= props;

    const [formData, setFormData] = useState(initialValueForm());
    const [isLoading, setIsLoading] = useState(false);
    const { t } = useTranslation();

    const onSubmit=()=>{
      // console.log("Enviando formulario..");
     // console.log(formData);        
      setShowModal(false);
      db.collection("available")
                  .add({
  
                    //course:formData.course,
                    //level:formData.level,
                    //sublevel:formData.sublevel,
                    courselink:formData.courselink,
                    coursename:formData.course+"."+formData.level+"."+formData.sublevel
                    })
                    .then(() => {
                      toast.success("The class material is uploaded.");
                      // resetForm();
                      setIsLoading(false);
                      setShowModal(false);
                    })
                    .catch(() => {
                      toast.warning("Error creating class material record.");
                      setIsLoading(false);
                    });
  }

    return (
<Form className="add-course-form" onSubmit={onSubmit}>
<Form.Group>
    <Form.Field className="course-inputs" >       
       <Dropdown 
        placeholder= {t("Course")}
        fluid
        search
        selection
        options={Courses}
        lazyLoad
        onChange={(e, data) =>
          setFormData({ ...formData, course: data.value})}
       className="añadir-cursos"  />
       <br>
       </br>         
       <Dropdown 
        placeholder= {t("Level")}
        fluid
        search
        selection
        options={Level}
        lazyLoad
        onChange={(e, data) =>
          setFormData({ ...formData, level: data.value})}
       className="añadir-cursos"  />
       <br>
       </br>
       <Dropdown 
        placeholder= {t("Sublevel")}
        fluid
        search
        selection
        options={Sublevel}
        lazyLoad
        onChange={(e, data) =>
          setFormData({ ...formData, sublevel: data.value})}
       className="añadir-cursos"  />
       <br>
       </br>              
       <Input placeholder= {t("Courselink")}
                  onChange={e => setFormData({...formData, courselink: e.target.value})}
                  className="entrada-cursos"
                  icon={<Icon name="staylinked"/>}
                  />
                 <br>
                 </br>                              
    </Form.Field>
</Form.Group>
<Button type="submit" isLoading={isLoading} className="enviar-cursos">{t("Sendrecord")}</Button>
</Form>
    )
}

function initialValueForm (){
  return{
  
      coursename:"",
      courselink:""
  }
}


export default AddMaterialsForm;