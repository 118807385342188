import React from 'react';

import { Chart, SeriesTemplate, CommonSeriesSettings, Title} from 'devextreme-react/chart';
import {Grid} from 'react-bootstrap';
import ProgressBar from 'react-customizable-progressbar';
import { Progress} from 'semantic-ui-react';
// import { dataSource } from './data.js';

//import logo from '../../logo.svg';
import './Evaluation.scss'

class App extends React.Component {
  constructor(props) {
    super(props);
    // console.log(this.props.user.uid);
    // console.log(this.props.grades);
   //console.log(this.props.evaluation);
  }
  state = { percent: 0 }

  // customizeTooltip(arg) {
  //   return {
  //     text: `Current : ${arg.percentText} <br>Perecentage :${arg.valueText}`
  //   };
  // }


  // increment = () =>
  //   this.setState((prevState) => ({
  //     percent: prevState.percent >= 100 ? 0 : prevState.percent + 20,
  //   }))


  render() {
    return (
   
      <div className="App">
           <header className="App-header">
           {/* <img src={logo} className="App-logo" alt="logo" /> */}
        {/* <p>Click to add event to the Calendar</p> */}
        <p className="titulo-dashboard">{this.props.nivelclase}.{this.props.clasesubnivel}{" "}{this.props.tituloclase}{" "}course</p>
        <Grid className="purchase-card">
        <div class="col">
      {/* <PieChart
        id="pie"
        type="doughnut"
        title="Evaluation Ring"
        palette="Soft Pastel"
        dataSource={olympicMedals}
        onPointClick={this.pointClickHandler}
      >
        <Series argumentField="course" valueField="overal">
          <HoverStyle color="#ffd700" />
        </Series>
         <Export enabled={true} /> 
         <Legend
          margin={0}
          horizontalAlignment="right"
          verticalAlignment="top"
        /> 
      </PieChart> */}
      <p className="evaluation">Overall evaluation</p>
      <ProgressBar
            radius={100}
            progress={this.props.grades} 
            strokeWidth={18}
            strokeColor="#1db954"
            strokeLinecap="round"
            trackStrokeWidth={18}
        >
            <div className="indicator">
                <div>{this.props.grades}%</div>
            </div>
        </ProgressBar>
      </div>
      <div className="col">
      <Chart
        id="chart"
        palette="Bright"
        dataSource={this.props.evaluation}>
        <CommonSeriesSettings
          argumentField="lesson"
          valueField="grade"
          type="bar"
          ignoreEmptyPoints={true}
          
        />
        <SeriesTemplate nameField="lesson" />
        <Title
          text="Level Skills"
          // subtitle="B 1.1"
        />

      </Chart>
      </div>
{/* 2 */}
{/* <div id="chart-demo">
        <div className="long-title">
         <h4>Progression Bar</h4> 
          Progression Bar 
        </div>
        <table className="demo-table">
          <tbody>
            <tr>
              <th></th>
              <th>June</th> 
             <th>July</th>
              <th>August</th>
            </tr>
            {service.getWeeksData().map((week, i) => (
              <tr key={i}>
                <th>{`${week.weekCount} units`}</th>
                {week.bulletsData.map((data, i) => {
                  return (
                    <td key={i}>
                      <Bullet
                        className="bullet"
                        startScaleValue={0}
                        endScaleValue={35}
                        value={data.value}
                        target={data.target}
                        color={data.color}
                      >
                        <Tooltip customizeTooltip={this.customizeTooltip} />
                      </Bullet>
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>   
  </div> */}
     <div id="chart-demo">
        <Progress percent={((this.props.macthcompletion*100)/this.props.completionbar).toFixed(2)} 
        size='large'
        indicating 
        progress
        />
   {/* <Progress 
        indicating 
        value={((1)*100/25)}
        progress='value'
        /> */}
        <p className="completion">Completion status</p>
        {/* <Button onClick={this.increment}>Increment</Button> */}
     </div>
      </Grid>
      </header>
      </div>

    );
  }

  pointClickHandler(arg) {
    arg.target.select();
  }
}

export default App;
