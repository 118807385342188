import React,{useState,useEffect} from 'react';
import {Menu,Icon} from "semantic-ui-react";
import {Link,withRouter} from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";
//import {Col} from 'react-bootstrap';
import { isUserAdmin,isUserTeacher,isRegularUser } from '../../utils/Api';
import BasicModal from "../Modal/BasicModal";
/////////
import AddEvaluationForm from '../../components/Evaluation/AddEvaluationFrom';
///////
import AddClassForm from '../../components/Classroom/AddClassForm';
//////////
import AddStudentForm from '../../components/Classroom/AddStudentForm';
//////
import AddMaterialsform from '../../components/Classroom/AddMaterialsForm';
/////
import AddPromotionsForm from '../../components/Promotions/AddPromotionsForm';
//////
import AddResourcesForm from '../Resources/AddResourcesForm/AddResourcesForm';
///////
import RemoveResourcesForm from '../Resources/RemoveResourcesForm/RemoveResourcesForm';
/////
import RemovePromotionsForm from '../Promotions/RemovePromotionsForm/RemovePromotionsForm';
////
import BlogCover from '../Blogs/BlogCover/BlogCover';
//////
import BlogContent from '../Blogs/BlogContent/BlogContent';
//////
import BlogMeta from '../Blogs/BlogMeta/BlogMeta';
/////
import AddCollaterals from '../Collaterals/AddCollaterals';

import "./MenuLeft.scss"

function MenuLeft(props) {
    const {user,location} = props;
    //console.log(props);
    const [activeMenu, setActiveMenu] = useState(location.pathname);
    const [userAdmin, setUserAdmin] = useState(false);
    const [userTeaching, setUserTeaching] = useState(false);
    const [regularuser, setRegularuser] = useState(false);
    const [showModal, setShowModal] = useState(false);
  const [titleModal, setTitleModal] = useState(null);
  const [contentModal, setContentModal] = useState(null);
  const { t } = useTranslation();
//console.log(regularuser);
//console.log(userAdmin);
useEffect(() => {
  setActiveMenu(location.pathname);
}, [location]);
//////////user admin
    useEffect(() => {
        isUserAdmin(user.uid).then(response => {
             setUserAdmin(response);
           });     
   }, [user])
////////////user teaching   
useEffect(() => {
  isUserTeacher(user.uid).then(response => {
       setUserTeaching(response);
     // console.log(response);
     });     
}, [user])
///////////////regular user
useEffect(() => {
  isRegularUser(user.uid).then(response => {
      setRegularuser(response);
      // console.log(response);
     });     
}, [user])

//////////
    const handlerMenu = (e, menu) => {
        setActiveMenu(menu.to)
        // console.log(menu);
   };

   const handlerModal = (type) => {
      switch (type) {
        case "studentclass":
          setTitleModal("Assign student Class");
          setContentModal(
          <AddStudentForm setShowModal={setShowModal}/>
          );
          setShowModal(true);
          break;
        case "classroom":
          setTitleModal("New classroom");
          setContentModal(
        //   <AddCoursesForm setShowModal={setShowModal}/>
        <AddClassForm setShowModal={setShowModal}/>
          );
          setShowModal(true);
          break;
          case "classmaterials":
            setTitleModal(t("Newclassmaterial"));
            setContentModal(
          <AddMaterialsform setShowModal={setShowModal}/>
            );
            setShowModal(true);
            break;
        case "record":
          setTitleModal("New grade");
          setContentModal(
        //   <AddRecordForm setShowModal={setShowModal}/>
          // <h2>Evaluation Form</h2>
          <AddEvaluationForm setShowModal={setShowModal}/>
          );
          setShowModal(true);
          break; 
          case "promotions":
            setTitleModal(t("Addnews"));
            setContentModal(
          //   <AddRecordForm setShowModal={setShowModal}/>
            // <h2>Evaluation Form</h2>
            <AddPromotionsForm setShowModal={setShowModal}/>
            );
            setShowModal(true);
            break; 
            case "removepromotions":
            setTitleModal(t("Deletenews"));
            setContentModal(
          //   <AddRecordForm setShowModal={setShowModal}/>
            // <h2>Evaluation Form</h2>
            <RemovePromotionsForm setShowModal={setShowModal}/>
            );
            setShowModal(true);
            break; 
            case "resources":
              setTitleModal(t("Newresource"));
              setContentModal(
            //   <AddRecordForm setShowModal={setShowModal}/>
              // <h2>Evaluation Form</h2>
              <AddResourcesForm setShowModal={setShowModal}/>
              );
              setShowModal(true);
              break; 
              case "deleteresources":
                setTitleModal(t("Deleteresources"));
                setContentModal(
              //   <AddRecordForm setShowModal={setShowModal}/>
                // <h2>Evaluation Form</h2>
                <RemoveResourcesForm setShowModal={setShowModal}/>
                );
                setShowModal(true);
                break; 
                case "blogcover":
                  setTitleModal(t("Blogcover"));
                  setContentModal(
                //   <AddRecordForm setShowModal={setShowModal}/>
                  // <h2>Evaluation Form</h2>
                  <BlogCover setShowModal={setShowModal}/>
                  );
                  setShowModal(true);
                  break;
                  case "blogcontent":
                    setTitleModal(t("Blogcontent"));
                    setContentModal(
                  //   <AddRecordForm setShowModal={setShowModal}/>
                    // <h2>Evaluation Form</h2>
                    <BlogContent setShowModal={setShowModal}/>
                    );
                    setShowModal(true);
                    break;
                    case "blogmeta":
                      setTitleModal(t("blogCEO"));
                      setContentModal(
                    //   <AddRecordForm setShowModal={setShowModal}/>
                      // <h2>Evaluation Form</h2>
                      <BlogMeta setShowModal={setShowModal}/>
                      );
                      setShowModal(true);
                      break;
                      case "webcollaterals":
                        setTitleModal("Collaterals");
                        setContentModal(
                      //   <AddRecordForm setShowModal={setShowModal}/>
                        // <h2>Evaluation Form</h2>
                        <AddCollaterals setShowModal={setShowModal}/>
                        );
                        setShowModal(true);
                        break;    
                
          default:
          setTitleModal(null);
          setContentModal(null);
          setShowModal(false);
          break;
      }
    };


    return (
        <>
          {/* <Col md={3}>  */}
        <Menu className="menu-left" vertical>
            <div className="top">
              <Menu.Item as={Link} to="/" name="home" active={activeMenu === "/"} onClick={handlerMenu}>
                  <Icon name="home"/>{t("Home")}
              </Menu.Item>
              {regularuser && (
                <>
                  {/* <Menu.Item as={Link} to="/subscriptions" name="subscriptions" active={activeMenu === "/subscriptions"} onClick={handlerMenu}>
                      <Icon name="sync alternate"/>Subscriptions
                  </Menu.Item> */}
                  <Menu.Item  as={Link} to="/mycourses"name="mycourses" active={activeMenu === "/mycourses"} onClick={handlerMenu}>
                      <Icon name="book"/>{t("Courses")}
                  </Menu.Item>
                  {/* <Menu.Item as={Link} to="/download" name="download" active={activeMenu === "/download"} onClick={handlerMenu}>
                      <Icon name="download"/>{t("Download")}
                  </Menu.Item>
                  <Menu.Item as={Link} to="/evaluation" name="evaluation" active={activeMenu === "/evaluation"} onClick={handlerMenu}>
                      <Icon name="chart line"/>{t("Progress")}
                  </Menu.Item>
                  <Menu.Item as={Link} to="/test" name="test" active={activeMenu === "/test"} onClick={handlerMenu}>
                      <Icon name="file alternate"/>{t("Leveltest")}
                  </Menu.Item> */}
                  {/* <Menu.Item  as={Link} to="/videos"name="videos" active={activeMenu === "/videos"} onClick={handlerMenu}>
                    <Icon name="video"/>{t("Videos")} 
                </Menu.Item> */}
                <Menu.Item  as={Link} to="/mediaresources"name="mediaresources" active={activeMenu === "/mediaresources"} onClick={handlerMenu}>
                    <Icon name="th list"/>{t("Resources")} 
                </Menu.Item>
                <Menu.Item  as={Link} to="/myinputs"name="myinputs" active={activeMenu === "/myinputs"} onClick={handlerMenu}>
                    <Icon name="thumbtack"/>{t("Teacherfeedback")}
                </Menu.Item>
              </>
              )}
               
               {userTeaching && (
                 <>
                  {/* <Menu.Item as={Link} to="/download" name="download" active={activeMenu === "/download"} onClick={handlerMenu}>
                      <Icon name="download"/>{t("Download")}
                  </Menu.Item> */}

                  {/* <Menu.Item as={Link} to="/guide" name="guide" active={activeMenu === "/guide"} onClick={handlerMenu}>
                      <Icon name="cloud download"/>{t("Teacherguide")}
                  </Menu.Item> */}
                  
                  <Menu.Item as={Link} to="/class" name="class" active={activeMenu === "/class"} onClick={handlerMenu}>
                      <Icon name="arrow alternate circle right"/>{t("Myclasses")}
                  </Menu.Item>

                  <Menu.Item as={Link} to="/evaluatetest" name="evaluatetest" active={activeMenu === "/evaluatetest"} onClick={handlerMenu}>                  
                      <Icon name="certificate"/>{t("Incomingtest")}
                  </Menu.Item>

                  <Menu.Item as={Link} to="/evaluatestudents" name="evaluatestudents" active={activeMenu === "/evaluatestudents"} onClick={handlerMenu}>                  
                      <Icon name="thumbtack"/>{t("Evaluatestudents")}
                  </Menu.Item>
                  {/* <Menu.Item  as={Link} to="/videos"name="videos" active={activeMenu === "/videos"} onClick={handlerMenu}>
                    <Icon name="video"/>{t("Videos")} 
                  </Menu.Item> */}
                  <Menu.Item  as={Link} to="/mediaresources"name="mediaresources" active={activeMenu === "/mediaresources"} onClick={handlerMenu}>
                      <Icon name="th list"/>{t("Resources")} 
                  </Menu.Item>
              </>

               )}
              
              {userAdmin &&( 
                <>
                  {/* <Menu.Item as={Link} to="/subscriptions" name="subscriptions" active={activeMenu === "/subscriptions"} onClick={handlerMenu}>
                      <Icon name="sync alternate"/>Subscriptions
                  </Menu.Item> */}
                  <Menu.Item as={Link} to="/download" name="download" active={activeMenu === "/download"} onClick={handlerMenu}>
                      <Icon name="download"/>{t("Download")}
                  </Menu.Item>
                  <Menu.Item as={Link} to="/evaluation" name="evaluation" active={activeMenu === "/evaluation"} onClick={handlerMenu}>
                      <Icon name="chart line"/>{t("Progress")}
                  </Menu.Item>
                  <Menu.Item as={Link} to="/test" name="test" active={activeMenu === "/test"} onClick={handlerMenu}>
                      <Icon name="file alternate"/>{t("Leveltest")}
                  </Menu.Item>  
                  <Menu.Item as={Link} to="/book" name="book" active={activeMenu === "/book"} onClick={handlerMenu}>
                      <Icon name="calendar alternate"/>{t("Booking")}
                  </Menu.Item>
                  {/* <Menu.Item as={Link} to="/guide" name="guide" active={activeMenu === "/guide"} onClick={handlerMenu}>
                      <Icon name="cloud download"/>{t("Teacherguide")}
                  </Menu.Item> */}
                  <Menu.Item as={Link} to="/onboarding" name="onboarding" active={activeMenu === "/onboarding"} onClick={handlerMenu}>
                      <Icon name="upload"/>{t("Onboarding")}
                  </Menu.Item>
              </>
              )}
       </div>

       {/* {  userTeaching &&(
       <div className="footer">
       <Menu.Item onClick={()=> handlerModal("record")}>
            <Icon name="plus square outline"/>Evaluate Skills
       </Menu.Item>
       </div>
       )} */}

       {userAdmin &&(
       <div className="footer">
         {/* <Menu.Item onClick={()=> handlerModal("record")}>
            <Icon name="plus square outline"/>{t("Evaluate")}
        </Menu.Item> */}
       {/* <Menu.Item onClick={()=> handlerModal("studentclass")}>
            <Icon name="plus square outline"/>{t("Assingstudentclass")}
       </Menu.Item> */}
       {/* <Menu.Item onClick={()=> handlerModal("classroom")}>
            <Icon name="plus square outline"/>{t("Newclassroom")}
       </Menu.Item> */}
       <Menu.Item onClick={()=> handlerModal("classmaterials")}>
            <Icon name="plus square outline"/>{t("Newclassmaterials")}
       </Menu.Item>
       <Menu.Item onClick={()=> handlerModal("promotions")}>
            <Icon name="plus square outline"/>{t("News")}
       </Menu.Item>
       <Menu.Item onClick={()=> handlerModal("removepromotions")}>
            <Icon name="plus square outline"/>{t("Deletenews")}
       </Menu.Item>
       <Menu.Item onClick={()=> handlerModal("resources")}>
            <Icon name="plus square outline"/>{t("Resources")}
       </Menu.Item>
       <Menu.Item onClick={()=> handlerModal("deleteresources")}>
            <Icon name="plus square outline"/>{t("Deleteresources")}
       </Menu.Item>
       <Menu.Item onClick={()=> handlerModal("blogcover")}>
            <Icon name="plus square outline"/>{t("Addblogcover")}
       </Menu.Item>
       <Menu.Item onClick={()=> handlerModal("blogcontent")}>
            <Icon name="plus square outline"/>{t("Addblogcontent")}
       </Menu.Item>
       <Menu.Item onClick={()=> handlerModal("blogmeta")}>
            <Icon name="plus square outline"/>{t("AddblogCEO")}
       </Menu.Item>
       <Menu.Item onClick={()=> handlerModal("webcollaterals")}>
            <Icon name="plus square outline"/>Add collaterals
       </Menu.Item>
       </div>
       )}
    {/* {isUserTeacher &&(
      <div className="footer">
      <Menu.Item onClick={()=> handlerModal("record")}>
      <Icon name="plus square outline"/>Evaluate
      </Menu.Item>
      <Menu.Item onClick={()=> handlerModal("maestro")}>
      <Icon name="plus square outline"/>New Teacher
      </Menu.Item>
      <Menu.Item onClick={()=> handlerModal("album")}>
      <Icon name="plus square outline"/>New Course 
      </Menu.Item>
      
      </div>
    )} */}
        </Menu>
        <BasicModal show={showModal} setShow={setShowModal} title={titleModal}>
          {contentModal}
        </BasicModal>
       {/* </Col>  */}
        </>
    )
}

export default withRouter(MenuLeft)