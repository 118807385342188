import React, { useState,useCallback } from 'react';
import { Form, Table,Icon,Container } from "semantic-ui-react";
import { map } from "lodash";
import { toast } from "react-toastify";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";

import "./onboardingbooking.scss";
//import csv from 'csv';
import csv from 'csvtojson';

function Onboardingbooking() {


    const [cvsjson, setCvsjson] = useState(null);
    const { t } = useTranslation();


    const onDrop = useCallback(acceptedFiles => {
        const file =acceptedFiles[0];
       // setFiles(file);

        const reader = new FileReader();
    
        reader.onabort = () => console.log("file reading was aborted");
        reader.onerror = () => console.log("file reading failed");
        reader.onload = () => {
   
        const fileAsBinaryString = reader.result;

        csv({
          noheader: true,
          output: "json"
        })
          .fromString(fileAsBinaryString)
          .then((csvRows) => {
            const toJson = []
            csvRows.forEach((aCsvRow, i) => {


              if (i !== 0) {
                const builtObject = {}

                Object.keys(aCsvRow).forEach((aKey) => {
                  const valueToAddInBuiltObject = aCsvRow[aKey];
                  const keyToAddInBuiltObject = csvRows[0][aKey];
                  builtObject[keyToAddInBuiltObject] = valueToAddInBuiltObject;
                })

                toJson.push(builtObject)
              }


            })
            setCvsjson (toJson)
          // console.log(toJson);
          })
        };
    
        // read file contents
        acceptedFiles.forEach(file => reader.readAsBinaryString(file));
      }, []);


    const {getRootProps
           ,getInputProps,
           isDragActive, 
           isDragAccept, 
           isDragReject
    } 
    = useDropzone({
    	accept: 'text/csv, application/vnd.ms-excel',
        noKeyboard:true,
        onDrop
  });




    return (
        <>   
        <Form className="add-onboardingb-fom">
             <Form.Field className="onboardingb-banner">
             <div
                  {...getRootProps()}
                  className="bannerb"
                />    
             <input {...getInputProps()} />
               {isDragAccept  &&  <p className="droptextb">{t("Drop2")}</p>}
                  {isDragReject  &&  <p className="droptextb">{t("Drop3")}</p>}
                  {!isDragActive &&  <p className="droptextb">{t("Drop")}...      </p>}
              </Form.Field>
        </Form>
        <br>
        </br>
        <Bookingtable  cvsjson={cvsjson} t={t}/>
        </>
    )
}


function Bookingtable (props){

    const{cvsjson,t}=props;
  
  //console.log(cvsjson);
  
    return (
   <Container>
     {cvsjson &&
        <Table inverted >
        <Table.Header>
            <Table.Row>
              <Table.HeaderCell >{t("Classid2")}</Table.HeaderCell>
              <Table.HeaderCell >{t("Classname2")}</Table.HeaderCell>
              <Table.HeaderCell >{t("Startdate2")}</Table.HeaderCell>
              <Table.HeaderCell >{t("Enddate")}</Table.HeaderCell>
              <Table.HeaderCell >{t("Sessionduration2")}</Table.HeaderCell>
              <Table.HeaderCell >{t("SessionUrl")}</Table.HeaderCell>
              <Table.HeaderCell >{t("Studentemail")}</Table.HeaderCell>
              <Table.HeaderCell >{t("Teacheremail")}</Table.HeaderCell>
              <Table.HeaderCell >{t("Timezone")}</Table.HeaderCell>
              <Table.HeaderCell >{t("Agregar")}</Table.HeaderCell>
            </Table.Row>
        </Table.Header>
        <Table.Body>
            {map(cvsjson, cvsjson => (
              <Bookingrows
              key={cvsjson.id}
              cvsjson={cvsjson}   
              />
          ))}
        </Table.Body>
      </Table>
      }
    </Container>  
      );
  }
  
  
  function Bookingrows (props) {
  
    const{cvsjson}=props;
    console.log(cvsjson);
  
    const {
        claseId,
        claseName,
        endDate,
        hr,
        link,
        startDate,
        student,
        teacher,
        timeZone
    } = cvsjson;
  
    //console.log(cvsjson);
    //console.log(email);
    //const HOST = process.env.HOST || "http://localhost:8080"
    const HOST = process.env.HOST || "https://restserver-323001.uw.r.appspot.com"
  
    const onSubmit = async () =>{
   // console.log('click');
  
    try {
      const response = await fetch(HOST + "/api/courses/booking", {
        headers: {
          'Content-Type': 'application/json',
          'Accept':       'application/json',
        },
        method: "POST",
        body:  JSON.stringify(cvsjson)
      })
    
      
      if(response.status === 404) 
        throw {message: "Bad request"}
        
        
      if(response.status !== 200) 
        throw new Error()
  
  
      // Si la tarea se ha cumplido correctamente 
      // podemos borrar al usuario de la tabla
  
  
  
      toast.success("Booking created");
    }
    catch(e) {
      toast.warning("ERROR: something went wrong...");
    }
  
  
  
    }
  
    return (
      <>
      <Table.Row > 
      <Table.Cell collapsing>
       { claseId }
       </Table.Cell>
       <Table.Cell>
       { claseName }
       </Table.Cell>
       <Table.Cell>
       {startDate}
       </Table.Cell>  
       <Table.Cell>
      {endDate}
       </Table.Cell> 
       <Table.Cell>
       {hr}
       </Table.Cell> 
       <Table.Cell>
       {link}
       </Table.Cell>  
       <Table.Cell>
       {student}
       </Table.Cell>
       <Table.Cell>
       {teacher}
       </Table.Cell> 
       <Table.Cell>
       {timeZone}
       </Table.Cell>     
       <Table.Cell>
       {/* <Icon name="plus"  onclick="onSubmit"/> */}
       <Icon className="add-sb"  name='plus' link onClick={ onSubmit }/>
       </Table.Cell>     
      </Table.Row>
      </>
    );
  
  }
  

export default Onboardingbooking;