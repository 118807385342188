import React,{useState,useEffect} from 'react';
import {withRouter} from "react-router-dom";
import GradeTestForm from "../../components/Evaluation/GradeTestForm";
import firebase from "../../utils/Firebase";
import "firebase/firestore";

import "./GradeTest.scss";

const db = firebase.firestore(firebase);

function GradeTest(props) {

    const {match,user}=props;
    //console.log(match.params.id);

    const [studenanswers, setStudenanswers] = useState(null);
    const [idcomentario, setIdcomentario] = useState(null);

    //console.log(idcomentario);

   useEffect(() => {
    db.collection("answers")
      .doc(match?.params?.id)
      .get()
      .then(response => {
         // console.log(response.data());
          setStudenanswers(response.data());
          setIdcomentario(match.params.id);
      });
  }, [match]);

    return (
        <div className="test-grade-info">
          {studenanswers && 
         <GradeTestForm studenanswers={studenanswers} user={user} idcomentario={idcomentario}/>
         }
        </div>
    )
}

export default withRouter(GradeTest);