import React, {useState,useEffect} from 'react';
import { withRouter } from 'react-router';
import Bannerresources from './Bannerresources/Bannerresources';
import firebase from '../../utils/Firebase';
import "firebase/firestore";

import './resources.scss';
//import Bannerpromotions from '../Promotions/Bannerpromotions/Bannerpromotions';

const db = firebase.firestore(firebase);

function Resources(props) {

    //console.log(props);
    const { match } = props;
    const [resources, setResources] = useState(null);
    // console.log(resources);

    useEffect(() => {
        db.collection("resources")
        .doc(match?.params.id)
        .get()
        .then(response =>{
           // console.log(response.data());
            setResources(response.data())
        })
     }, [match]);

     

    return (
        <div className="resouces">
            {resources &&
            
          <Bannerresources resources={resources} />
            }
        </div>
    )
}


export default withRouter(Resources);