import React,{useState} from 'react'
import { Icon, Image } from "semantic-ui-react";
import { Link, withRouter } from "react-router-dom";
import firebase from "../../utils/Firebase";
import "firebase/auth";
import UserImage from "../../assets/png/user.png";
import "./TopBar.scss";
import BasicModal from '../Modal/BasicModal';
import Logout from "../../components/Auth/Logout";

 function Topbar(props) {
    const { user, history } = props;
    const [showlogout, setShowoglout] = useState(false);
   // console.log(props);
    const logout= ()=>{
       // console.log("cerrar sesion");
      // firebase.auth().signOut();
      setShowoglout(true);
    }

    const goBack= ()=>{
       history.goBack();
    }

    return (
      <>
        <div className="top-bar">
      <div className="top-bar__left">
        <Icon name="angle left" onClick={goBack} />
      </div>

      <div className="top-bar__right">
        <Link to="/settings">
         <Image src={user.photoURL ? user.photoURL : UserImage}/>
        {user.displayName}
        </Link>
        <Icon name="power off" onClick={logout}/>
      </div>
    </div>
    <BasicModal 
    show={showlogout}
    setShow={setShowoglout}
    title={"Logout"}
    >
    <Logout setShowoglout={setShowoglout}/>
    </BasicModal>
    </>
    )
}

export default withRouter(Topbar);