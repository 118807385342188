import React,{useEffect,useState} from 'react';
import { map } from "lodash";
import Evaluation from '../../components/Evaluation';
import firebase from "../../utils/Firebase";
import 'firebase/firestore';
// import { Button } from 'react-bootstrap';
import './Evaluations.scss';

const db = firebase.firestore(firebase);

export default function Evaluations(props) {

    const {user}=props;
    const [grades, setGrades] = useState("");
    const [evaluation, setEvaluation] = useState([]);
    const [informacionclase, setInformacionclase] = useState(null);
    const [tituloclase, setTituloclase] = useState(null);
    const [nivelclase, setnivelclase] = useState(null);
    const [clasesubnivel, setClasesubnivel] = useState(null);
    const [completionbar, setCompletionbar] = useState(null);
    const [macthcompletion, setMacthcompletion] = useState(null);
  //  const [informacioncurso, setInformacioncurso] = useState(null);
 //console.log(evaluation);
 //console.log(evaluation);
 ///console.log(informacionclase);
// console.log(informacionclase);
//console.log(macthcompletion);
//console.log(completionbar);
/////
useEffect(() => {
        // const {user}=props;

        db.collection("grades")
        .get()
        .then(response => {
            
         const arrayGrades = [];
         const arrayEvaluations = [];
          map(response?.docs, grades => {
            const data = grades.data();
            // console.log(data.user);
            if(user.uid === data.studentid){
         //    console.log(data);
      
                arrayGrades.push({
                 key: grades.id,
                 grade: data.studentgrade,
                 lesson: data.lesson,
               //   uid: data.uid
               });
               //////
               arrayEvaluations.push({
                key: grades.id,
                grade: data.studentgrade,
                lesson: data.studentskill,
              //   uid: data.uid
              });
            }else{
                // console.log("fail");
            }
          });
        // console.log(arrayGrades);      
          const list =arrayEvaluations.sort((a, b) => (a.grade > b.grade) ? 1 : -1)
         // console.log(list);
          setEvaluation(list);
        
        // console.log(list)
        // const data = [
        //     {title:"One",prix:"100"},
        //     {title:"Two",prix:"200"},
        //     {title:"Three",prix:"300"}
        //   ];
        //   console.log((data.reduce((a,v) =>  a = a + v.prix , 0 )))
        //   const result = evaluation.reduce((total, currentValue) => total + currentValue.grade,0);         
        //   console.log(result);
        // let array = [
        //     {quantity: 1, amount: "24.99"},
        //     {quantity: 5, amount: "4.99"}
        //    ];
        // let sum = a => a.reduce((x, y) => x + y,0);

//    const sum = a =>  a.reduce( (previousValue, currentValue) => previousValue + currentValue, 0);
//    const sum = a =>  a.reduce( (previousValue, currentValue) => previousValue ,0);
//    const totalAmount = sum(evaluation.map(x => Number(x.grade)));
      
    // console.log(totalAmount.toFixed(2))
    // console.log(totalAmount)
///////////////sum array values grades
const gradeStringTotal = arrayGrades.reduce((totalGrades, x) => totalGrades + parseInt(x.grade, 10), 0);
setGrades(gradeStringTotal);

//   const gradeStringTotal = arrayGrades.reduce((totalGrades, x) => totalGrades, + parseInt(x.grade, 10), 0); 
//   console.log(gradeStringTotal); 
    });
}, [])

///////class listinformation
useEffect(() => {

  db.collection("studentclass")
  .get()
  .then(response => {
      
    map(response?.docs, classeslistado => {
      const data = classeslistado.data();
      data.id=classeslistado.id;
   
      if(user.uid === data.studentid){
        setTituloclase(data.course);
        setnivelclase(data.level);
        setClasesubnivel(data.sublevel);
        setCompletionbar(data.completion);
        setInformacionclase(data.class);
      }else{
    
      }
    });

});
}, [])
/////completion status

useEffect(() => {

  db.collection("completion")
  .get()
  .then(response => {
      
    map(response?.docs, completionlist => {
      const data = completionlist.data();
      data.id = completionlist.id;

      if(informacionclase === data.class){
        setMacthcompletion(data.completionstatus);
      }else{
    
      
     }
    });
  });
////////
////////
}, [informacionclase])

//////////funcion clear dashboard
// const cleardashboard = () =>{
//   if(informacionclase===completionbar){

//     console.log("complete");
//   }
// }

///////////
    return (
        <div>
            <Evaluation 
            user={user} 
            grades={grades} 
            evaluation={evaluation} 
            tituloclase={tituloclase}
            nivelclase={nivelclase}
            clasesubnivel={clasesubnivel}
            completionbar={completionbar}
            macthcompletion={macthcompletion}
            />
           
        </div>
    )
}
