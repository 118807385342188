import React,{useState,useEffect} from 'react';
import {Table,Icon,Container} from "semantic-ui-react";
import { map } from "lodash";
import {Link} from "react-router-dom";
import firebase from ".././../../utils/Firebase";
import 'firebase/firestore';

import './ListStudents.scss';


const db = firebase.firestore(firebase);

function ListStudents(props) {

    const {studentlist,user}=props;
    const [misestudiantes, setMisestudiantes] = useState(null);
    const [idestudiante, setIdestudiante] = useState(null);
   // console.log(studentlist.class);
    //console.log(misestudiantes);
  //  console.log(idestudiante);

///////class students
useEffect(() => {
    // const {user}=props;

    db.collection("studentclass")
    .get()
    .then(response => {
        
    const arrayStudents = [];
    // const arrayEvaluations = [];
      map(response?.docs, studentslisted => {
        const data = studentslisted.data();
        data.id=studentslisted.id;
       // console.log(data.teacher);
        if(studentlist.classname === data.classname){
          //  setClase(data.class);
       // console.log(data.class);
       setIdestudiante(data.student)
       arrayStudents.push(data);
        }else{
            // console.log("fail");
        }
      });
     // console.log(list);
    //console.log(arrayClases);
    setMisestudiantes( arrayStudents);
});
}, [])
//////  
  


    return (
       <> 
       {studentlist ? (    
        <Container textAlign='justify'>
        <Table inverted className="list-student-detail">
        <Table.Header>
          <Table.Row>
            {/* <Table.HeaderCell /> */}
            <Table.HeaderCell className="title-student-detail"><h1 className="estudiante-tt">My Students:</h1></Table.HeaderCell>
          </Table.Row>
        </Table.Header>
  
        <Table.Body>
       {map(misestudiantes, misestudiantes=> (
          <Recordsdos
            key={misestudiantes.id}
            misestudiantes={misestudiantes}   
            idestudiante={idestudiante}         
          />
        ))}
        </Table.Body>
      </Table>
        </Container>
      ) : (
        <Container>
        <p className="not-assigned-tres">
       You already sent your answers!
       <br></br>
        or
        <br></br>
        don´t have a class assigned yet.
        </p>
        </Container>
        )}
      </>  
    )
}

function Recordsdos(props){

    const{misestudiantes,idestudiante}=props;
   // const [nombrestudiante, setNombrestudiante] = useState(null);
    //console.log(clase.id);
    //console.log(key);
    //console.log(misestudiantes);
 //   console.log(nombrestudiante);
    //console.log(idestudiante);

    
        return (
          <>
          <Table.Row >
          <Link to={`/studentdetails/${misestudiantes.studentid}`}  style={{ color: 'inherit', textDecoration: 'inherit'}} >  
            <Table.Cell collapsing>
              <Icon name="user" />
            </Table.Cell>
            <Table.Cell>{misestudiantes.studentname}</Table.Cell>     
            </Link>
          </Table.Row>
          </>
        );
      }
      export default ListStudents;
