import React, { useState, useEffect } from "react";
import { Loader,Table,Icon } from "semantic-ui-react";
import { withRouter } from "react-router-dom";
import { map } from "lodash";
import { useTranslation } from "react-i18next";
import ReactPlayer from "react-player";
import firebase from "../../../utils/Firebase";
import "firebase/firestore";
import "firebase/storage";

import './filesdetails.scss';

const db = firebase.firestore(firebase);

function Filesdetails(props) {
    const {match}=props;
    const [filesalbum, setfilesAlbum] = useState(null);
   const [filesalbumImg, setfilesAlbumImg] = useState(null);
   const [files, setFiles] = useState([]);
   const { t } = useTranslation();
   //console.log(files);

        useEffect(() => {
            db.collection("filesalbums")
            .doc(match.params.id)
            .get()
            .then(response => {           
            // console.log(response);            
            const data = response.data();
            data.id = response.id;
            //console.log(data);
            // setCourselist(data.id)
            setfilesAlbum(data);
            });
        }, [match])

        useEffect(() => {
            if (filesalbum) {
                firebase
                .storage()
                .ref(`filesalbum/${filesalbum?.banner}`)
                .getDownloadURL()
                .then(url => {
                    //console.log(url);
                    setfilesAlbumImg(url);
                });
            }
        }, [filesalbum])


        useEffect(() => {
          if (filesalbum){
            db.collection("files")
            .where("album", "==",filesalbum.id)
            .get()
            .then(response =>{
             const arrayRecords=[];
             map(response?.docs, record=>{
               const data = record.data();
               data.id = record.id;
               arrayRecords.push(data);
             });
             setFiles(arrayRecords);
            });
          }
       }, [filesalbum])  

        if(!filesalbum ){
            return <Loader active>{t("Loading")}...</Loader>;
        }

    return (
     <>
        <div className="files-album">

          <div className="files-album__header">
              <HeaderAlbum filesalbum={filesalbum} filesalbumImg={filesalbumImg}  />
          </div>
        </div>
        <div>
          <ListVideos files={files} filesalbumImg={filesalbumImg} t={t}/>
        </div>
      </>
    )
}


function HeaderAlbum(props) {
    const { filesalbum, filesalbumImg } = props;
   
    return (
      <>
        <div
          className="files-image"
          style={{ backgroundImage: `url('${filesalbumImg}')` }}
        />
        <div className="files-info">
          <h1>{filesalbum.name}</h1>
          <p>
          </p>
        </div>
      </>
    );
  }

  function ListVideos(props) {

    const { files,filesalbumImg,t }= props;
    // console.log(props);
    console.log(filesalbumImg);
    return (
        <Table inverted className="list-files">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell />
            <Table.HeaderCell>{t("Fileresources")}:</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
  
        <Table.Body>
        {map(files, files => (
          <Videorecord
            key={files.id}
            files={files}
            filesalbumImg={filesalbumImg}
  
          />
        ))}
        </Table.Body>
      </Table>
    );
  }


function Videorecord(props){

  const { files }= props;

 const [filedocument, setFiledocument] = useState(null)

 //console.log(filedocument.url);

 useEffect(() => {
    firebase
    .storage()
    .ref(`files/${files.fileName}`)
    .getDownloadURL()
    .then(url =>{
      //console.log(url);
      setFiledocument({url });
    }) 
  }, [])

  const handleClick = () => {
      if(filedocument){
          window.open(filedocument.url);
      }
  };

    return (
      <>
      <Table.Row  >
        <Table.Cell collapsing>
            <Icon   name="folder open"  size='large' onClick={handleClick}/>
        </Table.Cell>
        <Table.Cell onClick={handleClick}><p className="title-file-list">{files.name}</p></Table.Cell>
      </Table.Row>
          </>
    );
  }


export default withRouter(Filesdetails);