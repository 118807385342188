import React,{useEffect,useState} from 'react';
import { map } from "lodash";
import { Button,Icon,Dropdown } from 'semantic-ui-react';
import {Grid} from 'react-bootstrap';
import firebase from "../../utils/Firebase";
// /////toast
import {toast} from "react-toastify";
import StripeCheckout from "react-stripe-checkout";

import axios from "axios";

import 'firebase/firestore';
import './Subscription.scss';

const db = firebase.firestore(firebase);

//var stripe = window.Stripe('');


export default function Subscription(props) {
  
  const {user}=props;
  // const [usuario, setUsuario] = useState(null);
  const [wishlist, setWishlist] = useState(null);
  const [nombre, setNombre] = useState(null);
  const [price, setPrice] = useState(0);
  const [cartid, setCartid] = useState(null);
  const [horas, setHoras] = useState(null);
  const [formData, setformData] = useState(initialValueForm());
  
  const options = [
    { key: 1, text: '1 hrs', value: 1 },
    { key: 2, text: 'Monthly (1 hours per week)', value: 4 },
    // { key: 2, text: '5 hrs', value: 5 },
    { key: 3, text: 'Monthly (2 hours per week)', value: 8 },
    // { key: 4, text: '9 hrs', value: 9 },
    // { key: 5, text: '10 hrs', value: 10 },
    { key: 4, text: 'Course', value: horas},
  ]
  //console.log(formData.hr);
  //console.log(cartid);
  //const [name, setName] = useState(null)
  //console.log(nombre);
 // console.log(name);

//console.log(usuario);
//console.log(usuario);
//////////////////////cart values
  useEffect(() => {
    db.collection("wishlist")
        .get()
        .then(response => {
         // console.log(response);            
          //const arrayWish = [];
          map(response?.docs, list => {
         const data = list.data();
        // console.log(data.id);
         if(user.uid === data.user){
     
////////////array for wishlist        
            //   arrayWish.push({
            //    key: list.id,
            //    grade: data.lesson,
            //    lesson: data.name,
            //  //   uid: data.courseid
            //  });
          // console.log(data.lesson);
           //console.log(list.id);
           //console.log(data.name);
          //  console.log(data.courseid);
          //console.log(data.price);  
          //console.log(data.hr);      
/////////////  
          setWishlist(data.lesson);
          setNombre(data.name);
          setPrice(data.price);
          setHoras(data.hr)
          setCartid(list.id)
          }else{
              // console.log("fail");
          }
          });
       //  console.log(arrayWish);
        });
  }, []);
/////////////////////
// var strWindowFeatures = "location=yes,height=1000,width=1500,scrollbars=yes,status=yes";
// var URL = "https://paymentcomligo.firebaseapp.com/";  

// const doCourse = ()=>{
//     console.log("click");
//     window.open(URL, "_blank", strWindowFeatures);
//     }
 /////////////////////delete cart
 
const borrar =()=>{
    //console.log(cartid);
    db.collection('wishlist')
    .doc(cartid)
    .delete()
    .then(() => {
      console.log("Document successfully deleted!");
      toast.success("The course was removed from your wishlist.");
      setWishlist(prevState => !prevState);
      setNombre(prevState => !prevState);
      setPrice(prevState => !prevState);
      //this.props.history.push("/")
      //window.location.reload();
    }).catch((error) => {
      toast.error("Error removing from your wishlist.");
     // console.error("Error removing document: ", error);
    });
}

const handleToken = async (token,addresses) => {
 //console.log(token,addresses);
 const product= {
  name: nombre+''+wishlist,
  price: price*formData.hr,
  description: wishlist
}
 const response = await axios.post(
 // "http://localhost:8080/checkout",
 "https://nodejs-notifications-293520.ue.r.appspot.com/checkout",
  { token,product}
);
const { status } = response.data;
//console.log("Response:", response.data);
if (status === "success") {
  toast("Success! Check email for details", { type: "success" });
  setWishlist(prevState => !prevState);
  setNombre(prevState => !prevState);
 // setPrice(prevState => !prevState);
  ////////delete from cart
  borrar();
  
} else {
  toast("Something went wrong", { type: "error" });
}
}

    return (
        <>
        <header className="App-header">
        <nav>
  <ul className="subs-list">
    <li ><a href="#" className="sub-nav">Current subscriptions</a></li>
    <li ><a href="#" className="sub-nav">Shopping cart</a></li>
    <li ><a href="#" className="sub-nav">Billing information</a></li>
    <li ><a href="#" className="sub-nav">Invoice History</a></li>
    {/* <li class=""><a href="#" className="sub-nav">about us</a></li>
    <li class=""><a href="#" className="sub-nav">contact us</a></li> */}
  </ul>
</nav>
        <div className="container">
                <Grid className="purchase-card">

            <h1 className="subscription-ti">Shopping cart</h1>
            <h2 className="course-title">{nombre}</h2>
            <h2 className="course-unit">{wishlist}</h2>
            <h2 className="course-price">{
              price ? (
                <>
               <Icon name="window close" onClick={borrar} className="borrar-cart"/>
               {/* <p>Price: ${price}.00 USD </p> */}
               <Dropdown 
               clearable 
               placeholder="select your desired hours"
               search
               selection 
               lazyLoad
               options={options} 
               onChange={(e,data) => setformData({...formData, hr: data.value})}
               />
               <hr className="guion-price"/>
               {/* <p>Total: ${price/40}</p> */}
               {/* <p className="price-total">Total: ${price/horas*formData.hr}.00 USD</p> */}
               <p className="price-total">Total: ${price*formData.hr}.00 USD</p>
   <StripeCheckout
      stripeKey="pk_live_1pYF8h9QlWzpfMbW3Eaz3xfv"
        token={handleToken}
        billingAddress
        amount={(price*formData.hr)*100}
        name={nombre}
   >
        <Button className="btn-round2">Checkout</Button>
    </StripeCheckout>
               </>
              ) : (
                <p className="empty-cart">Your cart is empty</p>
              )
            }</h2>
            {/* <Button className="single-btn" onClick={doCourse}> Single class</Button>
            <Button className="subscription-btn" >Subscription</Button> */}
             
                </Grid>
        </div>
        </header>
        </>
    )
}

function initialValueForm(){
  return{
     hr:""
}
}
