import React, { useState,useCallback } from 'react';
import {Form,Input,Button,Image}from "semantic-ui-react";
import {useDropzone} from "react-dropzone";
///toasts
import {toast} from "react-toastify";
import { useTranslation } from "react-i18next";
////no image
import NoImage from "../../../assets/png/no-image.png";
/////import uiid
import { v4 as uuidv4 } from "uuid";
/////database
import firebase from "../../../utils/Firebase";
import "firebase/storage";
import "firebase/firestore";

import "./AddResourcesForm.scss";
//firestore
const db = firebase.firestore(firebase);

export default function AddResourcesForm(props) {
    const {setShowModal}=props;

    const [formData, setFormData] = useState(initialValueForm);
   const [banner, setBanner] = useState(null);
   const [file, setFile] = useState(null);
   const [isLoading, setIsloading] = useState(false);
   const { t } = useTranslation();

   
   const onDrop=useCallback(acceptedFile =>{
    //  console.log(acceptedFile);
    const file =acceptedFile[0];
          setFile(file);
          setBanner(URL.createObjectURL(file));
     });
  
     const {getRootProps,getInputProps} =useDropzone({
           accept: "image/jpeg, image/png",
           noKeyboard:true,
           onDrop
     });
  
     const uploadImage = fileName => {
      const ref = firebase
        .storage()
        .ref()
        .child(`resources/${fileName}`);
      return ref.put(file);
    };
      
  
      const onSubmit= ()=>{
        // console.log("Creating Teachers"); 
        //console.log(formData);
        // setShowModal(false);
        if(!formData.name){
            toast.warning("Please provide the resources name");
        }else if (!file){
          toast.warning("Please upload a file");
        }else{ 
          setIsloading(true);
          const fileName=uuidv4();
         // console.log(fileName);
          uploadImage(fileName).then(()=>{
             // console.log("Upload successfull");
           db.collection("resources").add({
             name: formData.name,
             banner: fileName,
             url:formData.url 
            })
           .then(()=>{
              toast.success("resource added!.");
                resetForm();
                setIsloading(false);
                setShowModal(false);
           }).catch(() => {
              toast.error("Error adding the resources.");
              setIsloading(false);
            }) 
  
          }).catch(() =>{
              toast.error("Error uplading the imagefile!");
              setIsloading(true);
          })
        }
      };
  
    const resetForm = () => {
      setFormData(initialValueForm());
      setFile(null);
      setBanner(null);
    };

    return (
        <Form className="add-promotion-form" onSubmit={onSubmit}>
        <Form.Field className="promotion-banner">
          <div
            {...getRootProps()}
            className="banner"
            style={{ backgroundImage: `url('${banner}')` }}
          />
          <input {...getInputProps()} />
          {!banner && <Image src={NoImage} />}
        </Form.Field>
        <Form.Field className="promotion-avatar">
          <div
            className="avatar"
            style={{ backgroundImage: `url('${banner ? banner : NoImage}')` }}
          />
        </Form.Field>
        <Form.Field>
          <Input
            placeholder={t("Resourcename")}
            onChange={(e, data) =>
              setFormData({ ...formData,  name: data.value})}
          />
        </Form.Field>
        <Form.Field>
          <Input
            placeholder={`${t("Typeurldetails")}${'..'}`}
            onChange={(e, data) =>
              setFormData({ ...formData,  url: data.value})}
          />
        </Form.Field>
        <Button type="submit" loading={isLoading}>
        {t("Addresource")}
        </Button>
      </Form>
    )
}

function initialValueForm(){

    return{ 
        name:"",
        url:""
    };    
}
