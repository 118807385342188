import React,{useState,useEffect} from 'react';
import {withRouter} from "react-router-dom";
// import {Table,Icon,Container} from "semantic-ui-react";
// import { map } from "lodash";
// import {Link} from "react-router-dom";
import ListStudents from "../../components/Evaluation/ListStudents";
import firebase from "../../utils/Firebase";
import 'firebase/firestore';

import './Studentinfo.scss';


const db = firebase.firestore(firebase);

function Studentinfo(props) {

    const {match,user}=props;
    const [studentlist, setStudentlist] = useState(null);
// console.log(user);
  // console.log(studentlist);
//console.log(match);
//console.log(studentlist);
useEffect(() => {
    db.collection("classroom")
      .doc(match?.params?.id)
      .get()
      .then(response => {
        //  console.log(response.data());
        setStudentlist(response.data())
      });
  }, [match]);


    return (
      <>
               {studentlist && 
        <div>
         <ListStudents studentlist={studentlist} user={user}/>
        </div>
               }
      </>
    )
}


export default withRouter(Studentinfo);