import React, { useState, useEffect } from "react";
import { Form, Input, Button, Dropdown } from "semantic-ui-react";
import { map } from "lodash";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
// import { useDropzone } from "react-dropzone";
// // import uuid from "uuid/v4";
// import { v4 as uuidv4 } from "uuid";
import firebase from "../../../utils/Firebase";
import "firebase/firestore";
import "firebase/storage";
// import NoImage from "../../../assets/png/no-image.png";

import "./RemoveResourcesForm.scss";

const db = firebase.firestore(firebase);

export default function RemoveResourcesForm(props) {

    const {setShowModal}= props;
    const [isLoading, setIsLoading] = useState(false);
    const [recursos, setRecursos] = useState(null);
    const [formData, setFormData] = useState(initialValueForm());
    const [identificacion, setIdentificacion] = useState(null);
    const { t } = useTranslation();
//  console.log(promotions);   
// console.log(identificador);
/////////baner id firestore
    useEffect(() => {
        db.collection("resources")
        .get()
        .then(response => {
          //  console.log(response);
            
          const arrayResourceslist = [];
          map(response?.docs, resourceslist => {
            const data = resourceslist.data();
          //  console.log(data.email);
          //  console.log(teacherlist.id);
          arrayResourceslist .push({
            key: resourceslist.id,
            value: data.banner,
            text: data.name
          });
       
          
           // setIdentificador(promotionslist.id)
        
          });
        //  console.log(arrayTeachers);
        setRecursos(arrayResourceslist);
        });

    }, [])
/////////id from storage
    useEffect(() => {
      db.collection("resources")
      .get()
      .then(response => {
        //  console.log(response);
          
        const arrayidentificacionlist = [];
        map(response?.docs, identificacionlist => {
          const data = identificacionlist.data();
        //  console.log(data.email);
        //  console.log(teacherlist.id);
        arrayidentificacionlist  .push({
          key: identificacionlist.id,
          value: identificacionlist.id,
          text: data.name
        });
     
        
         // setIdentificador(promotionslist.id)
      
        });
      //  console.log(arrayTeachers);
      setIdentificacion(arrayidentificacionlist );
      });

  }, [])

    const onSubmit=(e)=>{
        // console.log("Enviando formulario..");
        // console.log(formData.id);  
        // console.log(formData.photo);
         db.collection('resources')
         .doc(formData.photo)
         .delete()
         .then(() => {
          // console.log("Document successfully deleted!");
           toast.success("The resources was removed from the slider.");
           deleteImage (); 
           setIsLoading(false);
           setShowModal(false);
        
         }).catch((error) => {
           toast.error("Error removing resources from the slider.");
          // console.error("Error removing document: ", error);
         });
      }
      
      const deleteImage = () => {
        let imageRef = firebase.storage().ref('resources/' + formData.id);
        imageRef
          .delete()
          .then(() => {
            console.log(`${formData.id}has been deleted successfully.`);
          })
          .catch((e) => console.log('error on image deletion => ', e));
      };

    return (
        <Form className="remove-resources-form" onSubmit={onSubmit}>
        <Form.Group>
            <Form.Field className="resources-inputs" >
                {/* <Input placeholder="Class Name"
                onChange={e => setFormData({...formData, classroom: e.target.value})}
                /> */}
               <p className="resources-legend">{t("Pleasematch")}</p> 
               <br>
               </br>
               <Dropdown 
                placeholder={t("Resource")}
                fluid
                search
                selection
                options={recursos}
                lazyLoad
                onChange={(e,data) =>
                  setFormData({ ...formData, id: data.value})}
               className="resources-form"  />
               <br>
               </br>
               <Dropdown 
                placeholder={t("Identifier")}
                fluid
                search
                selection
                options={identificacion}
                lazyLoad
                onChange={(e,data) =>
                  setFormData({ ...formData, photo: data.value})}
               className="resources-form"  />
            </Form.Field>
        </Form.Group>
        <Button type="submit" isLoading={isLoading} className="delete-resources">{t("Deleteresources")}</Button>
     </Form>
    )
}

function initialValueForm (){
    return{
        id: "",   
        photo:"" 
    }
}