import React, { useState,useEffect} from 'react';
import { map } from "lodash";
import { toast } from "react-toastify";
import {  Table,Container,Button,Icon } from "semantic-ui-react";
import { CSVLink, CSVDownload } from "react-csv";
import { useTranslation } from "react-i18next";

import './bringusersForm.scss';

 function BringusersForm() {

const [userslists, setUserslists] = useState(null);
const { t } = useTranslation();
//console.log(userslists);

useEffect(() => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept", "application/json");
    
    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };
    
   // fetch("http://localhost:8080/api/users", requestOptions)
      fetch("https://restserver-323001.uw.r.appspot.com/api/users", requestOptions)
      .then(response => response.text())
      .then(result => {
        const myObjStr = JSON.parse(result)
        console.log(myObjStr);
          setUserslists(myObjStr)
      })
      .catch(error => console.log('error', error));
}, [])



    return (
        <Container>
        {userslists &&
          <CSVLink data={userslists} style={{color:'#d48319',cursor:'pointer',fontWeight: 'bold'}}>{t("DownloadFile")}</CSVLink>
        }
        {userslists &&
           <Table inverted >
           <Table.Header>
               <Table.Row>
                 <Table.HeaderCell >{t("Users")} ({t("Email2")}) </Table.HeaderCell>
                 <Table.HeaderCell >{t("Users")} UID</Table.HeaderCell>
               </Table.Row>
           </Table.Header>
           <Table.Body>
               {map(userslists, userslists => (
                 <Userows
                 key={userslists.id}
                 userslists={userslists}  
             
                 />
             ))}
           </Table.Body>
         </Table>
         }
       </Container>  
    )
}


function Userows (props) {

    const{userslists }=props;
    //console.log(userslists);
  
    const {email,uid} = userslists;


  
    const copyToClipboard = (txt) => {
		navigator.clipboard.writeText(txt);
        toast.success("Text copied ");
	}
   
    return (
      <>
      <Table.Row > 
        <Table.Cell collapsing >
       {email}&nbsp;&nbsp;&nbsp;       
       <Icon
        className="add-s"  name='copy' link
        onClick={() => copyToClipboard(email)}>
		</Icon>
       </Table.Cell>
       <Table.Cell>
      { uid }&nbsp;&nbsp;&nbsp;    
      <Icon
        className="add-sus"  name='copy' link
        onClick={() => copyToClipboard(uid)}>
		</Icon>
       </Table.Cell>
      </Table.Row>
      </>
    );
  
  }

export default BringusersForm;