import React,{useState,useEffect} from 'react';
import {Table,Icon,List,Image,Container, Label, Menu} from "semantic-ui-react";
import {Link} from "react-router-dom";
import { map } from "lodash";
import firebase from "../../../utils/Firebase";

import './Listtest.scss';

const db = firebase.firestore(firebase);

function ListTest(props) {

    const {user} =props;

    const [myanswers, setmyanswers] = useState(null);
    const [mypendings, setmypendings] = useState(0);
    //console.log(user.displayName);
    //console.log(myanswers);

    var result = mypendings.length;

    useEffect(() => {
          db.collection("answers")
                .get()
                .then(response => {
                 //  console.log(response);
                 const arrayofAnswers=[]
                 const arrayofpendings=[]
              
                   map(response?.docs, teacherslist => {
                     const data = teacherslist.data();
                     data.id=teacherslist.id;
                     // console.log(data.teacher);
                     if(user.displayName === data.Teacher){
                       //  setTeacherid(data.id);
                       // console.log(data.id);
                       arrayofpendings.push(data);
                      arrayofAnswers.push(data);
                      }else{
                    
                      }  
                    });   
                    setmyanswers(arrayofAnswers);
                    setmypendings(arrayofpendings)
                });
        }, [])    

    return (
        <Container>
        <Table inverted className="list-tests">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell className="title-testing">Pending Evaluations:
            <Menu  className="menu-graddings" compact>
                {result ?(
              
                    <Menu.Item >
                      <Icon name='mail' size="large" style={{color:'#fff'}}/>
                      <Label color='teal' floating className="etiqueta-grade">
                      { result}
                      </Label>                                
                     </Menu.Item>
                   ):(

            <Menu.Item >
                <Icon name='mail' size="large" style={{color:'#fff'}}/>                                          
            </Menu.Item>
                    )}           
              </Menu>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
   <Table.Body>
     <List>
        {map(myanswers, myanswers => (
            <Evaluaciones
              key={myanswers.id}
              myanswers={myanswers}            
            />
          ))}
    </List>
   </Table.Body>
  </Table>
        </Container>
    )
}


function Evaluaciones(props){
    const{myanswers}=props;
    //console.log(clase.class);
  //  console.log(myanswers);
      
        return (
      <Table.Row >
         <Link to={`/gradetests/${myanswers.id}`}  style={{ color: 'inherit', textDecoration: 'inherit'}}>  
          <Table.Cell>  
            <List.Item >
            <Image avatar src={myanswers.avatarUser} />     
             <List.Header as='a' className="lista-lecciones">Lesson:{myanswers.userwork}</List.Header>
              <List.Description>
               {' '}
               <p>
                <b className="level-name">Level: {myanswers.userlevel}</b>
                <b className="student-name">{myanswers.username}</b>
                <b className="student-course">Course: {myanswers.usercourse}</b>
               </p>{' '}
               {new Date(myanswers.createAt.toDate()).toDateString()}.
             </List.Description>
           </List.Item>
          </Table.Cell>
          </Link>
      </Table.Row>    
        );
      }

export default  ListTest;     