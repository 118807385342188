import React, { useState, useEffect } from "react";
import { Form, Input, Button, Dropdown } from "semantic-ui-react";
import { map } from "lodash";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
// import { useDropzone } from "react-dropzone";
// // import uuid from "uuid/v4";
// import { v4 as uuidv4 } from "uuid";
import firebase from "../../../utils/Firebase";
import "firebase/firestore";
import "firebase/storage";
// import NoImage from "../../../assets/png/no-image.png";

import "./RemovePromotionsForm.scss";

const db = firebase.firestore(firebase);

export default function RemovePromotionsForm(props) {

    const {setShowModal}= props;
    const [isLoading, setIsLoading] = useState(false);
    const [promotions, setPromotions] = useState(null);
    const [formData, setFormData] = useState(initialValueForm());
    const [identificador, setIdentificador] = useState(null);
    const { t } = useTranslation();
//  console.log(promotions);   
// console.log(identificador);
/////////baner id firestore
    useEffect(() => {
        db.collection("promotions")
        .get()
        .then(response => {
          //  console.log(response);
            
          const arrayPromotionslist = [];
          map(response?.docs, promotionslist => {
            const data = promotionslist.data();
          //  console.log(data.email);
          //  console.log(teacherlist.id);
          arrayPromotionslist.push({
            key: promotionslist.id,
            value: data.banner,
            text: data.name
          });
       
          
           // setIdentificador(promotionslist.id)
        
          });
        //  console.log(arrayTeachers);
        setPromotions(arrayPromotionslist);
        });

    }, [])
/////////id from storage
    useEffect(() => {
      db.collection("promotions")
      .get()
      .then(response => {
        //  console.log(response);
          
        const arrayidentificadorlist = [];
        map(response?.docs, identificadorlist => {
          const data = identificadorlist.data();
        //  console.log(data.email);
        //  console.log(teacherlist.id);
        arrayidentificadorlist .push({
          key: identificadorlist.id,
          value: identificadorlist.id,
          text: data.name
        });
     
        
         // setIdentificador(promotionslist.id)
      
        });
      //  console.log(arrayTeachers);
      setIdentificador(arrayidentificadorlist );
      });

  }, [])

    const onSubmit=(e)=>{
        // console.log("Enviando formulario..");
         //console.log(formData.id);  
         //console.log(formData.photo);
         db.collection('promotions')
         .doc(formData.photo)
         .delete()
         .then(() => {
          // console.log("Document successfully deleted!");
           toast.success("This new was removed from the slider.");
           deleteImage (); 
           setIsLoading(false);
           setShowModal(false);
        
         }).catch((error) => {
           toast.error("Error removing news from the slider.");
          // console.error("Error removing document: ", error);
         });
      }
      
      const deleteImage = () => {
        let imageRef = firebase.storage().ref('promotions/' + formData.id);
        imageRef
          .delete()
          .then(() => {
         //   console.log(`${formData.id}has been deleted successfully.`);
          })
          .catch((e) => console.log('error on image deletion => ', e));
      };

    return (
        <Form className="remove-promotion-form" onSubmit={onSubmit}>
        <Form.Group>
            <Form.Field className="promotion-inputs" >
                {/* <Input placeholder="Class Name"
                onChange={e => setFormData({...formData, classroom: e.target.value})}
                /> */}
               <p className="promotions-legend">{t("Pleasematch")}</p> 
               <br>
               </br>
               <Dropdown 
                placeholder="News"
                fluid
                search
                selection
                options={promotions}
                lazyLoad
                onChange={(e,data) =>
                  setFormData({ ...formData, id: data.value})}
               className="promotion-form"  />
               <br>
               </br>
               <Dropdown 
                placeholder={t("Identifier")}
                fluid
                search
                selection
                options={identificador}
                lazyLoad
                onChange={(e,data) =>
                  setFormData({ ...formData, photo: data.value})}
               className="promotion-form"  />
            </Form.Field>
        </Form.Group>
        <Button type="submit" isLoading={isLoading} className="delete-news">Delete news</Button>
     </Form>
    )
}

function initialValueForm (){
    return{
        id: "",   
        photo:"" 
    }
}