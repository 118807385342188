import React,{useState,useEffect} from 'react';
import { Button,Container   } from "semantic-ui-react";
import BasicModal from '../../../components/Modal/BasicModal';
import AddCommentForm from '../../../components/Rating/AddCommentForm';
import { map } from "lodash";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import firebase from "../../../utils/Firebase";
import 'firebase/firestore';

import "./BannerCourse.scss";

const db = firebase.firestore(firebase);
//const increment = db.FieldValue.increment(1);

function BannerCourse(props) {

    const {maestroinfo,user,setReloadApp}= props;
   // console.log(setReloadApp);
  //  const [count, setCount] = useState(0);
    const [completioncount, setCompletioncount] = useState(null);
    const [classroomcompletion, setClassroomcompletion] = useState(0);
    const [statuscompletado, setStatuscompletado] = useState(0);
    const [isloading, setIsLoading] = useState(true);
    const [classroomcoment, setClassroomComment] = useState([]);
    const { t } = useTranslation();
        // console.log(maestroinfo.classname);
      //  console.log(completioncount);
    //console.log(classroomcompletion);
    //console.log(statuscompletado);
    //console.log(hiddebtn);
    //console.log(completioncount);
    //console.log(classroomcoment);

//////students dropdown
useEffect(() => {

  db.collection("studentclass")
  .get()
  .then(response => {
      const arrayStudiantes = []
    map(response?.docs, classlist => {
      const data = classlist.data();
      data.id = classlist.id;
      if(maestroinfo.classname === data.classname){

        arrayStudiantes.push({
          key:  data.id,
          value: data.studentid,
          text: data.studentname,
        })
      }
      // console.log(arrayStudiantes);
      setClassroomComment(arrayStudiantes)
    });
});
}, [maestroinfo])

///////completion list
useEffect(() => {

    db.collection("completion")
    .get()
    .then(response => {
        
      map(response?.docs, completions => {
        const data = completions.data();
        data.id = completions.id;
       // console.log(data.teacher);
        if(maestroinfo.classname === data.classname){
      //  console.log(data.id);
      //  console.log(data.completionstatus);
        setCompletioncount(data.id);
        setStatuscompletado(data.completionstatus);
        }else{
            // console.log("fail");
        }
      });
});
}, [])
/////////////////////track completion
useEffect(() => {

  db.collection("classroom")
  .get()
  .then(response => {
      
    map(response?.docs, classcompletions => {
      const data = classcompletions.data();
      data.id = classcompletions.id;
     // console.log(data.teacher);
      if(maestroinfo.classname === data.classname){
       // console.log(data.completion);
        setClassroomcompletion(data.completion);
        
      }else{
     
      }
    });
});


}, [maestroinfo])
/////////////////////////

//////update and tracking of session
    const contar = ()=>{
        //console.log("count"); 
       //  window.open(maestroinfo.link);

         const completionRef = db.collection("completion")
                            .doc(completioncount);
                            completionRef.get().then((response)=>{
             
             const completionData= response.data();
            // console.log(completionData.completionstatus) 
              const increment = completionData.completionstatus+1;
                //console.log(increment);            
             completionRef.update({
             completionstatus: increment
           }).then(()=>{
            toast.success("Time Session ended");
             setIsLoading(false);
             window.location.reload();
          
           })
  
           });                 
 
    }
//////////////
    return (
      <Container textAlign='justify'>
        <Informacion 
         maestroinfo={maestroinfo} 
         contar={contar} 
         isloading={isloading} 
         classroomcompletion={classroomcompletion}
         statuscompletado={statuscompletado}
         classroomcoment={classroomcoment}
         t={t}
         />
      </Container> 
    )
}


function Informacion (props){

  const{maestroinfo,contar,isloading,statuscompletado,classroomcompletion,classroomcoment,t}=props;

  const [hiddebtn, setHiddebtn] = useState(true);
  const [showcomment, setShowcomment] = useState(false);

  const comment= ()=>{
      // console.log("cerrar sesion");
     // firebase.auth().signOut();
     setShowcomment(true);
   }


/////////////////////tracking session state  
useEffect(() => {
 // setHiddebtn(true);
 setHiddebtn(true);

   if(statuscompletado >= classroomcompletion ){
     setHiddebtn(false);
    // console.log("igual");
   }
 
}, [statuscompletado,classroomcompletion])
//////////////////////////////////////


//console.log(hiddebtn);
//console.log(classroomcompletion);
//console.log(classroomcompletion);

const HOST = process.env.HOST || "http://localhost:8080";

 const openWindow = ()=>{
  window.open(maestroinfo.link);

  // var raw = JSON.stringify({});

  // try {
  //   const response = await fetch(HOST + "/api/courses/time", {
  //     headers: {
  //       'Content-Type': 'application/json',
  //       'Accept':       'application/json',
  //     },
  //     method: "POST",
  //     body:  raw,
  //     redirect: 'follow'
  //   })
  
    
  //   if(response.status === 404) 
  //     throw {message: "Bad request"}
      
      
  //   if(response.status !== 200) 
  //     throw new Error()

  //   // Si la tarea se ha cumplido correctamente 
  //   // podemos borrar al usuario de la tabla

  //   toast.success("Session time initialized");
  // }
  // catch(e) {
  //   toast.warning("ERROR: something went wrong...");
  // }


 }

  return(
    <>
       <div>
            <h1 className="title-clase">{t("Course")}:</h1>
            {/* <h2 className="titulo-clase">{maestroinfo.class}</h2> */}
            <h2 className="titulo-clase">{maestroinfo.classname}</h2> 
            {/* <Button className="btn-session" target="_blank" href={maestroinfo.link}>SESSION LINK</Button> */}
            <Button className="btn-session" target="_blank" onClick={openWindow}>{t("Sessionlink")}</Button>
            <h3 className="info-clase">Week day:{maestroinfo.classday} </h3>
            <h3 className="info-clase-dos">Time: {maestroinfo.classtime}</h3>
            <h3 className="detalle-curso">Course: {maestroinfo.course}</h3>
            <h3 className="nivel-curso">Level: {maestroinfo.level}</h3>
            <h3 className="sub-nivel">Sub level: {maestroinfo.sublevel}</h3>
            <h2 className="titulo-materiales">{t("Cousematerials")}:</h2>
            <Button target="_blank" href={maestroinfo.courselink} className="btn-materiales" isloading={isloading}>{t("Open")}</Button>           
            <h2 className="titulo-tracking">{t("Classdeliver")}:</h2>
            {hiddebtn ?(
              <>
              <Button className="btn-session-deliver" onClick={contar}>{t("Deliver")}</Button>
              <h3 className="descripcion-tracking">Completed sessions: {statuscompletado}/{classroomcompletion}</h3>
              </>
            ):(
              <>
              <Button className="btn-session-deliver" style={{cursor:'no-drop'}}>{t("Deliver")}</Button>
              <h3 className="descripcion-tracking">Completed sessions: {statuscompletado}/{classroomcompletion}</h3>
              </>
            )}
             <h2 className="titulo-comentarios">{t("Comments")}:</h2>
            <Button
              onClick={comment}
              className="adding-comments-classroom"
            >
            {t("Comment")}
            </Button>  
        </div>
        <BasicModal 
        show={showcomment}
        setShow={setShowcomment}
        title={"Comment"}
        >
        <AddCommentForm setShowcomment={setShowcomment} classroomcoment={classroomcoment}/>
        </BasicModal> 
    </>
  );

}


export default BannerCourse;