import React from 'react';
import { Tab,Button } from 'semantic-ui-react';
import { useTranslation} from "react-i18next";
import Audioresources from '../../components/mediaresources/audioresources/audioresources';
import Videoresources from '../../components/mediaresources/videoresources/videoresources';
import Filesresources from '../../components/mediaresources/filesresources/filesresources';


import './mediresources.scss';


function Mediaresources(props) {
    const { user }=props;
    const { t } = useTranslation();
    //console.log(user);
    const panes = [
        { menuItem:   <Button circular color='#E05025' icon='microphone' style={{ position: 'relative ', marginLeft: 100,top: -10}}/>, render: () => <Tab.Pane as='div'><Audioresources  user={user}/></Tab.Pane> },
        { menuItem:  <Button circular color='#E05025' icon='video camera' style={{ position: 'relative ', marginLeft: 20,top: -10}}/>, render: () => <Tab.Pane as='div'><Videoresources  user={user}/></Tab.Pane> },
        { menuItem:  <Button circular color='#E05025' icon='file'style={{ position: 'relative ', marginLeft: 20,top: -10}}/>, render: () => <Tab.Pane as='div'><Filesresources user={user}/></Tab.Pane> },
    
      ]
    
    const color = 'gray';

    return (
        <div>
            <h1 style={{marginLeft : 70}}>Resources</h1>
            <br>
            </br>
            <br>
            </br>
            <Tab menu={{color, inverted: true,}} panes={panes}  />
        </div>
    )
}

export default Mediaresources;