import React,{useState} from 'react';
import { Rating,Button,Input,Form,TextArea } from 'semantic-ui-react';
import firebase from "../../../utils/Firebase";
import "firebase/firestore";
import "firebase/auth";
import { toast } from "react-toastify";

import './AddCommentForm2.scss';

const db = firebase.firestore(firebase);

function AddCommentForm2(props) {

    const {studentinfo,setShowcomment,classcomment,teacherid}=props;
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState(initialValueForm());
  // console.log(biografia);
   //console.log(user.uid);
   // console.log(rating);
   const user = firebase.auth().currentUser;
  // console.log(user.photoURL);

    const onSubmit=()=>{

       
     //toast.success("Your camments are added");
      
          
       const payload ={
           idUser: studentinfo.studentid,
           avatarUser: user.photoURL,
           idTeacher: teacherid,
           title: formData.title,
           comment: formData.comment,
           rating: formData.rating,
           createAt: new Date()
       };
       //console.log(payload);
          
      // setShowModal(false);
        db.collection("comments")
                    .add(payload)
                      .then(() => {
                        toast.success("Your camments are added.");
                        // // resetForm();
                        // setIsLoading(false);
                        // setShowcomment(false);
                        updateComments();
                      })
                      .catch(() => {
                        toast.warning("Error adding comments.");
                        setShowcomment(false);
                      });
    }

    const updateComments = ()=>{
     const commentsRef = db.collection("classroom")
                           .doc(classcomment);
          commentsRef.get().then((response)=>{
            
            const commentsData= response.data();
            const ratingTotal = commentsData.ratingTotal + formData.rating;
            const quantityVoting = commentsData.quantityVoting+1;
            const ratingResult = ratingTotal / quantityVoting;

          commentsRef.update({
            rating: ratingResult,
            ratingTotal: ratingTotal,
            quantityVoting: quantityVoting
          }).then(()=>{
            //setReloadApp(prevState => !prevState);
            setIsLoading(false);
            setShowcomment(false);
          })

          });                 
    }

    const handleRate = (e, {rating}) => setFormData({ ...formData,rating });

    return (
        <div>
               <Rating maxRating={5} 
               defaultRating={0} 
               icon='star' size='massive' 
               className="add-rating2"
               onRate={handleRate}
               />
            
               <Form className="add-comments-form2" onSubmit={onSubmit}>
          <Form.Group>
            
              <Form.Field className="comments-inputs2" >
                  <Input placeholder="Title"
                  onChange={e => setFormData({...formData,  title: e.target.value})}
                  />
                 <br>
                 </br>
                 <TextArea placeholder='Comments...' className="give-comments2"
                 onChange={e => setFormData({...formData, comment: e.target.value})}
                 />
                 <br>
                 </br>
                 
              </Form.Field>
          </Form.Group>
          <Button type="submit" isLoading={isLoading} className="teacher-comment2">Add Comment</Button>
       </Form>
        </div>
    )
}

function initialValueForm (){
    return{
        title:"",
        comment: "",
        rating:""
    
    }
}

export default AddCommentForm2;