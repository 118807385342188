import React,{useState,useEffect} from 'react';
import { Rating,Button,Input,Form,TextArea,Dropdown } from 'semantic-ui-react';
import { map } from "lodash";
import firebase from "../../../utils/Firebase";
import "firebase/firestore";
import { toast } from "react-toastify";

import './AddCommentForm.scss';

const db = firebase.firestore(firebase);

const Gradeslabels = [
  { key: '1', value: 'correction',text: 'Correction' },
  { key: '2', value: 'scope' ,text: 'Scope'},
  { key: '3', value: 'fluency' ,text: 'Fluency'},
  { key: '4', value: 'coherence' ,text: 'Coherence'}
]

 function AddCommentForm(props) {
  const {setShowcomment,classroomcoment}=props;
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState(initialValueForm());
  const [studentclassid, setStudentclassid] = useState(null);
  const [communicationskill, setCommunicationskill] = useState(null);
  const [claseinfo, setClaseinfo] = useState(null);
  const [studentemail, setStudentemail] = useState(null);
  const [gradeupdate, setGradeupdate] = useState(null);
    // console.log(biografia);
    //console.log(user.uid);
    // console.log(rating);
    //console.log(formData.student);
    // console.log(studentclassid);
    // console.log(communicationskill);
    // console.log(studentemail);

 const user = firebase.auth().currentUser;
// console.log(user);
///////////grades
useEffect(() => {

  db.collection("grades")
  .get()
  .then(response => {
     //// const arrayStudiante = []
    map(response?.docs, gradeslist => {
      const data =gradeslist.data();
      // console.log(data.uid);
      data.id = gradeslist.id
      // console.log(arrayStudiantes);  
      if(data.studentid === formData.student && data.studentskill === "Communication"){
        // console.log(data.id);
        setGradeupdate(data.id)
       // setStudentemail(data.email)
      }    
    });
});
}, [formData])

//////students dropdown
useEffect(() => {

  db.collection("users")
  .get()
  .then(response => {
     //// const arrayStudiante = []
    map(response?.docs, userlist => {
      const data = userlist.data();
      // console.log(data.uid);
      // console.log(arrayStudiantes);  
      if(data.uid === formData.student){
        //console.log(data);
        setStudentemail(data.email)
      }    
    });
});
}, [formData])
//////
useEffect(() => {

  db.collection("studentclass")
  .get()
  .then(response => {
     //// const arrayStudiante = []
    map(response?.docs, classlist => {
      const data = classlist.data();
      data.id = classlist.id;
      if(formData.student === data.studentid){
        // console.log(data);
        // console.log(data.id);
        setClaseinfo(data)
        setCommunicationskill(data.rating);
        setStudentclassid(data.id);
      }
      // console.log(arrayStudiantes);      
    });
});
}, [formData])
////

  const onSubmit=()=>{

     
   //toast.success("Your camments are added");
    
        
     const payload ={
         idUser: user.uid,
         avatarUser: user.photoURL,
         idStudent: formData.student,
         title: formData.title,
         comment: formData.comment,
         rating: formData.rating,
         createAt: new Date(),
         evaluations: formData.evaluations
     };
     //console.log(payload);
        
    // setShowModal(false);
        db.collection("comments")
        .add(payload)
          .then(() => {
            toast.success("Your comments are added.");
            // // resetForm();
            // setIsLoading(false);
            // setShowcomment(false);
            updateComments();
            updateGrades();
          })
          .catch(() => {
            toast.warning("Error adding comments.");
            setShowcomment(false);
          });
          /////grading
          // db.collection("grades")
          // .add({
          //   // studentgrade: communicationskill*4,
          //   // studentid: formData.student,
          //   //  studentclassid: studentclassid,
          //   //  studentclassname: claseinfo.classname,
          //   //  studentcourse: claseinfo.course,
          //   //  studentlevel: claseinfo.level,
          //   //  studentwork: "Class evaluation",
          //   //  studentemail:studentemail,
          //   //  studentname:claseinfo.studentname,
          //   //  studentskill:"Communication",
          //   //  teachername: claseinfo.teachername,
          //   // teacherid: user.uid
          // })
          // .then(() => {
          //     updateGrades();
          //     // toast.success("The test is already evaluated.");
          //     // resetForm();
          //   })
          //   .catch(() => {
          //     // toast.warning("Error evaluating the test.");
          //   });
  }

  const updateComments = ()=>{
   const commentsRef = db.collection("studentclass")
                         .doc(studentclassid);
        commentsRef.get().then((response)=>{
          
          const commentsData= response.data();
          const ratingTotal = commentsData.ratingTotal + formData.rating;
          const quantityVoting = commentsData.quantityVoting+1;
          const ratingResult = ratingTotal / quantityVoting;

        commentsRef.update({
          rating: ratingResult,
          ratingTotal: ratingTotal,
          quantityVoting: quantityVoting
        }).then(()=>{
         // setReloadApp(prevState => !prevState);
          setIsLoading(false);
          setShowcomment(false);
        })

        });                 
  }

  const updateGrades = ()=>{
    const gradesRef = db.collection("grades")
                          .doc(gradeupdate);
        gradesRef.get().then((response)=>{
           
            // const gradesData= response.data();
            // console.log(gradesData);
          //  const ratingTotal = commentsData.ratingTotal + formData.rating;
          //  const quantityVoting = commentsData.quantityVoting+1;
          //  const ratingResult = ratingTotal / quantityVoting;
 
        gradesRef.update({
          studentgrade:communicationskill*4
         }).then(()=>{
          // setReloadApp(prevState => !prevState);
           setIsLoading(false);
           setShowcomment(false);
         })
 
         });                 
   }

  const handleRate = (e, {rating}) => setFormData({ ...formData,rating });

  return (
      <div>
             <Rating maxRating={5} 
             defaultRating={0} 
             icon='star' size='massive' 
             className="add-rating"
             onRate={handleRate}
             />
          
             <Form className="add-comments-form" onSubmit={onSubmit}>
        <Form.Group>
          
            <Form.Field className="comments-inputs" >
                <Input placeholder="Title"
                onChange={e => setFormData({...formData,  title: e.target.value})}
                />
               <br>
               </br>
               <Dropdown 
                  placeholder="Students"
                  fluid
                  search
                  selection
                  options={classroomcoment}
                  lazyLoad
                  onChange={(e, data) =>
                    setFormData({ ...formData, student: data.value})}
                 className="add-class-comment"  
                 />
               <br>
               </br>
               <Dropdown 
                  placeholder="Teacher evaluations"
                  fluid
                  search
                  selection
                  options={Gradeslabels}
                  lazyLoad
                  onChange={(e, data) =>
                    setFormData({ ...formData, evaluations: data.value})}
                className="add-teacher-evaluation"  />
                <br>
                </br>
               <TextArea placeholder='Comments...' className="give-comments"
               onChange={e => setFormData({...formData, comment: e.target.value})}
               />
               <br>
               </br>
               
            </Form.Field>
        </Form.Group>
        <Button type="submit" isLoading={isLoading} className="teacher-comment">Add Comment</Button>
     </Form>
      </div>
  )
}

function initialValueForm (){
  return{
      title:"",
      student:"",
      evaluations:"",
      comment: "",
      rating:""
  
  }
}

export default AddCommentForm;