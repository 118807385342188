import React,{useState,useEffect} from 'react';
import { Button,Container} from "semantic-ui-react";
import { map } from "lodash";
import {withRouter} from "react-router-dom";
import BasicModal from '../../components/Modal/BasicModal';
import AddEvaluationForm from "../../components/Evaluation/AddEvaluationFrom";
import firebase from "../../utils/Firebase";
import "firebase/firestore";

import './Studentdetails.scss';

const db = firebase.firestore(firebase);

 function Studentdetails(props) {

    const {match,user}=props;
    const [datosestudiante, setdatosestudiante] = useState(null);
    const [cuentaestudiante, setCuentaestudiante] = useState(null);
    const [showgades, setShowgrades] = useState(false);
   // console.log(match.params.student);
   //console.log(datosestudiante);
  // console.log(cuentaestudiante);
 // console.log(datosestudiante);

 //////////list users  
useEffect(() => {
    db.collection("users")
    .get()
    .then(response => {     
   
        map(response?.docs, usuarios => {
        const data = usuarios.data();
        data.id=usuarios.id;

        if(match.params.studentid===data.uid){
            setCuentaestudiante(data)
        }
        else{

        }
            });
   
    });
}, [])
/////////////
useEffect(() => {
    db.collection("studentclass")
    .get()
    .then(response => {     
   
        map(response?.docs, evaluacion => {
        const data = evaluacion.data();
        data.id=evaluacion.id;

        if(match.params.studentid===data.studentid){
            setdatosestudiante(data)
        }
        else{

        }
            });
   
    });
}, [])
////////
const gradeskills= ()=>{
    // console.log("cerrar sesion");
   // firebase.auth().signOut();
   setShowgrades(true);
 }

    return (
        <>
        {cuentaestudiante &&
        <Container textAlign='justify'>
            <div>
            <h1 className="estudiante-detalles">Student details:</h1>
            <h2 className="titulo-detalles-estudiante">Name: {cuentaestudiante.username}</h2>
            <h3 className="email-detalles-estudiante">Email: {cuentaestudiante.email} </h3>
            <div class="center"> 
            <Button onClick={gradeskills} className="btn-grade-skills">Grade Skills</Button>
            </div>
            <BasicModal 
                show={showgades}
                setShow={setShowgrades}
                title={"Grade Skills"}
                >
                <AddEvaluationForm setShowgrades={setShowgrades} user={user} datosestudiante={datosestudiante} cuentaestudiante={cuentaestudiante}/>
            </BasicModal>
            </div>
        </Container>
        }
        </>
    )
}

export default withRouter(Studentdetails);