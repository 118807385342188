import React, { useState, useEffect} from "react";
import { Form, Button, Dropdown,Icon,Input } from "semantic-ui-react";
import { map } from "lodash";
import { toast } from "react-toastify";
import firebase from "../../../utils/Firebase";
import "firebase/firestore";
import "firebase/storage";
// import NoImage from "../../../assets/png/no-image.png";

import "./BlogContent.scss";

const db = firebase.firestore(firebase);

 function BlogContent(props) {

  const {setShowModal}= props;

 const [formData, setFormData] = useState(initialValueForm());

 const [isLoading, setIsLoading] = useState(false);
 const [blogs, setBlogs] = useState([]);

 useEffect(() => {
  db.collection("blogcovers")
  .get()
  .then(response => {
   //  console.log(response);
      
        const arrayBlogs = [];
        map(response?.docs, blogs => {
          const data = blogs.data();
        // console.log(data);
        // data.id=users.id;
          arrayBlogs.push({
            key: data.id,
            value: data.id,
            text: data.title,
          //  uid: users.id
          });
        });
      // console.log(arrayUsers);
      setBlogs(arrayBlogs);
  });

}, [])

  const onSubmit=()=>{  
    if(!formData.id){
      toast.warning("Please provide the id");
  }else if (!formData.content){
    toast.warning("Please provide the html");
  }
  else{ 
    // console.log(formData);
    setShowModal(false);
    db.collection("blogcontent")
                .add({
                  content:formData.content,
                  id:formData.id
                  })
                  .then(() => {
                    toast.success("The blog info is uploaded.");
                    // resetForm();
                    setIsLoading(false);
                    setShowModal(false);
                    resetForm();
                  })
                  .catch(() => {
                    toast.warning("Error creating blog info record.");
                    setIsLoading(false);
                  });
  }
  }

  const resetForm = () => {
    setFormData(initialValueForm());
  };
  return (
    <Form className="add-blog-form" onSubmit={onSubmit}> 
            <Dropdown 
              placeholder="Blog name"
              fluid
              search
              selection
              options={blogs}
              lazyLoad
              onChange={(e, data) =>
                setFormData({ ...formData, id: data.value})}
            className="add-blog"  />
            <br>
            </br>   
            <Form.TextArea placeholder=' Please paste de html code...' className='blog-info'
            onChange={e => setFormData({...formData, content: e.target.value})}
            />       
      <br></br>
      <Button type="submit" isLoading={isLoading} className="add-blog">Add blog</Button>
    </Form>
  )
}


function initialValueForm (){
  return{
      id: "",
      content:"",
  }
}

export default BlogContent;