import React,{useState,useEffect} from 'react';
import {Table,Icon,Container} from "semantic-ui-react";
import { map } from "lodash";
import { useTranslation } from "react-i18next";
import {Link} from "react-router-dom";
import firebase from "../../utils/Firebase";

import "./Myinputs.scss";

const db = firebase.firestore(firebase);

function Myinputs(props) {
    const {user}=props;

    const [miclase, setMiclase] = useState(null);
    const { t } = useTranslation();
    // console.log(miclase);

    //////////////////list student class
useEffect(() => {
    db.collection("studentclass")
    .get()
    .then(response => {

        const arraymiClases=[]

        map(response?.docs, classes => {
            const data = classes.data();
            data.id=classes.id;
           // console.log(data.teacher);
            if(user.uid === data.studentid){
              //  setClase(data.class);
           // console.log(data);
             arraymiClases.push(data);
            }else{
                // console.log("fail");
            }
          });   
          setMiclase(arraymiClases);
});
}, [])

    return (
        <Container textAlign='justify'>
        <Table inverted className="list-inputs">
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell />
            <Table.HeaderCell className="title-inputs">{t("Myinputs")}:</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
      
        <Table.Body>
      
        {map(miclase, miclase => (
          <Recordslist
            key={miclase.id}
            miclase={miclase}            
          />
        ))}
        </Table.Body>
        </Table>
      </Container>
          )
}
      
      
      
function Recordslist(props){
    const{miclase}=props;
        return (
        <>
        <Table.Row >
        <Link to={`/myinputsdetails/${miclase.id}`}  style={{ color: 'inherit', textDecoration: 'inherit'}}>  
            <Table.Cell collapsing>
            <Icon name="bookmark" />
            </Table.Cell>
            {/* <Table.Cell>{miclase.class}</Table.Cell>      */}
            <Table.Cell>{miclase.classname}</Table.Cell>  
            </Link>
        </Table.Row>
        </>
        );
}

export default Myinputs;