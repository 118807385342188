import React,{useState} from 'react';
import {Button, Grid, GridColumn,Icon} from "semantic-ui-react";
/////router
import {BrowserRouter as Router} from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";
import BasicModal from "../../components/Modal/BasicModal";
////forms
import FaqForm from "../../components/Help/FaqForm";
import SupportForm from "../../components/Help/SupportForm";
import Routes from "../../routes/Routes";
//style
////menu left
import MenuLeft from "../../components/MenuLeft";
/////Topbar
import TopBar from "../../components/TopBar";
////////Player
import Player from "../../components/Player";
//database
import firebase from "../../utils/Firebase";
import "firebase/storage";
import "./LoggedLayout.scss"

export default function LoggedLayout(props) {
const {user,setReloadApp} =props;
const { t, i18n } = useTranslation();
const [titleModal, setTitleModal] = useState(null);
const [contentModal, setContentModal] = useState(null);
const [showModal, setShowModal] = useState(false);
//console.log(user);
/////function player

// const [recordData, setrecordData] = useState(null);

// const playerRecording=(albumImage, recordName,recorNameFile)=>{
//  // console.log('albumImage >' + albumImage);
// //  console.log('recordName >' + recordName);
// //  console.log('recordUrl >' + recorNameFile);
// firebase
//  .storage()
//  .ref(`recordings/${recorNameFile}`)
//  .getDownloadURL()
//  .then(url =>{
//    //console.log(url);
//    setrecordData({url,image:albumImage, name: recordName });
//  }) 
//  //
// }
    const changeLanguage = lng => {
      i18n.changeLanguage(lng);
    };


   const handlerModal = (type) => {
    switch (type) {
      case "support":
        setTitleModal("Support");
        setContentModal(
         <SupportForm setShowModal={setShowModal} user={user}/>
        );
        setShowModal(true);
        break;
      case "faq":
        setTitleModal("FAQs");
        setContentModal(
          <FaqForm setShowModal={setShowModal}/>
        );
        setShowModal(true);
        break;

  
       default:
        setTitleModal(null);
        setContentModal(null);
        setShowModal(false);
        break;
    }
  };


    return (
     <>
      <Router>
       <Grid className="logged-layout">
       <Grid.Row>           
              <GridColumn width={3}>
                <MenuLeft user={user}/>
              </GridColumn> 
                <Grid.Column  className="content" width={13}>
                <TopBar user={user}/>
                <Routes user={user} setReloadApp={setReloadApp}  />
                </Grid.Column>       
             </Grid.Row> 
            <Grid.Row>
                <Grid.Column width={16}>
                {/* <Player recordData={recordData}/> */}
                <div>
                  <Button.Group className='group-translations'>
                      <Button  onClick={() => changeLanguage("en")} className='translate' size='mini'>En</Button>
                  
                      <Button onClick={() => changeLanguage("es")} className='translate' size='mini'>Es</Button>
                    
                      <Button onClick={() => changeLanguage("fr")} className='translate' size='mini'>Fr</Button>
                  
                      <Button onClick={() => changeLanguage("br")} className='translate' size='mini'>Pt</Button>
                  </Button.Group>
                  <Button circular color='facebook' icon='question' onClick={()=> handlerModal("faq")}/>
                  <Button circular color='instagram' icon='envelope outline' onClick={()=> handlerModal("support")}/>
                  {/* <Button circular color='linkedin' icon='linkedin' />   */}
                </div>
                </Grid.Column>
            </Grid.Row>
      </Grid>
       </Router>
       <BasicModal show={showModal} setShow={setShowModal} title={titleModal}>
          {contentModal}
       </BasicModal>
     </> 
    )
}
