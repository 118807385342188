import React, { useState } from "react";
import { Button, Form, Input, Icon } from "semantic-ui-react";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { reauthenticate } from "../../utils/Api";
import alertErrors from "../../utils/AlertErrors";
import firebase from "../../utils/Firebase";
import "firebase/auth";

export default function UserEmail(props) {
    const { user, setShowModal, setTitleModal, setContentModal } = props;
    const { t } = useTranslation();

   const onEdit = () =>{
     setTitleModal(t("Updateemail"))
     setContentModal(<ChangeEmailForm email={user.email} setShowModal={setShowModal} t={t}/>)  
     setShowModal(true)
   }

    return (
        <div className="user-email">
            <h3>{t("Email")} {user.email}</h3>
            <Button circular onClick={onEdit}>
            {t("Update")}
            </Button>
        </div>
    )
}

function ChangeEmailForm(props) {

const {email, setShowModal,t} = props;
const [formData, setFormData] = useState({email:"",password:""})
const [showPassword, setShowPassword] = useState(false);
const [isloading, setIsLoading] = useState(false);

const onSubmit = () => {
    if (!formData.email) {
      toast.warning("El email es el mismo.");
    } else {
      setIsLoading(true);
      reauthenticate(formData.password)
        .then(() => {
          const currentUser = firebase.auth().currentUser;
          currentUser
            .updateEmail(formData.email)
            .then(() => {
              toast.success("Email actualizado.");
              setIsLoading(false);
              setShowModal(false);
              currentUser.sendEmailVerification().then(() => {
                firebase.auth().signOut();
              });
            })
            .catch(err => {
              alertErrors(err?.code);
              setIsLoading(false);
            });
        })
        .catch(err => {
          alertErrors(err?.code);
          setIsLoading(false);
        });
    }
  };
        /*
        console.log("Form send it");
        console.log(formData);
        setShowModal(false);*/
    
   
    return(
     <Form onSubmit={onSubmit}>
      <Form.Field>
         <Input
         defaultValue={email}
          type="text"
          onChange={e => setFormData({...formData, email: e.target.value})}
         /> 
      </Form.Field>
      <Form.Field>
         <Input
         placeholder= {t("Password")}
         type={showPassword ? "text": "password"}
         onChange={e => setFormData({...formData, password: e.target.value})}
         icon={
         <Icon name={showPassword ? "eye slash outline" : "eye"}
         link
         onClick={()=> setShowPassword(!showPassword)}
         />}
         /> 
      </Form.Field>
      <Button type="submit" loading={isloading}>
      {t("Updateemail")}
      </Button>
     </Form>
    )   
}