import React, { useState, useEffect } from "react";
import { Form, Input, Button, Dropdown } from "semantic-ui-react";
import { map } from "lodash";
import { toast } from "react-toastify";
// import { useDropzone } from "react-dropzone";
// // import uuid from "uuid/v4";
// import { v4 as uuidv4 } from "uuid";
import firebase from "../../../utils/Firebase";
import "firebase/firestore";
import "firebase/storage";
// import NoImage from "../../../assets/png/no-image.png";

import "./AddEvaluationForm.scss";

const db = firebase.firestore(firebase);

const Skills = [
    // { key: '1', value: 'Vocabulary',text: 'Vocabulary' },
    // { key: '2', value: 'Grammar' ,text: 'Grammar'},
    { key: '1', value: 'Communication' ,text: 'Communication'}
  //  { key: '4', value: 'Test' ,text: 'Test'},
    
  ]

export default function AddEvaluationForm(props) {
   
    const {setShowModal,setShowgrades,datosestudiante, cuentaestudiante,user}= props;
  
    const [formData, setFormData] = useState(initialValueForm());

    const [isLoading, setIsLoading] = useState(false);

    //const [user, setUser] = useState([])
//console.log(teachers);
//console.log(user);
//console.log(datosestudiante);
//console.log(cuentaestudiante);

////////users list
    // useEffect(() => {
    //     db.collection("users")
    //     .get()
    //     .then(response => {
    //      //  console.log(response);
            
    //       const arrayUsers = [];
    //       map(response?.docs, users => {
    //         const data = users.data();
    //      //  console.log(data);
    //        arrayUsers.push({
    //           key: users.id,
    //           value: data.uid,
    //           text: data.email,
    //         //   uid: data.uid
    //         });
    //       });
    //     // console.log(arrayUsers);
    //      setUser(arrayUsers );
    //     });

    // }, [])
//////////////////////
//////////submit function
const onSubmit=()=>{
        // console.log("Enviando formulario..");
        // console.log(formData);
       // setShowModal(false);
        setShowgrades(false);
        db.collection("grades")
                    .add({
                    //  user: formData.user,
                      studentgrade: formData.studentgrade,
                      studentskill: formData.studentskill,
                      studentclassid: datosestudiante.id,
                      studentclassname: datosestudiante.class,
                      studentcourse: datosestudiante.course,
                      studentlevel: datosestudiante.level,
                      studentsublevel: datosestudiante.sublevel,
                      studentemail: cuentaestudiante.email,
                      studentid: cuentaestudiante.uid,
                      studentname: datosestudiante.studentname,
                      teacherid:user.uid,
                      teachername:user.displayName
                    })
                      .then(() => {
                        toast.success("The grade is already recorded.");
                        // resetForm();
                        setIsLoading(false);
                      //  setShowModal(false);
                      setShowgrades(false);
                      })
                      .catch(() => {
                        toast.warning("Error recording grade score.");
                        setIsLoading(false);
                      });
}
//////////////////////////
    return (

       <Form className="add-evaluation-form" onSubmit={onSubmit}>
          <Form.Group>
              <Form.Field className="evaluation-inputs" >
              <b className="grade-description-skill">Please enter the grade for the sellected skill:</b>
                  <Input placeholder="Enter corresponding grade 20%"
                  onChange={e => setFormData({...formData, studentgrade: e.target.value})}
                  className="entrada-evaluacion"/>
                 <br>
                 </br>
                 <b className="grade-description-skill">Please select the skill:</b>
                 <Dropdown 
                  placeholder="Level skills"
                  fluid
                  search
                  selection
                  options={Skills}
                  lazyLoad
                  onChange={(e, data) =>
                    setFormData({ ...formData, studentskill: data.value })}
                 className="evaluation-form"  />
                 <br>
                 </br>
                  {/* <Dropdown 
                  placeholder="The evaluation belongs to"
                  fluid
                  search
                  selection
                  options={user}
                  lazyLoad
                    onChange={(e, data) =>
                      setFormData({ ...formData, user: data.value })}
                className="evaluation-form"  /> */}
              </Form.Field>
          </Form.Group>
          <br></br>
          <Button type="submit" isLoading={isLoading} className="envio-evaluacion">Send record</Button>
       </Form>
    )
}

function initialValueForm (){
    return{
        studentgrade: "",
        studentskill:""
    
    }
}