import React,{useState,useEffect} from 'react';
import {Table,Icon,List,Image} from "semantic-ui-react";
import { map } from "lodash";
import { useTranslation } from "react-i18next";
import firebase from "../../../utils/Firebase";
import "firebase/firestore";

import './Listinputs.scss';

const db =firebase.firestore(firebase);


 function Listinputs(props) {
    const {studentinfo}= props;
    const [inputs, setinputs] = useState([])
    const { t } = useTranslation();
    // console.log(studentinfo);


useEffect(() => {
        // const {user}=props;

        db.collection("comments")
        .get()
        .then(response => {
            
        const arrayComentarios = [];
        // const arrayEvaluations = [];
          map(response?.docs, comentarios => {
            const data = comentarios.data();
           // console.log(data.teacher);
            //    console.log(data);
            if(studentinfo.studentid === data.idStudent){
                // console.log(data);
                arrayComentarios.push(data);
            }
          });
        //   console.log(arrayComentarios);
          setinputs(arrayComentarios)
      
    });
}, [])

return (
    
    <Table inverted className="list-comments-inputs">
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell className="title-comentarios-inputs">{t("Inputs")}:</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
     <Table.Body>
       <List>
          {map(inputs, inputs => (
              <Comentarios
                key={inputs.id}
                inputs={inputs}            
              />
            ))}
      </List>
     </Table.Body>
    </Table>
        )
    }
    
    function Comentarios(props){
    const{inputs}=props;
    //console.log(clase.class);
    //console.log(reviews);
      
        return (
      <Table.Row >
          <Table.Cell>  
            <List.Item >
            <Image avatar src={inputs.avatarUser} />     
             <List.Header as='a' className="lista-comentarios-inputs">{inputs.title}{' '}{inputs.evaluations}</List.Header>
              <List.Description>
               {' '}
               <a>
                <b className="teacher-comments-inputs">{inputs.comment}</b>
               </a>{' '}
               {new Date(inputs.createAt.toDate()).toDateString()}.
             </List.Description>
           </List.Item>
          </Table.Cell>
      </Table.Row>    
        );
      }


export default  Listinputs ;