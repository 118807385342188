import React,{useState,useEffect} from 'react';
//import { map } from "lodash";
//import firebase from "../../utils/Firebase";
import ListTest from "../../components/Evaluation/ListTest";

import "./Evaluatetest.scss";

//const db = firebase.firestore(firebase);

export default function Evaluatetest(props) {

    const {user} =props;
    

    return (
        <div>
            <ListTest user={user}/>
        </div>
    )
}
