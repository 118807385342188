import React, { useState,useCallback } from 'react';
import { Form, Table,Icon,Container } from "semantic-ui-react";
import { map } from "lodash";
import { toast } from "react-toastify";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";

import "./studentinfoForm.scss";
//import csv from 'csv';
import csv from 'csvtojson';

 function StudentinfoForm() {

    const [cvsjson, setCvsjson] = useState(null);
    const { t } = useTranslation();

    const onDrop = useCallback(acceptedFiles => {
        const file =acceptedFiles[0];
       // setFiles(file);

        const reader = new FileReader();
    
        reader.onabort = () => console.log("file reading was aborted");
        reader.onerror = () => console.log("file reading failed");
        reader.onload = () => {
   
        const fileAsBinaryString = reader.result;

        csv({
          noheader: true,
          output: "json"
        })
          .fromString(fileAsBinaryString)
          .then((csvRows) => {
            const toJson = []
            csvRows.forEach((aCsvRow, i) => {


              if (i !== 0) {
                const builtObject = {}

                Object.keys(aCsvRow).forEach((aKey) => {
                  const valueToAddInBuiltObject = aCsvRow[aKey];
                  const keyToAddInBuiltObject = csvRows[0][aKey];
                  builtObject[keyToAddInBuiltObject] = valueToAddInBuiltObject;
                })

                toJson.push(builtObject)
              }


            })
            setCvsjson (toJson)
          // console.log(toJson);
          })
        };
    
        // read file contents
        acceptedFiles.forEach(file => reader.readAsBinaryString(file));
      }, []);


    const {getRootProps
           ,getInputProps,
           isDragActive, 
           isDragAccept, 
           isDragReject
    } 
    = useDropzone({
    	accept: 'text/csv, application/vnd.ms-excel',
        noKeyboard:true,
        onDrop
  });




    return (
        <>   
        <Form className="add-onboardings-fom">
             <Form.Field className="onboardings-banner">
             <div
                  {...getRootProps()}
                  className="banners"
                />    
             <input {...getInputProps()} />
               {isDragAccept  &&  <p className="droptexts">{t("Drop2")}</p>}
                  {isDragReject  &&  <p className="droptexts">{t("Drop3")}</p>}
                  {!isDragActive &&  <p className="droptexts">{t("Drop")}...      </p>}
              </Form.Field>
        </Form>
        <br>
        </br>
        <Usertables  cvsjson={cvsjson} t={t}/>
        </>
    )
}


function Usertables (props){

    const{cvsjson,t}=props;
  
  //console.log(cvsjson);
  
    return (
   <Container>
     {cvsjson &&
        <Table inverted >
        <Table.Header>
            <Table.Row>
              <Table.HeaderCell >{t("Users")} ({t("Email2")}) </Table.HeaderCell>
              <Table.HeaderCell >{t("Users")} UID </Table.HeaderCell>
              <Table.HeaderCell >{t("Usersname")}</Table.HeaderCell>
              <Table.HeaderCell >{t("Language")}</Table.HeaderCell>
              <Table.HeaderCell >{t("Spanish")}</Table.HeaderCell>
              <Table.HeaderCell >{t("Agregar")}</Table.HeaderCell>
            </Table.Row>
        </Table.Header>
        <Table.Body>
            {map(cvsjson, cvsjson => (
              <Usersrows
              key={cvsjson.id}
              cvsjson={cvsjson}   
              />
          ))}
        </Table.Body>
      </Table>
      }
    </Container>  
      );
  }
  
  
  function Usersrows (props) {
  
    const{cvsjson}=props;
   // console.log(cvsjson);
  
    const {email,uid,username,language,spanish} = cvsjson;
  
    //console.log(cvsjson);
    //console.log(email);
    //const HOST = process.env.HOST || "http://localhost:8080"
    const HOST = process.env.HOST || "https://restserver-323001.uw.r.appspot.com"
  
    const onSubmit = async () =>{
   // console.log('click');
  
    try {
      const response = await fetch(HOST + "/api/users/info", {
        headers: {
          'Content-Type': 'application/json',
          'Accept':       'application/json',
        },
        method: "POST",
        body:  JSON.stringify(cvsjson)
      })
    
      
      if(response.status === 404) 
        throw {message: "Bad request"}
        
        
      if(response.status !== 200) 
        throw new Error()
  
  
      // Si la tarea se ha cumplido correctamente 
      // podemos borrar al usuario de la tabla
  
  
  
      toast.success("User info uploaded");
    }
    catch(e) {
      toast.warning("ERROR: something went wrong...");
    }
  
  
  
    }
  
    return (
      <>
      <Table.Row > 
        <Table.Cell collapsing>
       {email}
       </Table.Cell>
       <Table.Cell>
       { uid }
       </Table.Cell>
       <Table.Cell>
       { username }
       </Table.Cell>  
       <Table.Cell>
       {language}
       </Table.Cell>  
       <Table.Cell>
       {spanish}
       </Table.Cell>  
       <Table.Cell>
       {/* <Icon name="plus"  onclick="onSubmit"/> */}
       <Icon className="add-ss"  name='plus' link onClick={ onSubmit }/>
       </Table.Cell>     
      </Table.Row>
      </>
    );
  
  }


export default StudentinfoForm;