import React, {useState,useEffect}  from 'react';
import { withRouter } from 'react-router';
import Bannerpromotions from './Bannerpromotions/Bannerpromotions';
import firebase from '../../utils/Firebase';
import "firebase/firestore";

import './promotions.scss';

const db = firebase.firestore(firebase);

function Promotions(props) {
    
    //console.log(props);
    const { match } = props;
    const [promotions, setPromotions] = useState(null);
    //console.log(promotions);

    useEffect(() => {
       db.collection("promotions")
       .doc(match?.params.id)
       .get()
       .then(response =>{
          // console.log(response.data());
           setPromotions(response.data())
       })
    }, [match]);

    return (
        <div className="promotions">
            {promotions &&
           <Bannerpromotions promotions={promotions} />
            }
        </div>
    )
}

export default withRouter(Promotions);