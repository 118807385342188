import React, { useState, useEffect } from "react";
import { Form, Input, Button, Dropdown,Icon} from "semantic-ui-react";
import { map } from "lodash";
import { toast } from "react-toastify";
// import { useDropzone } from "react-dropzone";
// // import uuid from "uuid/v4";
// import { v4 as uuidv4 } from "uuid";
import firebase from "../../../utils/Firebase";
import "firebase/firestore";
import "firebase/storage";
// import NoImage from "../../../assets/png/no-image.png";

import "./AddClassForm.scss";

const db = firebase.firestore(firebase);

const Courses= [
  { key: '1', value: 'general',text: 'General Spanish' },
  { key: '2', value: 'diversity' ,text: 'Diversity'},
  { key: '3', value: 'business' ,text: 'For Business'},
  { key: '4', value: 'tourism' ,text: 'For Tourism'},
  { key: '5', value: 'culture' ,text: 'Culture and Civilization'}, 
  { key: '6', value: 'ccse' ,text: 'Preparation for the CCSE'}, 
  { key: '7', value: 'dele' ,text: 'Preparation for the DELE'}, 
  { key: '8', value: 'siele' ,text: 'Preparation for the SIELE'}, 
  { key: '9', value: 'health' ,text: 'For Health Care'}, 
  { key: '10', value: 'law' ,text: 'For Law'}, 
  { key: '11', value: 'lgtbi' ,text: 'For LGTBI'}, 
  { key: '12', value: 'religious' ,text: 'For Religious'}, 
  { key: '13', value: 'security' ,text: 'For Security'}, 
  { key: '14', value: 'sephardis' ,text: 'For Sephardis'}, 
  { key: '15', value: 'training' ,text: 'Teacher Training'}, 
]

const Level= [
  { key: '1', value: 'advanced',text: 'Advanced' },
  { key: '2', value: 'beginner' ,text: 'Beginner'},
  { key: '3', value: 'intermediate' ,text: 'Intermediate'},
  { key: '4', value: 'a1' ,text: 'A1'},
  { key: '5', value: 'a2' ,text: 'A2'},
  { key: '6', value: 'b1' ,text: 'B1'},
  { key: '7', value: 'b2' ,text: 'B2'},
  { key: '8', value: 'c1' ,text: 'C1'},
  { key: '9', value: 'c2' ,text: 'C2'},
  { key: '10', value: 'm' ,text: 'M'},
  { key: '11', value: '1' ,text: 'N/A'},
]  

const Sublevel= [
  { key: '1', value: '1',text: '1' },
  { key: '2', value: '2' ,text: '2'},
  { key: '3', value: '3' ,text: '3'},
  { key: '4', value: '4' ,text: '4'},
  { key: '5', value: '5' ,text: '5'},
  { key: '6', value: '6' ,text: '6'},
  { key: '7', value: '1' ,text: 'N/A'},
]  

export default function AddClassForm(props) {
   
    const {setShowModal}= props;
    const [teachers, setTeachers] = useState([]);
    const [formData, setFormData] = useState(initialValueForm());
    const [listacursos, setListacursos] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [completionstatus, setCompletionstatus] = useState(0);

  
//console.log(teachers);
// console.log(user);
//////list courses
    useEffect(() => {
        db.collection("available")
        .get()
        .then(response => {
         //  console.log(response);
            
          const arrayListacursos = [];
          map(response?.docs, listacursos => {
            const data = listacursos.data();
         //  console.log(data);
         arrayListacursos.push({
              key:  listacursos.id,
              value: data.courselink,
              text: data.coursename,
            //   uid: data.uid
            });
          });
        // console.log(arrayUsers);
        setListacursos(arrayListacursos );
        });

    }, [])


/////list teachers
    useEffect(() => {
        db.collection("teacherlist")
        .get()
        .then(response => {
          //  console.log(response);
            
          const arrayTeacherslist = [];
          map(response?.docs, teacherlist => {
            const data = teacherlist.data();
          //  console.log(data.email);
          //  console.log(teacherlist.id);
            arrayTeacherslist.push({
              key: teacherlist.id,
              value: data.uid,
              text: data.email
            });
          });
        //  console.log(arrayTeachers);
         setTeachers(arrayTeacherslist);
        });

    }, [])
///////completion record
const completion =()=>{
  db.collection("completion")
  .add({
    classname: formData.classroom,
    teacher: formData.teacher,
    classday:formData.classday,
    classtime:formData.classtime,
    course:formData.course,
    level:formData.level,
    link:formData.link,
    courselink:formData.courselink,
    sublevel:formData.sublevel,
    completionstatus:completionstatus
    })
    .then(() => {

    })
    .catch(() => {

    });
}
///////
const onSubmit=()=>{
          
        setShowModal(false);
        db.collection("classroom")
                    .add({
                      classname: formData.classroom,
                      teacher: formData.teacher,
                      classday:formData.classday,
                      classtime:formData.classtime,
                      course:formData.course,
                      level:formData.level,
                      link:formData.link,
                      courselink:formData.courselink,
                      sublevel:formData.sublevel,
                      completion:formData.completion
                      })
                      .then(() => {
                        toast.success("The classroom is already set.");
                        // resetForm();
                        completion();
                        setIsLoading(false);
                        setShowModal(false);
                      })
                      .catch(() => {
                        toast.warning("Error creating classroom.");
                        setIsLoading(false);
                      });
    }
////////////
    return (
       <Form className="add-class-form" onSubmit={onSubmit}>
          <Form.Group>
              <Form.Field className="class-inputs" >
                  <Input placeholder="Class Name"
                  onChange={e => setFormData({...formData, classroom: e.target.value})}
                  className="entrada-nombre"
                  icon={<Icon name="bookmark"/>}
                  />
                 <br>
                 </br>
                 <Input placeholder="Class day"
                  onChange={e => setFormData({...formData, classday: e.target.value})}
                  className="entrada-nombre"
                  icon={<Icon name="calendar outline"/>}
                  />
                 <br>
                 </br>
                 <Input placeholder="Class time"
                  onChange={e => setFormData({...formData, classtime: e.target.value})}
                  className="entrada-nombre"
                  icon={<Icon name="clock outline"/>}
                  />
                 <br>
                 </br>
                 <Input placeholder="Session link"
                  onChange={e => setFormData({...formData, link: e.target.value})}
                  className="entrada-nombre"
                  icon={<Icon name="linkify"/>}
                  />
                 <br>
                 </br> 
                 {/* <Input placeholder="Course link"
                  onChange={e => setFormData({...formData, courselink: e.target.value})}
                  className="entrada-nombre"
                  icon={<Icon name="staylinked"/>}
                  /> */}
                    <Input placeholder="Number of completion classes"
                  onChange={e => setFormData({...formData, completion: e.target.value})}
                  className="entrada-nombre"
                  icon={<Icon name="briefcase"/>}
                //  type="number"
                  />
                 <br>
                 </br> 
                  <Dropdown 
                  placeholder="Course link"
                  fluid
                  search
                  selection
                  options={listacursos}
                  lazyLoad
                  onChange={(e, data) =>
                    setFormData({ ...formData, courselink: data.value})}
                 className="añadir-clase"  
                 />
                 <br>
                 </br>             
                 <Dropdown 
                  placeholder="Course"
                  fluid
                  search
                  selection
                  options={Courses}
                  lazyLoad
                  onChange={(e, data) =>
                    setFormData({ ...formData, course: data.value})}
                 className="añadir-clase"  />
                 <br>
                 </br>         
                 <Dropdown 
                  placeholder="Level"
                  fluid
                  search
                  selection
                  options={Level}
                  lazyLoad
                  onChange={(e, data) =>
                    setFormData({ ...formData, level: data.value})}
                 className="añadir-clase"  />
                 <br>
                 </br>
                 <Dropdown 
                  placeholder="Sub level"
                  fluid
                  search
                  selection
                  options={Sublevel}
                  lazyLoad
                  onChange={(e, data) =>
                    setFormData({ ...formData, sublevel: data.value})}
                 className="añadir-clase"  />
                 <br>
                 </br>              
                 <Dropdown 
                  placeholder="Teacher"
                  fluid
                  search
                  selection
                  options={teachers}
                  lazyLoad
                  onChange={(e, data) =>
                    setFormData({ ...formData, teacher: data.value})}
                 className="añadir-clase"  />
                 <br>
                 </br>                           
              </Form.Field>
          </Form.Group>
          <Button type="submit" isLoading={isLoading} className="enviar-record">Send record</Button>
       </Form>
    )
}

function initialValueForm (){
    return{
        classroom:"",
        classday:"",
        classtime:"",
        course:"",
        level:"",
        link:"",
        courselink:"",
        sublevel:"",
        teacher:"",
        completion:0,    
    }
}