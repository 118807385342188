import React,{useState} from 'react'
import {Button,Icon,Form,Input,Checkbox} from "semantic-ui-react";
///data base
import firebase from "../../../utils/Firebase";
import "firebase/auth";

import 'firebase/firestore';
/////validation email
import { validateEmail } from "../../../utils/Validations"
// /////toast
import {toast} from "react-toastify";

////style
import "./RegisterForm.scss";

const db = firebase.firestore(firebase);

export default function RegisterForm(props) {
    const { setSelectedForm } = props;
    //////state fordata
    const [formData, setFormData]=useState(defaultValueForm());
   /////state show password
   const [showPassword, setShowPassword]=useState(false);

   const [formError, setFormError] = useState({});
   const [isLoading, setIsLoading] = useState(false);
   const [checked, setChecked] = useState(false);
  //  console.log(checked);
  /////function show password
  const handlerShowPassword = () =>{
    setShowPassword(!showPassword)
  }

   /////form data
    const onChange = e => {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value
      });
    };
  
    const HOST = process.env.HOST || "https://restserver-323001.uw.r.appspot.com/"

    const onSubmit  = async () => {
      // toast.error("Error the fields are empty")
   
      setFormError({});
      let errors = {};
      let formOk = true;
  
      if (!validateEmail(formData.email)) {
        errors.email = true;
        formOk = false;
      }
      // if (formData.password.length < 6) {
      //   errors.password = true;
      //   formOk = false;
      // }
      // if (!formData.username) {
      //   errors.username = true;
      //   formOk = false;
      // }
      setFormError(errors);
  
      if (formOk) {
        setIsLoading(true);
         // console.log(formData);
        
          var raw = JSON.stringify({
            "email": formData.email
          });

          try {
          const response = await fetch(HOST + "/api/webpage/create", {
            headers: {
              'Content-Type': 'application/json',
              'Accept':       'application/json',
            },
            method: "POST",
            body:  raw
          })


          if(response.status === 404) 
            throw {message: "Bad request"}
            
            
          if(response.status !== 200) 
            throw new Error()

            // Si la tarea se ha cumplido correctamente 
            // podemos borrar al usuario de la tabla
            toast.success("Your information has been received. We will contact you soon.");
            setIsLoading(false);
            setSelectedForm(null);
          }
          catch(e) {
          toast.warning("ERROR: something went wrong try later...");
          }
      //   firebase.auth().createUserWithEmailAndPassword(formData.email,formData.password).then(()=>  {
      //     // setCount(userCredential.user.uid);
      //   changeUserName();
      //   sendVerificationEmail();
      // }).catch(()=>{
      //   toast.error("Error creating the account");
      //   //console.log("error");
      // }).finally(()=>{
      //   setIsLoading(false);
      //   setSelectedForm(null);
      // })
      }
    
    };


    // const changeUserName = () =>{
    //   firebase.auth().currentUser.updateProfile({
    //     displayName: formData.username
    //   }).catch(()=>{
    //     toast.error("Error asigning nickname");
    //   })
    // }

    // const sendVerificationEmail = () => {
    //   firebase
    //     .auth()
    //     .currentUser.sendEmailVerification()
    //     .then(() => {
    //       toast.success("Email was send it for verify the account.");
    //     })
    //     .catch(() => {
    //       toast.error("Error sending verifying email.");
    //     });
    // };

    return (
        <div className="register-form">
            <h1>We teach Spanish Online.</h1>
            <Form onSubmit={onSubmit} onChange={onChange}>
              <Form.Field>
                  <Input
                    type="text"
                    name="email"
                    placeholder="email"
                    icon="mail outline"
                    error={formError.email}
                  />
                    {formError.email && (
            <span className="error-text">
              Please introduce a valid mail.
            </span>
          )}
              </Form.Field>
              {/* <Form.Field>
                  <Input
                    type={showPassword ? "text" : "password" }
                    name="password"
                    placeholder="password"
                    error={formError.password}
                    icon={
                      showPassword ? (
                        <Icon
                          name="eye slash outline"
                          link
                          onClick={handlerShowPassword}
                        />
                      ) : (
                        <Icon name="eye" link onClick={handlerShowPassword} />
                      )
                    }            
                  />
                     {formError.password && (
                      <span className="error-text">
                        Please introduce a password gratter than 5 characters.
                      </span>
                    )}
              </Form.Field> */}
              {/* <Form.Field>
                  <Input
                    type="Text"
                    name="username"
                    placeholder="username"
                    icon="user circle outline"
                    error={formError.username}
                  />
                    {formError.password && (
                      <span className="error-text">
                        Please introduce a username.
                      </span>
                    )}
              </Form.Field> */}
              <Form.Field>
                <Checkbox 
                // label='I agree to the Terms and Conditions' 
                checked={checked}
                onChange={e => setChecked(!checked)}
                />
              <p style={{color:'#fff',position: 'relative',left: 85,top:-15}}>
              I have read and I accept{" "}
              <br/>
              <a target="_blank" rel="noreferrer "href="https://comligo.com/policies" style={{color:'#d48319',position: 'relative',left: 0,}}> the Terms and Conditions</a>
              </p>
             </Form.Field>
             {checked ?(
              <Button type="submit"  loading={isLoading} className="reg-button">
                Submit
              </Button>
              ):(
              <Button disabled style={{cursor:'no-drop'}} className="reg-button">
                  Submit
              </Button>
               )}
            </Form>
            <div className="register-form__options">
            <p onClick={() => setSelectedForm(null)}>Back</p>    
            <p>
          I already have a Comligo account{" "}
          <span onClick={() => setSelectedForm("login")}>Login</span>
          </p>
            </div>
        </div>
    );
}

function defaultValueForm(){
  return{
    email:"",
    // password:"",
    // username:""
  }
}