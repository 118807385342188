import firebaseApp from "./Firebase";
import * as firebase from "firebase";
const db = firebase.firestore(firebaseApp);



export async function isUserAdmin(uid) {
  const response = await db
    .collection("admins")
    .doc(uid)
    .get();
//    console.log(response);
return response.exists;
}

export async function isUserTeacher(uid) {
  const response = await db
    .collection("teaching")
    .doc(uid)
    .get();
  //  console.log(response);
// return response.exists;
return response.exists;
}

export async function isRegularUser(uid) {
  const response = await db
    .collection("regularuser")
    .doc(uid)
    .get();
// console.log(response);
// return response.exists;
return response.exists;
}

export const reauthenticate = password => {
  const user = firebase.auth().currentUser;
 // console.log(user);
 // console.log(password);

  const credentials = firebase.auth.EmailAuthProvider.credential(
    user.email,
    password
  );

  return user.reauthenticateWithCredential(credentials);
};

